import { Button } from "react-bootstrap";
import "./OnlineTest.scss";
import { useLocation, useNavigate } from "react-router-dom";
const OnlineTest = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const navigateToQuiz = () => {
    navigate("/Quiz", { replace: true, state: { testId: state.testId, title: state?.title } });
  };
  return (
    <div className="OnlineTest">
      <div className="container">
        <h3>Welcome to online test</h3>
        <p>General Instructions</p>
        <ul>
          <li>
            Online test will be a combination of different levels of question
            namely: Level -1, 2 & 3
          </li>
          <li>The scores for levels -1, 2 & 3 are 1, 2 & 3 respectively</li>
          <li>
            Each test consists of a combination of different categories of
            questions namely: multiple choice questions, true or false, matching
            the following, sequencing questions, multiple options, image
            questions
          </li>
          <li>
            Each user will have different set of questions for the chosen test
          </li>
          <li>
            The sequence of options for a single question are different for each
            user
          </li>
          <li>The timer will be displayed on the right side of the page</li>
          <li>
            The number of questions attempted will be displayed on the left side
            of the page
          </li>
          <li>It is mandatory to answer all the questions</li>
          <li>
            After completing the test, use{" "}
            <strong>GO TO HOME PAGE / EXIT </strong>button to return to the home
            page
          </li>
          <li>
            One you start the test{" "}
            <strong>
              “DO NOT use TAB KEY / BACK KEY / REFRESH PAGE / OPEN NEW WINDOW”
            </strong>
            , this will lead to cancellation of test.
          </li>
        </ul>
        <p>Menus displayed in the online Test</p>
        <ul>
          <li>
            <strong>NEXT: </strong>After choosing an answer, use the{" "}
            <strong>NEXT</strong> button to proceed to next question
          </li>
          <li>
            <strong>SKIP: </strong> Use the <strong>SKIP</strong> button if you
            cannot answer the question or if you want to try the question again
            at the end of the exam
          </li>
          <li>
            <strong>REVIEW: </strong>After you have answered all the questions,
            the questions will be displayed, so that you can check the answers
          </li>
          <li>
            <strong>SUBMIT: </strong>After you have checked the answers, use{" "}
            <strong>SUBMIT</strong> button to confirm
          </li>
        </ul>
        <p>Results</p>
        <ul>
          <li>
            The test results are displayed on the screen with the following
            information; the number of correct answers, the percentage of score
            and the grade of the test
          </li>
          <li>
            The correct answers for all the questions will be displayed in green
            colour
          </li>
          <li>You have the option of giving us feedback for each test</li>
          <li>
            The results of all tests are saved on the{" "}
            <strong>MY PROFILE</strong> page
          </li>
        </ul>
        <Button
          variant="primary"
          className="next-button"
          onClick={navigateToQuiz}
        >
          Start <img src="/assets/arrow.png" />
        </Button>
      </div>
    </div>
  );
};

export default OnlineTest;
