import { useContext, useEffect, useState } from "react";
import NSMyPackage from "../Semester/NSMyPackage";
import NurseMyPackage from "../Module/NurseMyPackage";
import AuthContext from "../../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";
const MyPackagePage = (props: any) => {
  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    const token: any = sessionStorage.getItem("token");
    setUserData(jwtDecode(token));
  }, [user]);
  return (
    <>
      {userData?.user?.role === "NursingStudent" ? (
        <NSMyPackage />
      ) : (
        <NurseMyPackage />
      )}
    </>
  );
};

export default MyPackagePage;
