import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import HomeLayout from "./layouts/HomeLayout";
import Semester from "./pages/Semester/Semester";
import Quiz from "./pages/Quiz/Quiz";
import Score from "./pages/Score/Score";
import PurchasedSubject from "./pages/PurchasedSubject/PurchasedSubject";
import PurchasedPackage from "./pages/PurchasedSubject/PurchasedPackage";
import AllSemester from "./pages/Semester/AllSemester";
import AllSpeciality from "./pages/Module/AllSpeciality";
import PurchasedModule from "./pages/PurchasedModule/PurchasedModule";
import Module from "./pages/Module/Module";
import SpecialityPackage from "./pages/SpecialityPackage/SpecialityPackage";
import ViewSubjectPackage from "./pages/PurchasedSubject/ViewSubjectPackage";
import ViewSpecialityPackage from "./pages/SpecialityPackage/ViewSpecialityPackage";
import OnlineTest from "./pages/OnlineTest/OnlineTest";
import AboutUs from "./pages/AboutUs/AboutUs";
import TestProcess from "./pages/TestProcess/TestProcess";
import NSAboutUs from "./pages/NSAboutUs/NSAboutUs";
import NurseAboutUs from "./pages/NurseAboutUs/NurseAboutUs";
import Profile from "./pages/Profile/Profile";
import MyPackagePage from "./pages/MyPackagePage/MyPackagePage";
import AllModule from "./pages/Module/AllModule";
import DemoOnlineTest from "./pages/OnlineTest/DemoOnlineTest";
import PaymentData from "./pages/PaymentData/PaymentData";
import SuccessData from "./pages/PaymentData/SuccessData";
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/Quiz" element={<Quiz />}></Route>
        <Route path="/semester" element={<Semester />}></Route>
        <Route path="/module" element={<Module />}></Route>
        <Route path="/allmodule" element={<AllModule />}></Route>
        <Route path="/SubjectDetails" element={<PurchasedSubject />}></Route>
        <Route path="/PackageDetails" element={<PurchasedPackage />}></Route>
        <Route path="/PurchasedModule" element={<PurchasedModule />}></Route>
        <Route
          path="/ViewSubjectPackage"
          element={<ViewSubjectPackage />}
        ></Route>
        <Route
          path="/ViewSpecialityPackage"
          element={<ViewSpecialityPackage />}
        ></Route>
        <Route
          path="/SpecialityPackage"
          element={<SpecialityPackage />}
        ></Route>
        <Route path="/ns-semester" element={<AllSemester />}></Route>
        <Route path="/nurse-speciality" element={<AllSpeciality />}></Route>
        <Route path="/score" element={<Score />}></Route>
        <Route path="/testPage" element={<OnlineTest />}></Route>
        <Route path="/demoTest" element={<DemoOnlineTest />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        {/* <Route path="/ns-onlinetest" element={<TestProcess />}></Route>
        <Route path="/nurse-onlinetest" element={<TestProcess />}></Route> */}
        <Route path="/onlinetest" element={<TestProcess />}></Route>
        <Route path="/my-package" element={<MyPackagePage />}></Route>
        <Route path="/nursing-students" element={<NSAboutUs />}></Route>
        <Route path="/nurse" element={<NurseAboutUs />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route
          path="/paymentData"
          element={<PaymentData />}
        ></Route>
        <Route path="/successData" element={<SuccessData />}></Route>
      </Route>
    </>
  )
);
