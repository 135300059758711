import "./tabs.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CourseCards from "../CourseCards/CourseCards";
import { Container } from "react-bootstrap";
import CarouselSlider from "../CarouselSlider/CarouselSlider";
const CourceTabs = ({ tabs }: any) => {
  return (
    <div className="tabParent">
      <Container className="customMaxWidth">
        <div className="tabsAndCard">
          <Tabs
            defaultActiveKey="nursingstudent"
            transition={false}
            className="im-tabs"
          >
            {tabs.map((data: any) => (
              <Tab eventKey={data.key} title={data.value}>
                <div className="row">
                  <CarouselSlider
                    data={data.courseData}
                    buttonText={"View Courses"}
                    buuttonFunction={data.buuttonFunction}
                    isViewBtn={true}
                    isPrice={false} 
                    userType={data.tabkey}
                    purchaseType={"Speciality"}
                    isSemester={data.isSemester}
                    isCourseList={true}
                  />
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </Container>
    </div>
  );
};

export default CourceTabs;
