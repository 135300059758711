import _ from "lodash";
import { useEffect, useState } from "react";
import CodetoName from "../CodetoName/CodetoName";
import "./ExamList.scss";
import dayjs from "dayjs";
import { adminPath } from "../../constants/api";
import authInterceptor from "../../utils/authInterceptor";
import { nursetestType } from "../../constants/constants";
const ExamList = (props: any) => {
  const [examTypeValue, setExamTypeValue] = useState("All");
  const [examType, setExamType] = useState(nursetestType);
  const [examData, setExamData] = useState([]);
  const [globalexamData, setGlobalExamData] = useState([]);

  useEffect(() => {
    if (props.examData) {
      setExamData(_.cloneDeep(props.examData));
      setGlobalExamData(_.cloneDeep(props.examData));
    }
  }, [props.examData]);
  const checkExamData = (data: any) => {
    const examData = data?.examResult;
    if (examData) {
      return (
        <>
          <li style={{ flex: 0.5 }}>
            {examData.score}/
            {data.type === "Speciality"
              ? data.totalMark
              : data.totalQuestionMark}
          </li>
          <li style={{ flex: 0.5 }}>{data?.examResult.percentage}%</li>
          <li
            style={{ flex: 0.5 }}
            className={`${examData?.isPass ? "Pass" : "Fail"}`}
          >
            {`${examData?.gradeData?.grade}`}
          </li>
          {data?.examStatus === "Completed" ? (
            <li style={{ flex: 0.5 }} className="complete-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
            </li>
          ) : (
            <li style={{ flex: 0.5 }}>{examData?.examstatus}</li>
          )}
          <li style={{ flex: 0.5 }}>
            {dayjs(examData?.completedDate).format("DD/MM/YYYY")}
          </li>
        </>
      );
    } else {
      return (
        <>
          <li style={{ flex: 0.5 }}>Yet to Start</li>
          <li style={{ flex: 0.5 }}>Yet to Start</li>
          <li style={{ flex: 0.5 }}>Yet to Start</li>
          <li style={{ flex: 0.5 }}>Yet to Start</li>
          <li
            style={{ flex: 0.5 }}
            onClick={() => props.getQuestionList(data)}
            className="textLink"
          >
            {"Take Test >>"}
          </li>
        </>
      );
    }
  };
  const handleTypeChange = (e: any) => {
    let filtered: any;
    setExamTypeValue(e.target.value);
    if (e.target.value === "All") {
      setExamData(_.cloneDeep(globalexamData));
    } else {
      filtered = globalexamData.filter((data: any) => {
        return data.type === e.target.value;
      });
      setExamData(filtered);
    }
  };
  return (
    <div className="container" data-aos="zoom-in" data-aos-duration="1000">
      <div className="examTable">
        <div className="topHeader">
          <h4>Test Details</h4>
          {props.isPurchasedModule && <span>{props.title}</span>}
        </div>
        {props.isFilterRequired && (
          <div className="subjectListFilter">
            <select
              className="form-select signup-select"
              name="course"
              onChange={handleTypeChange}
              value={examTypeValue}
            >
              <option>{"All"}</option>
              {examType.map((data: any) => (
                <option value={data.value}>{data.title}</option>
              ))}
            </select>
          </div>
        )}

        <ul className="header-list">
          {/* <li style={{ flex: 0.2 }}>S.No</li> */}
          {!props.isPurchasedModule && (
            <li style={{ flex: 0.5, textAlign: "left" }}>Module/Speciality</li>
          )}
          <li style={{ flex: 0.5 }}>Type of test</li>
          <li style={{ flex: 0.5 }}>Test No</li>
          <li style={{ flex: 0.5 }}>Score</li>
          <li style={{ flex: 0.5 }}>Percentage</li>
          <li style={{ flex: 0.5 }}>Grade</li>
          <li style={{ flex: 0.5 }}>Status</li>
          <li style={{ flex: 0.5 }}>DOC</li>
        </ul>
        <div>
          {(examData || []).map((data: any, index: number) => (
            <ul className="test_list">
              {/* <li style={{ flex: 0.2 }}>{index + 1}</li> */}
              {!props.isPurchasedModule && (
                <li style={{ flex: 0.5, textAlign: "left" }}>
                  {data.type === "Speciality" || data.type === "Mock" ? (
                    <span>
                      <CodetoName
                        url="speciality/getSpecialityName/"
                        code={data.specialityCode}
                      />
                    </span>
                  ) : (
                    <span>
                      <CodetoName
                        url={`module/getModuleName/`}
                        code={data.moduleCode}
                      />
                    </span>
                  )}
                </li>
              )}
              <li style={{ flex: 0.5 }}>
                {data.type || data.title || "Module Test"}
              </li>
              <li style={{ flex: 0.5 }}>{data.testNumber}</li>
              {checkExamData(data)}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamList;
