import Image from "react-bootstrap/Image";
import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./semester.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import PackageCardSlider from "../../components/CarouselSlider/PackageCardSlider";
import PurchasedPackage from "../../components/CarouselSlider/PurchasedPackage";
import { jwtDecode } from "jwt-decode";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import authInterceptor from "../../utils/authInterceptor";
import { nsdescription } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
const Semester = () => {
  const navigate = useNavigate();
  const [subjectList, setSubjectList] = useState([]);
  const [pacakgeList, setPackageList] = useState([]);
  const [cartList, setCartList] = useState<any>([]);
  const [myCourse, setMyCourse] = useState([]);
  const { user } = useContext(AuthContext);

  const { state } = useLocation();
  const { id, name } = state || {};
  const userData = getUserData(); 
  
  const getCartItems = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}cartItems/${userData.user.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list); 
      const myCourseData = decryptAESData.map((data: any) => data.code);
      setCartList(myCourseData); 

    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}/NursingStudent`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData.map((data: any) => {
        return data.purchasedItems[0];
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getSubjectListData = async (id: any, purchasedData: any) => {
    try {
      const userData = getUserData();
      let response: any;
      if (userData) {
        response = await authInterceptor.get(
          `${adminPath}subject/FE/sem/${id}`
        );
      } else {
        response = await authInterceptor.get(
          `${adminPath}subject/FE/semnoauth/${id}`
        );
      }

      const decryptAESData: any = jsonDecryptAES(response?.data);
      const list = decryptAESData
        .map((data: any) => {
          if (!purchasedData.includes(data._id)) {
            return {
              _id: data._id,
              title: data.subjectTitle,
              code: data.subjectCode,
              shortTitle: data.shortTitle,
              description: data.subjectDescription,
              image: data.subjectImage,
              finalPrice: data.subjectPrice,
              discountPrice: data.subjectDiscountPrice,
              duration: data.subjectDurationDays,
              status: data.status,
              date: data.date,
            };
          }
        })
        .filter((list: any) => list);
      setSubjectList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getPacakgeListData = async (id: any, purchasedData: any) => {
    try {
      const userData = getUserData();
      let response: any;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}nspackage/FE/${id}`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}nspackage/FENoAuth/${id}`
        );
      }
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData
        .map((data: any) => {
          if (!purchasedData.includes(data._id)) {
            return {
              _id: data._id,
              title: data.packageName,
              listCount: data.packageSubject.length,
              list: data.packageSubject,
              image: data.subjectImage,
              finalPrice: data.packagePrice,
              discountPrice: data.packageDiscountPrice,
              duration: data.packageDurationDays,
              status: data.status,
              date: data.date,
              customPackage: data.customPackage,
              subjectCount: data.subjectCount,
              isPackage: true,
            };
          }
        })
        .filter((list: any) => list);
      setPackageList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getAlldata = async (id: any) => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      purchasedData = await getPurchasedData(userData);
    }
    await getSubjectListData(id, purchasedData);
    await getPacakgeListData(id, purchasedData);
  };
  useEffect(() => {
    if (id) {
      getAlldata(id);
    } else {
      if (userData) {
        getCartItems();
        getAlldata(userData.user.semester);
      } else {
        navigate("/ns-semester");
      }
    }
  }, [id, user]);

  const cardBtn = (data: any) => {
    if (data.isPackage) {
      navigate("/PackageDetails", {
        state: {
          isPackagePurchased: true,
          purchasedDataID: data.purchasedDataID,
          packageId: data._id,
        },
      });
    } else {
      navigate("/SubjectDetails", {
        state: {
          purchasedDataID: data.purchasedDataID,
          endDate: data.endDate,
          code: data.code,
        },
      });
    }
  };
  const packageViewMore = (data: any) => {
    navigate("/ViewSubjectPackage", {
      state: {
        isPackagePurchased: false,
        purchasedDataID: data.purchasedDataID,
        packageId: data._id,
      },
    });
  };

  const redirectUpdate = (link: string) => {
    navigate(link);
  };
  return (
    <>
      <section className="semester-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <BreadcrumbBox
            link="/ns-semester"
            redirect={
              userData && userData.user.role === "NursingStudent"
                ? userData.user.semName
                : name
            }
            currentPage="Subjects"
          />
          <div className="package-header">
            <h2 className="title">Subject & Package Details</h2>
            {userData && userData.user.role === "NursingStudent" ? (
              <Link to={"/my-package"} className="nonPurchased">
               Click here for My Package  <img src="assets/breadcrumb.svg" />
              </Link>
            ) : null}
          </div>

          <p className="desc">{nsdescription}</p>

          {/* {myCourse && myCourse.length > 0 ? (
            <div className="myCourse">
              <h3>My Courses</h3>
              <PurchasedPackage
                data={myCourse}
                cardBtn={cardBtn}
                userType={"NursingStudent"}
              />
            </div>
          ) : null}
          {myCourse.length === 0 && subjectList && subjectList.length > 0 ? (
            <div className="newCourse nocolor">
              <h3>New Courses</h3>
              <CarouselSlider
                data={subjectList}
                purchaseType={"Subject"}
                userType={"NursingStudent"}
              />
            </div>
          ) : null} */}

          <div className="newCourse nocolor">
            <CarouselSlider
              data={subjectList}
              purchaseType={"Subject"}
              userType={"NursingStudent"}
            />
          </div>
        </div>
      </section>
      <div className="newCourse-details">
        {/* {myCourse &&
        myCourse.length > 0 &&
        subjectList &&
        subjectList.length > 0 ? (
          <div className="newCourse">
            <h3>New Courses</h3>
            <CarouselSlider
              purchaseType={"Subject"}
              data={subjectList}
              userType={"NursingStudent"}
            />
          </div>
        ) : null} */}
      </div>
      {/* {pacakgeList && pacakgeList.length > 0 ? (
        <section
          className="package-details"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <div className="container package-details-box">
            <h2 className="title mt-5">{"Package Details"}</h2>
            <div className="row justify-content-center">
              <PackageCardSlider
                data={pacakgeList}
                purchaseType={"Package"}
                viewMore={packageViewMore}
                userType={"NursingStudent"}
                semId={id}
              />
            </div>
          </div>
        </section>
      ) : null} */}
    </>
  );
};

export default Semester;
