import "./AboutHeader.scss";
const AboutHeader = (props: any) => {
  return (
    <div className="AboutHeader">
      <h3>{props.text}</h3>
      <p>{props.para}</p>
    </div>
  );
};

export default AboutHeader;
