import "./FEQuizOptionStyle.scss";
import { alphabet } from "../constants/constants";
import { useEffect, useState } from "react";
import ResultAnswerOption from "./ResultAnswerOption";
const MultiOptionResult = (props: any) => {
  const [answerList, setAnswerList] = useState(props.answerList);
  useEffect(() => {
    props.answerList.map((data: any) => {
      data["selectedCheck"] = data.selectedCheck ? data.selectedCheck : false;
    });
    setAnswerList(props.answerList);
  }, [props.indexValue]);

  return (
    <div className={"multiOptionQuestions"}>
      {answerList?.map((data: any, index: number) => (
        <div className="MultiOptionList">
          <span
            className={`checkBoxOption ${data?.selectedCheck ? "active" : ""}`}
          >
            <img src="/assets/check.svg" />
          </span>
          <ResultAnswerOption
            attrbute="value"
            isActive={data?.selectedCheck}
            isCorectAnswer={data?.checked}
            isEffect={false}
            dataOption={data}
            currentOptionSelected={props.correctOption}
            option={alphabet[index]}
          />
        </div>
      ))}
    </div>
  );
};
export default MultiOptionResult;
