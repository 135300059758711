import './FEQuizOptionStyle.scss';
import AnswerOption from './AnswerOption';
import { alphabet } from '../constants/constants';
import { useState, useEffect } from 'react';
const SequenceAnswer = (props: any) => {
  const [answerList, setAnswerList] = useState(props.answerList)
  useEffect(() => {
    props.answerList.map((data: any) => {
      data['order'] = data.order?data.order: '';
    })
    setAnswerList(props.answerList)
  }, [props.indexValue]) 
  const handleChange = (e:any,data:any)=>{
    const list = [...answerList]
    const index = list.findIndex((list) => list._id === data._id)
    list[index].order = e.target.value
    setAnswerList(list)
    props.validateAnswer(list)
  }
  return (
    <div className='SequenceAnswer'>
      {props.answerList?.map((data: any, index: number) => (
        <span className='SequenceAnswerList'>
         <AnswerOption dataOption={data} attrbute="value" isEffect={false} currentOptionSelected={props.currentOptionSelected}
            option={alphabet[index]} /> 
          <input type="text" value={data.order} onChange={(e)=>handleChange(e, data)}/>
        </span>
      ))}
    </div>
  )
}
export default SequenceAnswer;