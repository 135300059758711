import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CourseCards from "../CourseCards/CourseCards";
import "./CarouselSlider.scss";
type Course = {
  title: string;
  description: string;
  imagePath: string;
  discountPrice: string;
  finalPrice: string;
};

const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <span className={className} onClick={onClick}> 
      <img src="assets/nextIcon.png" alt=">" />
    </span>
  );
};

const PrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <span className={className} onClick={onClick}> 
      <img src="assets/prevIcon.png" alt="<"/>
    </span>
  );
};

const CarouselSlider = (props: any) => {
  const [sliderData, setSliderData] = useState<Course[]>([]);
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow className="im-next-btn" />,
    prevArrow: <PrevArrow className="im-prev-btn" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  useEffect(() => {
    if (props.data?.length) {
      setSliderData(props.data);
    }
  }, [props.data]);
  const cardBtn = (data: any) => {
    console.log(data);
  };
  return (
    <div className="list-slider-carousel">
      {props.isCourseList
        ? sliderData && (
            <Slider {...settings}>
              {sliderData.map((course: any) => (
                <CourseCards
                  course={course}
                  isPrice={props.isPrice}
                  buttonText={props.buttonText}
                  userType={props.userType}
                  isSemester={props.isSemester}
                  purchaseType={props.purchaseType}
                  isViewBtn={props.isViewBtn}
                  buuttonFunction={()=>props.buuttonFunction(course)}
                />
              ))}
            </Slider>
          )
        : sliderData && (
            <Slider {...settings}>
              {sliderData.map((course: any) => (
                <CourseCards
                  course={course}
                  isPrice={true}
                  userType={props.userType}
                  isSemester={props.isSemester}
                  purchaseType={props.purchaseType}
                  buttonText="Buy Now"
                  buuttonFunction={cardBtn}
                />
              ))}
            </Slider>
          )}
    </div>
  );
};

export default CarouselSlider;
