import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./PurchasedSubject.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import axios from "axios";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import MenuBookIcon from "../../assets/menu_book.png";
import CalenderIcon from "../../assets/calendar_month_gray.svg";
import CalenderWhiteIcon from "../../assets/calendar_month.svg";
import MenuBookWhiteIcon from "../../assets/menu_book_white.svg";
import BtnWrapper from "../../common/BtnWrapper";
import { Button, Card, ListGroup } from "react-bootstrap";
import BackBtn from "../../assets/backbtn.svg";
import GradeBox from "../../components/GradeBox/GradeBox";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import authInterceptor from "../../utils/authInterceptor";
import { nsdescription } from "../../constants/constants";
const ViewSubjectPackage = () => {
  const [packageData, setPackageData] = useState<any>();
  const [subjectNameList, setSubjectNameList] = useState<any>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { packageId, isPackagePurchased } = state || {};
  const userData = getUserData();

  const getPackageByID = async (id: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}nspackage/FEPackage/${id}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      let obj = {
        _id: decryptAESData._id,
        title: decryptAESData.packageName,
        listCount: decryptAESData.packageSubject.length,
        list: decryptAESData.packageSubject,
        image: decryptAESData.subjectImage,
        finalPrice: decryptAESData.packagePrice,
        discountPrice: decryptAESData.packageDiscountPrice,
        duration: decryptAESData.packageDurationDays,
        status: decryptAESData.status,
        date: decryptAESData.date,
        semester:decryptAESData.semester,
        semName:decryptAESData.semName,
        isPackage: true,
      };
      getSubjectName(decryptAESData?.packageSubject);
      setPackageData(obj);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getSubjectName = async (data: any) => {
    const url = userData
      ? `${adminPath}subject/getSubjectName`
      : `${adminPath}subject/getSubjectName/NoAuth`;
    const response: any = await authInterceptor.post(url, { data });
    setSubjectNameList(response?.data?.list);
  };
  const getPackagedata = async () => {
    await getPackageByID(packageId);
  };
  useEffect(() => {
    getPackagedata();
  }, [state]);

  return (
    <>
      <section className="subjectdetails-wrapper">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div className="container subjectdetails-box">
          <div>
            <img 
              src={BackBtn}
              alt="back-btn"
              onClick={() => navigate(-1)}
              className="backbtn"
            />
          </div>
          <div
            className="subjectdetails-box-detail-conatiner"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="subjectdetails-box-header">
              <BreadcrumbBox
                link="/semester"
                redirect={(userData && userData.user.role === 'NursingStudent') ? userData.user.semName : packageData?.semName}
                currentPage="Package"
              />
              <h2 className="purchasedTitle viewpackage">{packageData?.title}</h2>
              <p className="desc">{nsdescription}
              </p>
              <p className="price">
                Price : <span className="rupee-symbol">&#x20B9;</span>
                {packageData?.discountPrice && packageData?.discountPrice != 0
                  ? packageData?.discountPrice
                  : packageData?.packagePrice}
              </p>
              <ul className="subjectdetails-box-suboptions">
                <li>
                  <img src={MenuBookWhiteIcon} alt="menu-book" />
                  {`Total Number of Subjects : ${subjectNameList.length}`}
                </li>
                <li>
                  <img src={CalenderWhiteIcon} alt="menu-book" />
                  {`Number of Days : ${packageData?.duration}`}
                </li>
              </ul>
              <div style={{ maxWidth: "300px" }}>
                {isPackagePurchased ? (
                  <Button
                    variant="primary"
                    style={{ visibility: "hidden" }}
                    className="buynow-button"
                    size="lg"
                  >
                    Take Test
                  </Button>
                ) : (
                  <BtnWrapper
                    paydata={packageData}
                    purchaseType={"Package"}
                    userType={"NursingStudent"}
                  />
                )}
              </div>
            </div>
            <div className="subject-list-card">
              <Card.Title>Subjects List</Card.Title>
              <ListGroup>
                {(subjectNameList || []).map((subject: string, index: any) => (
                  <ListGroup.Item key={index}>
                    <img src="assets/check_circle.svg" />
                    <span>{subject}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>
          <GradeBox />
        </div>
      </section>
    </>
  );
};

export default ViewSubjectPackage;
