import "./TestProcess.scss";
const TestProcess = () => {
  return (
    <div className="TestProcess">
      <div className="container">
        <h3>Online Test</h3>
        <p>
          Impetus nursing online test is an assessment designed to evaluate the
          knowledge, knowledge on skills, and competencies of individuals in the
          field of nursing through an online platform. These tests are designed
          for nursing students and nurses for professional development to ensure
          that individuals possess the necessary understanding and proficiency
          required for their roles in healthcare. These tests are typically
          administered through online platforms, providing a convenient and
          accessible way for candidates to demonstrate their understanding of
          various nursing concepts and subjects.
        </p>
        <p>
          To prepare for a Nursing online test, candidates may engage in
          self-directed study, review course materials, and participate in
          practice exams to enhance their readiness for the actual assessment.
        </p>
        <p>Key features of Impetus nursing online test may include:</p>
        <div className="onlineTest-point">
          <h5>Content Coverage:</h5>
          <p>
            Online test typically covers a broad range of nursing topics,
            including anatomy, physiology, nursing foundation, microbiology,
            Biochemistry, psychology, sociology, pharmacology, medical-surgical
            nursing, paediatric nursing, obstetric and gynaecologic nursing,
            psychiatric nursing, nursing research, and other specialized areas.
          </p>
        </div>
        <div className="onlineTest-point">
          <h5>Format:</h5>
          <p>
            Questions are presented in various formats, such as multiple-choice
            questions (MCQs), true/false statements, match the following,
            multiple options, arranging in sequence questions. Some tests may
            also include scenario-based questions to assess critical thinking
            and decision-making skills.
          </p>
        </div>
        <div className="onlineTest-point">
          <h5>Level of Questions:</h5>
          <p>
            There are three levels of questions in the online test portal to
            evaluate the comprehensive knowledge of the participant;
          </p>
          <ul>
            <li>Level – 1 questions will focus on assessment of factual knowledge</li>
            <li>Level - 2 questions will focus on assessment of functional knowledge</li>
            <li>Level - 3 questions will focus on assessment of problem solving ability</li>
          </ul>
          <p>The marks for the level of questions will 1,2 &amp; 3 relatively.</p>
        </div>
        <div className="onlineTest-point">
          <h5>Time Constraints:</h5>
          <p>
            The test is timed to assess the ability of individuals to manage
            their time effectively, reflecting real-world situations in the
            university examination and competitive examinations conducted by
            various recruitment boards where time management is crucial.
          </p>
        </div>
        <div className="onlineTest-point">
          <h5>Security Measures:</h5>
          <p>
            To maintain the integrity of the assessment, security measures such
            as randomized question order, randomized option order, question
            pooling, and monitoring tools are implemented to prevent
            hoodwinking.
          </p>
        </div>
        <div className="onlineTest-point">
          <h5>Remote Accessibility:</h5>
          <p>
            Since it is an online test, individuals can take it remotely,
            allowing for flexibility in scheduling and accessibility for a
            diverse group of participants.
          </p>
        </div>
        <div className="onlineTest-point">
          <h5>Scoring and Feedback:</h5>
          <p>
            Immediate scoring and grading shall be provided upon completion of
            the test, allowing test-takers to identify areas of strength and
            weakness. This feedback / grading shall be valuable for further
            learning and improvement.
          </p>
        </div>
        <div className="onlineTest-point">
          <h5>Technical Requirements:</h5>
          <p>
            Participants need access to a computer, tablet, smart mobile or
            other electronic devices with a stable internet connection. The
            online platform shall be user- friendly and accessible to
            individuals with varying levels of technical proficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestProcess;
