import './FEQuizOptionStyle.scss';
import AnswerOption from './AnswerOption';
import { alphabet } from '../constants/constants';
import { useState, useEffect } from 'react';
const SequenceAnswerResult = (props: any) => {
  const [answerList, setAnswerList] = useState(props.answerList)
  useEffect(() => {
    props.answerList.map((data: any) => {
      data['order'] = data.order?data.order:0;
    })
    setAnswerList(props.answerList)
  }, [props.indexValue])  
  return (
    <div className='SequenceAnswer'>
      {props.answerList?.map((data: any, index: number) => (
        <span className='SequenceAnswerList'>
         <AnswerOption dataOption={data} attrbute="value" isEffect={false} currentOptionSelected={props.currentOptionSelected}
            option={alphabet[index]} /> 
          <input value={data.seqAnswer} disabled/>
          <input className={`${data.order === data.seqAnswer? 'correctMatch':'wrongMatch'}`} value={data.order} disabled/>
        </span>
      ))}
    </div>
  )
}
export default SequenceAnswerResult;