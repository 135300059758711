import React from "react";
import { useTimer } from "react-timer-hook";

const MyTimer = ({ expiryTimestamp, deleteTempData }: any) => {
  const { totalSeconds, seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp,
    onExpire: deleteTempData,
  });

  return (
    <div style={{ textAlign: "center" }}>
      <div className="My-Timer-Box">
        {hours > 0 && (
          <>
            <span>{hours}</span> <span>{" : "}</span>
          </>
        )}{" "}
        <span>{minutes}</span> <span>{" : "}</span> <span>{seconds}</span>
      </div>
    </div>
  );
};

export default MyTimer;
