import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import MyAccount from "./MyAccount";
import Logo from "../../assets/logo.jpg";
import BackArrow from "../../assets/arrow_back.svg";
import "./header.scss";
import Modal from "react-bootstrap/Modal";
import FELogin from "../FELogin/FELogin";
import FESignUp from "../FESignUp/FESignUp";
import { jwtDecode } from "jwt-decode";
import AuthContext from "../../contexts/AuthContext";
import { LoginModalContext } from "../../contexts/LoginModalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ExamNotifyModal from "../ExamNotifyModal/ExamNotifyModal";
import axios from "axios";
import { adminPath } from "../../constants/api";
import { jsonDecryptAES, jsonEncryptAES } from "../../utils/common";
import dayjs from "dayjs";
import authInterceptor from "../../utils/authInterceptor";
import CartDrawer from "../CartDrawer/CartDrawer";
import { CartModalContext } from "../../contexts/CartModalContext";
const Header = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("SignUp");
  const [token, setToken] = useState<any>();
  const [name, setName] = useState<any>();
  const [isQuiz, setIsQuiz] = useState<any>();
  const [examPopData, setExamPopData] = useState([]);
  const [notifyExamPQID, setNotifyExamPQID] = useState<any>();
  const [selectedExam, setSelectedExam] = useState<any>();
  const [quizQuestionList, setQuizQuestionList] = useState();

  const [showExamNotifyModal, setshowExamNotifyModal] =
    useState<boolean>(false);
  const [active, setActive] = useState("home");
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = location;
  const handleOpen = () => {
    setToster({ triggerPopup: true });
  };
  const handleClose = () => {
    setToster({ triggerPopup: false });
  };

  const { logout } = useContext(AuthContext);
  const { setToster, state } = useContext(LoginModalContext);
  const { setCart } = useContext(CartModalContext);

  const userData: any = token ? jwtDecode(token) : {};
  useEffect(() => {
    const tokenCache: any = sessionStorage.getItem("token");
    if (tokenCache) {
      const tokenData: any = jwtDecode(tokenCache);
      setName(tokenData?.user?.name);
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    setIsOpen(state.triggerPopup);
  }, [state.triggerPopup]);

  // To disable right click
  // useEffect(() => {
  //   const handleContextmenu = (e: any) => {
  //     e.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", handleContextmenu);
  //   return function cleanup() {
  //     document.removeEventListener("contextmenu", handleContextmenu);
  //   };
  // }, []);


  // Http to https redirect
  // useEffect(() => {
  //   if (window.location.protocol.toLowerCase() !== "https:") {
  //     const url = `https://${window.location.host}`;
  //     window.location.replace(url);
  //   }
  // }, []);
  useEffect(() => {
    setShowModal(state.triggerPopup);
  }, [state.triggerPopup]);
  useEffect(() => {
    console.log("pathname", pathname);
    setIsQuiz(pathname.includes("Quiz") || pathname.includes("score"));
  }, [location]);
  useEffect(() => {
    const tokenCache: any = sessionStorage.getItem("token");
    if (tokenCache) {
      const tokenData: any = jwtDecode(tokenCache);
      setName(tokenData?.user?.name);
      setToken(sessionStorage.getItem("token"));
    }
  }, [showModal]);
  const optionChnage = (value: string) => {
    setSelectedOption(value);
    handleOpen();
  };
  const logoutFn = async () => {
    let payload = {
      IHSID: userData?.user?.IHSID,
    };
    const apiResponse = await authInterceptor.post(
      `${adminPath}FElanding/logout`,
      payload
    );
    console.log("apiResponse :", apiResponse);
    logout();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("refID");
    setToken(null);
    navigate("/home");
  };
  useEffect(() => {
    if (quizQuestionList) {
      try {
        authInterceptor
          .post(`${adminPath}tempdata`, {
            questionData: {
              selectedExam: selectedExam?._id,
              pqdId: notifyExamPQID,
              noOfQuestions: selectedExam?.noOfQuestions,
              duration: selectedExam?.duration,
              passPercent: selectedExam?.passPercent,
              totalMark: selectedExam?.totalQuestionMark,
              quizQuestionList: quizQuestionList,
              examtype: selectedExam.type,
            },
          })
          .then((response: any) => {
            navigate("/testPage", {
              state: { testId: response?.data?.id, title: "" },
            });
          });
      } catch (error: any) {
        if (error.response) {
        }
      }
    }
  }, [quizQuestionList]);
  const takeNotifyTest = async (examData: any) => {
    setshowExamNotifyModal(false);
    const timeDuration = dayjs(examData.endTime).diff(dayjs(), "minute");
    examData.duration = timeDuration;

    let PQDObj: any = {
      orderID:
        new Date().getFullYear() +
        new Date().getMonth() +
        new Date().getDate() +
        new Date().getHours() +
        new Date().getMinutes() +
        new Date().getSeconds() +
        new Date().getUTCMilliseconds(),
      userType: userData.user.role,
      IHSID: userData.user.IHSID,
      purchasedDate: dayjs(),
      packageData: {},
      examData: examData,
      collageExamId: examData._id,
      StudentName: userData.user.name,
      userName: userData.user.name,
      collageName: examData.collageName,
      semester: examData.semester,
      subject: examData.subject,
      type: examData.type,
    };
    const encryptPQD = await jsonEncryptAES(PQDObj);
    const purchasedDataID = await authInterceptor.post(`${adminPath}PQD/FE/`, {
      data: encryptPQD,
    });
    setNotifyExamPQID(purchasedDataID.data?.id);
    setTimeout(() => {
      getQuestionList(examData, true);
    });
  };

  const getQuestionList = async (selectedType: any, isNotify?: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          unitCode: selectedType.selecteUnitList,
          subjectCode: selectedType.subject,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Quiz`, obj);
      });
      axios.all(requests).then((responses) => {
        responses.forEach((resp: any) => {
          const decryptAESData: any = jsonDecryptAES(resp.data.list);
          finalData.push(...decryptAESData);
        });
        setQuizQuestionList(finalData);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getpurchasedDataIDData = async (college: any, semester: any) => {
    const tokenVal: any = sessionStorage.getItem("token");
    const userDataId: any = tokenVal ? jwtDecode(tokenVal) : {};
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/IHSID/${userDataId?.user?.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);

      const examListArray: any = [];
      let collageExamIdList: any = [];
      decryptAESData.map((data: any) => {
        data?.examData.map((inndata: any) => {
          inndata.pqdID = data?._id;
          if (data.collageExamId) collageExamIdList.push(data.collageExamId);
        });
        examListArray.push(...data?.examData);
      });
      await getExamList(college, semester, collageExamIdList);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getExamList = async (
    college: any,
    semester: any,
    collageExamIdList: any
  ) => {
    try {
      const apiResponse = await authInterceptor.get(
        `${adminPath}scheduleExam/FE/${college}/${semester}`
      );
      const decryptAESData = await jsonDecryptAES(apiResponse.data.list);
      const filteredData: any = decryptAESData.filter((data: any) => {
        const isEnddateBefore = dayjs().isBefore(dayjs(data?.endTime));
        return !collageExamIdList.includes(data?._id) && isEnddateBefore;
      });

      setExamPopData(filteredData);
      if (filteredData.length > 0) setshowExamNotifyModal(true);
    } catch (error: any) {
      console.log("error", error);
    }
  };
  const handleModal = async (college: any, semester: any) => {
    await getpurchasedDataIDData(college, semester);
    handleClose();
  };
  const handleExamNotifyClose = () => {
    setshowExamNotifyModal(false);
  };
  const handleActive = (route: any) => {
    setActive(route);
  };
  const getCartItems = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}cartItems/${userData.user.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const cartData = decryptAESData.map((data: any) => data?.cartList?.code);
      const purchaseType = decryptAESData.map(
        (data: any) => data?.purchaseType
      );
      return { cartData, purchaseType };
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const updateCart = async () => {
    const { cartData, purchaseType }: any = await getCartItems();
    window.scrollTo(0, 0);
    setCart({
      triggerPopup: true,
      type:
        userData.user.role === "NursingStudent"
          ? "Subject"
          : purchaseType.includes("Module")
          ? "Module"
          : "Speciality",
    });
  };
  const demoTestChange = () => {
    if (token) {
      handleActive("Demo Test");
      navigate("/demoTest");
    } else {
      setToster({ triggerPopup: true });
    }
  };
  return (
    <div>
      <CartDrawer />
      {isQuiz ? (
        <div className="quiz-header">
          <Container className="quiz-header-container">
            <div style={{cursor:'pointer'}} onClick={() => navigate("/home")}>
              <img
                src={BackArrow}
                className="backArrow-logo"
                alt=""
              />
              <span>GO TO HOME PAGE</span>
            </div>

            <img src={Logo} className="brand-logo" alt="" />
          </Container>
        </div>
      ) : (
        <Navbar
          expand="lg"
          className="header-wrapper-bg bg-body-tertiary im-navbar"
        >
          <Container>
            <Navbar.Brand href="#home">
              <img src={Logo} className="brand-logo" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="im-menubar">
                <Link
                  to={"home"}
                  className={
                    active === "home" ? "menu-link active-link" : "menu-link"
                  }
                  onClick={() => {
                    handleActive("home");
                  }}
                >
                  Home
                </Link>
                <Link
                  to={"aboutus"}
                  className={
                    active === "about" ? "menu-link active-link" : "menu-link"
                  }
                  onClick={() => {
                    handleActive("about");
                  }}
                >
                  About Us
                </Link>
                <Link
                  to={"nursing-students"}
                  className={
                    active === "nursingStudent"
                      ? "menu-link active-link"
                      : "menu-link"
                  }
                  onClick={() => {
                    handleActive("nursingStudent");
                  }}
                >
                  Nursing Students
                </Link>
                <Link
                  to={"nurse"}
                  className={
                    active === "nurse" ? "menu-link active-link" : "menu-link"
                  }
                  onClick={() => {
                    handleActive("nurse");
                  }}
                >
                  Nurses
                </Link>
                <Link
                  to={"onlinetest"}
                  className={
                    active === "Online Test"
                      ? "menu-link active-link"
                      : "menu-link"
                  }
                  onClick={() => {
                    handleActive("Online Test");
                  }}
                >
                  Online Test
                </Link>

                <a
                  className={
                    active === "Demo Test"
                      ? "menu-link active-link"
                      : "menu-link"
                  }
                  onClick={() => {
                    demoTestChange();
                  }}
                >
                  Demo Test
                </a>
              </Nav>
            </Navbar.Collapse>

            <>
              {token ? (
                <>
                  <span className="CartBtn headerCart" onClick={updateCart}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                    </svg>
                  </span>

                  <Navbar.Collapse className="justify-content-end profile-section">
                    <div className="profilePhoto">{name.charAt(0)}</div>
                    <NavDropdown
                      title={<MyAccount name={name} />}
                      className="profile-wrapper"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="profile"
                        className="logout-btn"
                      >
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="#"
                        className="logout-btn"
                        onClick={logoutFn}
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Navbar.Collapse>
                </>
              ) : (
                <div className="login_btn">
                  <Button
                    onClick={() => optionChnage("Login")}
                    className="imbtn-primary"
                    size="lg"
                  >
                    Login
                  </Button>
                  <span></span>
                  <Button
                    onClick={() => optionChnage("SignUp")}
                    className="imbtn-secondary"
                    size="lg"
                  >
                    Sign Up
                  </Button>
                </div>
              )}
            </>
          </Container>
          <Modal
            show={showExamNotifyModal}
            centered
            size="lg"
            className="ExamNotifyModal"
          >
            <a onClick={handleExamNotifyClose} className="popup_close">
              X
            </a>
            <Modal.Body className="exam-notify-popup">
              <p className="title">You have a Scheduled Exam</p>
              {examPopData.map((data: any) => (
                <ExamNotifyModal
                  data={data}
                  takeNotifyTest={takeNotifyTest}
                  handleExamNotifyClose={handleExamNotifyClose}
                ></ExamNotifyModal>
              ))}
            </Modal.Body>
          </Modal>

          <Modal
            show={showModal}
            centered
            size="xl"
            className={`${
              selectedOption === "SignUp" ? "signup-popup-wrapper" : ""
            } login-popup-wrapper`}
            onHide={handleClose}
            onRequestClose={handleClose}
          >
            <a onClick={handleClose} className="popup_close">
              X
            </a>
            <Modal.Body className="login-popup">
              <img className="login-bg" src="assets/login-bg.png" alt="bg" />
              <div className="login_tabs">
                {selectedOption === "Login" ? (
                  <FELogin
                    handleClose={handleClose}
                    handleModal={handleModal}
                    redirectToSignUp={optionChnage}
                  />
                ) : null}

                {selectedOption === "SignUp" ? (
                  <FESignUp
                    handleClose={handleClose}
                    redirectToSignUp={() => optionChnage("Login")}
                  />
                ) : null}
                {selectedOption === "ForgotPassword" ? (
                  <ForgotPassword
                    handleClose={handleClose}
                    redirectToSignUp={() => optionChnage("Login")}
                  />
                ) : null}
              </div>
            </Modal.Body>
          </Modal>
        </Navbar>
      )}
    </div>
  );
};

export default Header;
