import './FEQuizOptionStyle.scss';
import AnswerOption from './AnswerOption';
import { alphabet } from '../constants/constants';
import { useState, useEffect } from 'react';
const MatchAnswerResult = (props: any) => {
  const [answerList, setAnswerList] = useState(props.answerList)
  useEffect(() => {
    props.answerList.map((data: any) => {
      data['order'] = data.order?data.order:null;
    })
    setAnswerList(props.answerList)
  }, [props.indexValue]) 
 
  return (
    <div className='MatchAnswer'>
      {props.answerList?.map((data: any, index: number) => (
        <span className='MatchAnswerList'>
          <AnswerOption dataOption={data} attrbute="value" isEffect={false} currentOptionSelected={props.currentOptionSelected}
            option={alphabet[index]} />
          <AnswerOption dataOption={data} attrbute="matchAnswer" isEffect={false} currentOptionSelected={props.currentOptionSelected}
          />
          <input value={data.matchOrder} disabled/>
          <input className={`${data.order === data.matchOrder? 'correctMatch':'wrongMatch'}`} value={data.order} disabled/>
        </span> 
      ))}
    </div>
  )
}
export default MatchAnswerResult;