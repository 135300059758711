import { useContext, useEffect, useState } from "react";
import CourceTabs from "./Tabs";
import "./courses.scss";
import axios from "axios";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import AuthContext from "../../contexts/AuthContext";
import authInterceptor from "../../utils/authInterceptor";

const Courses = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [semesterList, setSemesterList] = useState<any>([]);
  const [specialityList, setSpecialityList] = useState<any>([]);
  const getSemesterListData = async () => {
    const userData = getUserData();
    try {
      let response: any;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}semester/FE`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}semester/FE/NoAuthSem`
        );
      }

      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      let filterData;
      if (userData && userData.user.role === "NursingStudent") {
        filterData = decryptAESData.filter(
          (data: any) => data.semCode === userData?.user?.semester
        );
      } else {
        filterData = decryptAESData;
      }
      const list = filterData.map((data: any) => {
        return {
          _id: data._id,
          title: data.semName,
          code: data.semCode,
          shortTitle: data.shortTitle,
          description: data.semDescription,
          image: data.semImage,
          list: data.subjectList,
          status: data.status,
          date: data.date,
        };
      });
      setSemesterList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getSpecalityListData = async () => {
    try {
      let response: any;
      const userData = getUserData();
      if (userData) {
        response = await authInterceptor.get(`${adminPath}speciality/FE`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}speciality/NoAuth/FE`
        );
      }
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData.map((data: any) => {
        return {
          _id: data._id,
          title: data.specialityTitle,
          code: data.specialityCode,
          shortTitle: data.shortTitle,
          description: data.specialityDescription,
          image: data.specialityImage,
          list: data.moduleList,
          status: data.status,
          date: data.date,
        };
      });
      setSpecialityList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  useEffect(() => {
    getSemesterListData();
    getSpecalityListData();
  }, []);
  useEffect(() => {
    getSemesterListData();
    getSpecalityListData();
  }, [user]);

  const semViewCourse = (data: any) => {
    navigate("/semester", { state: { id: data.code, name: data.title } });
  };
  const specViewCourse = (data: any) => {
    navigate("/module", { state: { id: data.code, name: data.title } });
  };
  const tabsObj = [
    {
      key: "nursingstudent",
      value: "Nursing Students",
      courseData: semesterList,
      isPrice: false,
      buttonText: "View Courses",
      tabkey:"NursingStudent",
      isSemester: true,
      buuttonFunction: semViewCourse,
    },
    {
      key: "nurse",
      value: "Nurses",
      courseData: specialityList,
      tabkey:"Nurse",
      isPrice: false,
      buttonText: "View Courses",
      buuttonFunction: specViewCourse,
    },
  ];
  return (
    <div className="im-courses">
      <h2 className="title">{"Knowledge Challenge"}</h2>
      <p className="subtitle">Embrace the Challenge, Conquer the Unknown !</p>
      <CourceTabs tabs={tabsObj} />
    </div>
  );
};

export default Courses;
