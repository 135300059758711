import "./ProfileHeader.scss";
import BackBtn from "../../assets/backbtn.svg";
import { useNavigate } from "react-router-dom";

const ProfileHeader = (props: any) => {
  const navigate = useNavigate();

  return (
    <div className="ProfileHeader">
      <div className="container">
        <div className="backBtnWrapper">
          <img
            src={BackBtn}
            alt="back-btn"
            onClick={() => navigate(-1)}
            className="backbtn"
          />
          <h1 className="profileText">Profile</h1>
        </div>
        <div className="nameWrapper">
          <div className="profilePhoto">{props.initial}</div>
          <span className="nameTextWrapper">
            <span className="name">{props.profileData.name.value}</span>
            <span className="IHSID"><strong>IHSID : </strong>{props.userData?.user?.IHSID}</span>
            <span className="role">
              {props.profileData.type.value} -{" "}
              {props.userData?.user?.role === "Nurse"
                ? props.userData?.user?.rn
                : props.userData?.user?.semName}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
