import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./Module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import { dateDiff, jsonDecryptAES } from "../../utils/common";
import PurchasedPackage from "../../components/CarouselSlider/PurchasedPackage";
import { jwtDecode } from "jwt-decode";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import authInterceptor from "../../utils/authInterceptor";
import { nursedescription } from "../../constants/constants";
const NurseMyPackage = () => {
  const navigate = useNavigate();
  const [myCourse, setMyCourse] = useState([]);
  const { state } = useLocation();
  const { id } = state || {};
  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}/Nurse`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      let expiredOrder: any = [];
      const filteredData = decryptAESData.filter((data: any) => {
        const diffValue: any = dateDiff(data.endDate);
        if (!(diffValue > 0)) {
          expiredOrder.push(data.orderID);
        }
        return diffValue > 0;
      });
      if (expiredOrder.length > 0) {
        await authInterceptor.post(`${adminPath}order/deleteAll`, {
          ordeIds: expiredOrder,
        });
      }
      const list = filteredData.map((data: any) => {
        return data.purchasedItems[0];
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getAlldata = async (id: any) => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      purchasedData = await getPurchasedData(userData);
    }
  };
  useEffect(() => {
    getAlldata(id);
  }, [id]);
  const cardBtn = (data: any) => {
    if (data.isPackage) {
      navigate("/SpecialityPackage", {
        state: {
          isPackagePurchased: true,
          packageId: data._id,
          purchasedDataID: data.purchasedDataID,
          code:data.code,
          endDate: data.endDate,
        },
      });
    } else {
      navigate("/PurchasedModule", {
        state: {
          purchasedDataID: data.purchasedDataID,
          endDate: data.endDate,
          code: data.code,
        },
      });
    }
  };
  return (
    <>
      <section className="speciality-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <BreadcrumbBox
            link="/allmodule"
            redirect={"All Modules"}
            currentPage="Module"
          />
          <div className="package-header">
            <h2 className="title">My Packages</h2>
            <Link to={"/allmodule"} className="nonPurchased">
              Click here for Non Purchased Modules  <img src="assets/breadcrumb.svg" />
            </Link>
          </div>

          <p className="desc">{nursedescription}</p>
          {myCourse && myCourse.length > 0 ? (
            <div className="myCourse">
              <PurchasedPackage data={myCourse} cardBtn={cardBtn} />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default NurseMyPackage;
