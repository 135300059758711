import { useContext } from 'react'; 
import "./RegisterNowBtn.scss"
import { Button } from 'react-bootstrap';
import { LoginModalContext } from '../../contexts/LoginModalContext';
 
const RegisterNowBtn = (props: any) => { 
  const { setToster } = useContext(LoginModalContext);
  return (
    <Button onClick={()=>setToster({triggerPopup:true})} className={`${props.isSubjectBtn ? "subjectBtn":"" } regnow-button`} size="lg">Register Now</Button>
  );
}

export default RegisterNowBtn;