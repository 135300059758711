import { useEffect, useState } from "react";
import "./FEQuizOptionStyle.scss";
const TrueOrFalseOption = (props: any) => {
  const [isRefreshed, setIsRefreshed] = useState(false);
  useEffect(() => {
    setIsRefreshed(true);
  }, [props.indexValue]);
  const checkForAns = (props: any) => {
    let className = "AnswerOption";
    if (props?.highlightCorrect) {
      className += props.highlightCorrect ? " effects correctAns" : " effects ";
    } else {
      className +=
        props.currentOptionSelected == "True" ? " effects active" : " effects ";
    }
    return className;
  };
  return (
    <>
      {isRefreshed && (
        <div className="TrueOrFalseOption">
          <div
            className={`AnswerOption effects ${
              props.currentOptionSelected === "True" ? "active" : ""
            }`}
            style={{ marginRight: "30px" }}
            onClick={() => props.validateAnswer("True")}
          >
            <span className="ordernumber">A</span>
            <span className="answer">True</span>
          </div>
          <div
            className={`AnswerOption effects ${
              props.currentOptionSelected === "False" ? "active" : ""
            }`}
            onClick={() => props.validateAnswer("False")}
          >
            <span className="ordernumber">B</span>
            <span className="answer">False</span>
          </div>
        </div>
      )}
    </>
  );
};
export default TrueOrFalseOption;
