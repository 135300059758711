import { useNavigate } from "react-router-dom";
import "./ScoreCardV3.scss";
import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import bottom from "../../assets/clientimages/Scorecard/bottom.svg";
import ThumbsUp from "../../assets/clientimages/Scorecard/thumb-up.svg";
import ThumbsDown from "../../assets/clientimages/Scorecard/thumb-down.svg";
const ScoreCardV3 = (props: any) => {
  const [gradeData, setGradeData] = useState<any>(props.gradeData);
  const navigate = useNavigate();

  useEffect(() => {
    setGradeData(props.gradeData);
  }, [props.gradeData]);
  return (
    <div
      className={`ScoreCardV3-wrapper ${
        props.isPass ? "passCard" : "failCard"
      }`}
    >
      <div style={{ position: "relative" }}>
        <div className="ScoreCardV3-wrapper-header">
          <span>{"Thank you for"}</span>
          <br />
          <span>completing Online Test!</span>
        </div>
        <div className="ScoreCardV3-wrapper-percentage-sec">
          <div> {`${props.percentage}%`}</div>
          {props.isPass ? (
            <img className="ScoreCardV3-wrapper-thumbs-image up" src={ThumbsUp} />
          ) : (
            <img
              className="ScoreCardV3-wrapper-thumbs-image down"
              src={ThumbsDown}
            />
          )}
        </div>
        <div className="ScoreCardV3-wrapper-grade-sec">
          <span>Grade</span>
          <span>{gradeData?.grade}</span>
        </div>
      </div>
      <div className="ScoreCardV3-wrapper-scoredata-wrapper">
        <div className="ScoreCardV3-wrapper-scoredata">
          <span>Score</span>
          <span>
            {props.score} / {props.overAllMark}
          </span>
        </div>
        <div className="ScoreCardV3-wrapper-scoredata">
          <span>Correct Answers</span>
          <span>
            {props.correctList} / {props.count}
          </span>
        </div>
        <div className="ScoreCardV3-wrapper-scoredata">
          <span>Remark</span>
          <span>{gradeData?.text}</span>
        </div>
        <div className="ScoreCardV3-wrapper-scoredata">
          <span>Duration</span>
          <span>{props.examDuration}</span>
        </div>
      </div>
      {/* <img className="ScoreCardV3-wrapper-bottom-image" src={bottom} /> */}
    </div>
  );
};

export default ScoreCardV3;
