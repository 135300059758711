import dayjs from "dayjs"; 
import UnittoName from "../CodetoName/UnittoName";
import CodetoName from "../CodetoName/CodetoName";
 import isBetween from "dayjs/plugin/isBetween"
const ExamNotifyProfile = (props: any) => { 
  dayjs.extend(isBetween) 
 const isStarted = dayjs().isBetween(dayjs(props.data?.startTime), dayjs(props.data?.endTime)) 
  return (
    <div className="cardWrapper">
      <div className="headerWrapper">
        <div className="title">You have a Scheduled Exam</div> 
      </div>
      <div className="contentWrapper">
        <div className="testItem">
          <span className="testItemtext">Test Type</span>
          <span className="testItemValue">{props.data?.type}</span>
        </div>
        <div className="testItem">
          <span className="testItemtext">Subject</span>
          <span className="testItemValue">
            <CodetoName
              url="subject/getSubjectName/"
              code={props.data?.subject}
            />
          </span>
        </div>

        <div className="testItem">
          <span className="testItemtext">Total Units</span>
          <span className="testItemValue">
            <UnittoName
              url="unit/FE/getUnitName/"
              code={props.data?.selecteUnitList}
            />
            {/* {props.data?.selecteUnitList.length} */}
          </span>
        </div>
        <div className="testItem">
          <span className="testItemtext">Duration</span>
          <span className="testItemValue">{props.data?.duration} Mins</span>
        </div>

        <div className="testItem">
          <span className="testItemtext">Start Date</span>
          <span className="testItemValue">
            {dayjs(props.data?.examDate).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="testItem">
          <span className="testItemtext">Time</span>
          <span className="testItemValue">
            {dayjs(props.data?.startTime).format("hh:mm A")}
          </span>
        </div>
        <div className="testItem">
          <span className="testItemtext">End Time</span>
          <span className="testItemValue">
            {dayjs(props.data?.endTime).format("hh:mm A")}
          </span>
        </div>
        <div className="btnWrapper">
          <button
            className="test-btn"
            disabled={!isStarted}
            onClick={() => props.getQuestionList(props.data)}
          >
            Take Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamNotifyProfile;
