import './FEQuizOptionStyle.scss'
import ResultAnswerOption from "./ResultAnswerOption";
import { alphabet } from "../constants/constants"; 
import ResultAnswerImage from './ResultAnswerImage';
const MCQResult = (props: any) => {
  return (
    <div className={'mcqQuestions'}> 
      {props.answerList?.map((data: any, index: number) => (
        <div className="mcqAnswerList">
          {props.isImageOption ?
            <ResultAnswerImage isEffect={false} dataOption={data}  
            isCorectAnswer={props.currentOptionSelected?.checked} 
            isActive={props.currentOptionSelected?._id === data?._id} 
            currentOptionSelected={props.currentOptionSelected} correctOption={props?.correctOption} option={alphabet[index]} /> :
            <ResultAnswerOption attrbute="value"
              isActive={props.currentOptionSelected?._id === data?._id} 
              isCorectAnswer={props.currentOptionSelected?.checked} 
              isEffect={false} dataOption={data} currentOptionSelected={props.correctOption} option={alphabet[index]} />}
        </div>
      ))}
    </div>
  );
};
export default MCQResult;
