
import './FEQuizOptionStyle.scss';
const AnswerOption = (props: any) => { 
  return (
    <div className={`AnswerOption ${props.isEffect?'effects':''} ${props.isActive? 'active':''}`} onClick={props.validateAnswer}>
       {props.option &&<span className='ordernumber'>{props.option}</span>}
      <span className='answer'>{props.dataOption[props.attrbute]}</span>
    </div>
  )
}

export default AnswerOption;