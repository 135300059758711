import axios from "axios";
import { adminPath, refreshtoken } from "../constants/api";
import { tokensconst } from "../constants/constants";
import { encryptAES, getUserData } from "./common";
const authInterceptor = axios.create({});
authInterceptor.interceptors.request.use(async (config) => {
  const authToken = sessionStorage.getItem("token");

  config.headers.Authorization = `bearer ${authToken}`;
  config.headers["x-request-type"] = "impetus";
  return config;
});

authInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const authToken = sessionStorage.getItem("token");

      // window.location.href = "/login"
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("refID");
      //window.location.reload();
      window.location.href = "/";
      // const payload = {
      //   access_token: authToken,
      //   refresh_token: authToken,
      // };

      // let apiResponse = await axios.post(
      //   refreshtoken,
      //   payload
      // );
      // sessionStorage.setItem("token", apiResponse?.data);
      error.config.headers["Authorization"] = `bearer ${authToken}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default authInterceptor;
