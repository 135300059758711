import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
export const encryptAES = (value: string) => {
  return CryptoJS.AES.encrypt(value, 'Impetus').toString();
};

export const decryptAES = (value: string) => {
  return CryptoJS.AES.decrypt(value, 'Impetus');
}
export const jsonEncryptAES = (value: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(value), 'Impetus').toString() 
};

export const jsonDecryptAES = (value: any) => { 
  return JSON.parse(CryptoJS.AES.decrypt(value, 'Impetus').toString(CryptoJS.enc.Utf8));;
}
 
export const currencyFormat = (number: any) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'INR' }).format(number)
}
export const matchcompare = (a: any, b: any) => {
  if (a.match < b.match) {
    return -1;
  }
  if (a.match > b.match) {
    return 1;
  }
  return 0;
};

export const showClock = (target:any) => { 
    const distance = target - new Date().getTime();
    const mins = distance < 0 ? 0: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secs = distance < 0 ? 0: Math.floor((distance % (1000 * 60)) / 1000);  
    return {mins , secs}
}
 
export const arrayOfObjToArray = (array:any,name:string) =>{
  const newArry:any = [];
  (array||[])?.map((data:any) => {
    newArry.push(data[name]);
  });
  return newArry;
}

export const checkArrayCorrectAns = (selecetdArray:any,answerArray:any) =>{
  let count:any = 0;
  (answerArray||[])?.map((data:any,index:number) => {
      if(data === selecetdArray[index]){
        count++
      }
  });
  return count;
}
export const sixDigitRandom = ()=>{
  return Math.floor(100000 + Math.random() * 900000)
}
export const dateDiff = (date:any) =>{
  if(date){
    const currentDate = dayjs(new Date())
    const endDate = dayjs(date)
    const daysLeft = endDate.diff(currentDate, 'day')
    return daysLeft
  }
}

export const getUserData = () => {
  const token: any = sessionStorage.getItem("token");
  let userData: any;
  if(token){
    userData = jwtDecode(token);
  }
  return userData
}

export const shuffle = (array: any) => {
  return array.sort(() => Math.random() - 0.5);
};


export const shuffleArray=(array:any) =>{
  // Sort the array by type
  array.sort((a:any, b:any) => a.questiontype.localeCompare(b.questiontype));

  let result = [];
  let i = 0, j = array.length - 1;

  // Rearrange the items
  while (i <= j) {
      result.push(array[i++]);
      if (i <= j) result.push(array[j--]);
  }

  return result;
}
