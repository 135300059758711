import "./ExamNotifyModal.scss";
import dayjs from "dayjs";
import UnittoName from "../CodetoName/UnittoName";
import CodetoName from "../CodetoName/CodetoName";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
const ExamNotifyModal = (props: any) => {
  const isStarted = dayjs().isBetween(
    dayjs(props.data?.startTime),
    dayjs(props.data?.endTime)
  );
  return (
    <div className="exam-notify-wrapper">
      <div className="content">
        <div className="contnetItem">
          <span className="name">Test Type</span>
          <span className="value">{props.data?.type}</span>
        </div>
        <div className="contnetItem">
          <span className="name">Subject</span>
          <span className="value">
            <CodetoName
              url="subject/getSubjectName/"
              code={props.data?.subject}
            />
          </span>
        </div>
        <div className="contnetItem">
          <span className="name">Duration</span>
          <span className="value">
            <span>{props.data?.duration}</span>
            <span>Mins</span>
          </span>
        </div>
        {/* <div className="contnetItem" style={{flex:2}}>
          <span className="name">Total Unit</span>
          <span className="value">
          <UnittoName
              url="unit/FE/getUnitName/"
              code={props.data?.selecteUnitList}
            /> 
          </span> 
        </div> */}
        <div className="contnetItem">
          <span className="name">Date</span>
          <span className="value">
            {dayjs(props.data?.examDate).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="contnetItem" style={{flex:'1.8'}}>
          <span className="name">Exam Time</span>
          <span className="value">
            {`${dayjs(props.data?.startTime).format("hh:mm A")}`} - {dayjs(props.data?.endTime).format("hh:mm A")}
          </span>
        </div>
        {/* <div className="contnetItem">
          <span className="name">End Time</span>
          <span className="value">
            {dayjs(props.data?.endTime).format("hh:mm A")}
          </span>
        </div> */}
      </div>
      <hr />
      <div className="footer">
        <div className="footer-content">
          {/* <CountdownTimer targetDate={dateTimeAfterThreeDays} /> */}

          {/* <div className="times">
            <div className="info-wrapper">
              <span className="timeValue">19</span>
              <span className="timeText">Hrs</span>
            </div>
            <div className="info-wrapper">
              <span className="timeValue">56</span>
              <span className="timeText">Mins</span>
            </div>
            <div className="info-wrapper">
              <span className="timeValue">34</span>
              <span className="timeText">Secs</span>
            </div>
          </div> */}
          <div className="button-wrap">
            <div className="btnWrapper" style={{ minWidth: "300px" }}>
              {/* <button
                className="cancel-btn"
                onClick={() => {
                  props.handleExamNotifyClose();
                }}
              >
                Cancel
              </button> */}
              <button
                className="test-btn"
                onClick={() => props.takeNotifyTest(props.data)}
                disabled={!isStarted}
              >
                Take Test
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExamNotifyModal;
