import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import Courses from "../../components/Courses/Courses";
import ImageCard1 from "../../assets/learning-1.png";
import ImageCard2 from "../../assets/learning-2.png";
import ImageCard3 from "../../assets/learning-3.png";
import ImageCard4 from "../../assets/learning-4.png";
import TwoNurseImg from "../../assets/two-nurses.png";
import SingleNurseImg from "../../assets/single-nurse.png";
import Highlights from "../../assets/Highlights_Content-05.svg";
import { aboutPara, welcomePara } from "../../constants/constants";
import "./home.scss";
import ColorPicker from "./ColorPicker";
 

const Home = () => {
  useEffect(() => {
    localStorage.removeItem("isPageRefreshed");
  }, []);

  return (
    <div className="mainContent">
      <HomeBanner />
      <Courses />
      {/* <ColorPicker /> */}
      <section className="welcome-section">
        <div className="container">
          <div className="courseDesc">
            <div
              className="descleft col-xs-12 col-sm-12 col-md-6"
              data-aos="fade-top"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <h2 className="title">
                {`Welcome to Impetus `}
                <span className="highlight">{`Online Test`}</span>
                {` System!`}
              </h2>
              {/* {aboutPara.map((data) => (
                <p>{data}</p>
              ))} */}
              {welcomePara.map((data) => (
                <p className="welcomePara">{data}</p>
              ))}
            </div>
            <div
              className="descRight col-xs-12 col-sm-12 col-md-6"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <img src={TwoNurseImg} alt="Nurses" />
            </div>
          </div>
        </div>
      </section>
      <div className="highlight-section">
        <div className="container">
          <h2 className="title">
            {`Highlights of Impetus `}
            <span className="highlight">{`Online Test`}</span>
            {` System`}
          </h2>
          <div className="courseDesc">
            <div className="descleft col-xs-12 col-sm-12 col-md-6">
              <img
                src={SingleNurseImg}
                alt="Nurse"
                data-aos="zoom-in-down"
                data-aos-duration="1000"
              />
            </div>
            <div className="descRight col-xs-12 col-sm-12 col-md-6">
              <img src={Highlights} />
              {/* <div className="feature">
                <Card
                  className="feature-cards card-1"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img src={ImageCard1} />
                  <p>24 x 7 Learning</p>
                </Card>
                <Card
                  className="feature-cards card-2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <img src={ImageCard2} />
                  <p>Comprehensive Learning</p>
                </Card>
                <Card
                  className="feature-cards card-3"
                  data-aos="fade-top"
                  data-aos-duration="1000"
                >
                  <img src={ImageCard3} />
                  <p>Clinical Excellence</p>
                </Card>
                <Card
                  className="feature-cards card-4"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={ImageCard4} />
                  <p>Professional Growth</p>
                </Card>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
