import "./AboutUs.scss";
import AboutHeader from "../../components/AboutHeader/AboutHeader"; 
import abbg1 from "../../assets/aboutbg-1.svg"
import abbg2 from "../../assets/aboutbg.svg"
import BenefitsBanner from "../../components/BenefitsBanner/BenefitsBanner";
const AboutUs = () => {
  return (
    <div className="AboutUs">
      <AboutHeader text="About Us" para="Welcome to Impetus Healthcare Skills Online Test System!"/>
      <div className="container">
        <div className="row aboutus-section-one">
          <div className="col left-section"  data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="500">
            <h3>About US</h3>
            <p className="mb-5">
              The rapid development of information and communication
              technologies has changed the way we live, work and learn. The
              rapid development of information and communication technologies
              has meant that innovative methods of knowledge transfer in the
              education system and assessment are becoming increasingly
              important. Online tests are being used to assess nurses' and
              nursing students' knowledge and understanding of key nursing
              concepts. In the new technological economy, they have become a
              worldwide phenomenon, crossing oceans and reaching remote areas.
            </p>
            <p>
              An online test is an electronic test that tests knowledge and
              understanding of the subject in question. It has unique advantages
              as it overcomes the barriers of introversion and physical
              distance, creates new ways of communication and shapes identity.
              It enables consistent assessment of learning, reduces assessment
              time and can be administered in a variety of forms, e.g. as
              multiple-choice questions, fill-in-the-blank questions, questions
              with a ranking, true or false, or a combination of these patterns
            </p>
          </div>
          <div className="col"  data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="500">
            <img src={abbg1} />
          </div>
        </div>

        <div className="row aboutus-section-two">
          <div className="col" data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500">
            <img src={abbg2} />
          </div>
          <div className="col right-section"  data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"> 
            <p className="mb-5">
              Impetus Online Test Solutions offers the best online tests for
              nurses and nursing students. These tests are essential assessment
              tools to evaluate their knowledge, skills – based knowledge and
              competence in various aspects of nursing practice. These tests are
              an integral part of nursing curricula and professional
              development, helping to ensure that nurses and nursing students
              are well prepared to provide quality patient care and perform
              better on university exams. Online testing offers the advantage of
              accessibility, allowing nurses and nursing students to take the
              tests from the comfort of their own homes or premises. This
              flexibility is especially valuable for professionals preparing for
              state exams.
            </p>
            <p>
              Impetus' online testing platform provides essential assessment
              tools to evaluate the knowledge, skills and competencies of
              professional nurses and nursing students to assess their ability
              to perform in competitive and university examinations conducted by
              various agencies. Impetus offers online test series exclusively
              for nurses preparing for state recruitment exams and for nursing
              students preparing for university exams.
            </p>
            <ul>
              <li>
              a . Online Competitive Exams Test for Nurses: It provides an optimal assessment tool for nurses preparing for the nurse selection and recruitment examinations conducted by central and state recruitment agencies. It helps nurses to determine their level of knowledge, identify areas for improvement, enhance their knowledge and facilitate success in the examinations.
              </li>
              <li>
                b . Online test for nursing students: Designed to assess the
                cognitive level of students based on the nursing curriculum
                prescribed by Indian Nursing Council or respective state health
                universities to enhance their theoretical knowledge and skills,
                apply best clinical practices and perform well in the university
                examinations.
              </li>
            </ul>
          </div>
        </div>
        <BenefitsBanner />
      </div>
    </div>
  );
};

export default AboutUs;
