import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./SpecialityPackage.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import axios from "axios";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import MenuBookIcon from "../../assets/menu_book.png";
import CalenderIcon from "../../assets/calendar_month_gray.svg";
import CalenderWhiteIcon from "../../assets/calendar_month.svg";
import MenuBookWhiteIcon from "../../assets/menu_book_white.svg";
import BtnWrapper from "../../common/BtnWrapper";
import { Button, Card, ListGroup } from "react-bootstrap";
import BackBtn from "../../assets/backbtn.svg";
import GradeBox from "../../components/GradeBox/GradeBox";
import authInterceptor from "../../utils/authInterceptor";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import { nursedescription } from "../../constants/constants";
const ViewSpecialityPackage = () => {
  const [packageData, setPackageData] = useState<any>();
  const [specialityNameList, setSpecialityNameList] = useState<any>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { packageId, purchaseType, type, isPackagePurchased } = state || {};
  const userData = getUserData();
  const getPackageByID = async (id: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}specialityPackage/FE/${id}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data);

      let obj = {
        _id: decryptAESData._id,
        title: decryptAESData.packageName,
        listCount: decryptAESData.packageSpeciality.length,
        list: decryptAESData.packageSpeciality,
        image: decryptAESData.specialityImage,
        finalPrice: decryptAESData.packagePrice,
        discountPrice: decryptAESData.packageDiscountPrice,
        duration: decryptAESData.packageDurationDays,
        status: decryptAESData.status,
        date: decryptAESData.date,
      };
      getSpecialityName(decryptAESData?.packageSpeciality);
      setPackageData(obj);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getSpecByID = async (id: any) => {
    const url = userData
      ? `${adminPath}speciality/FE/${id}`
      : `${adminPath}speciality/FE/NoAuth/${id}`;

    try {
      const response: any = await authInterceptor.get(url);
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      let obj = {
        _id: decryptAESData._id,
        title: decryptAESData.specialityTitle,
        listCount: decryptAESData.moduleList.length,
        list: decryptAESData.moduleList,
        image: decryptAESData.specialityImage,
        finalPrice: decryptAESData.price,
        discountPrice: decryptAESData.discountPrice,
        duration: decryptAESData.durationDays,
        status: decryptAESData.status,
        date: decryptAESData.date,
      };
      getModuleName(decryptAESData?.moduleList);
      setPackageData(obj);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getSpecialityName = async (data: any) => {
    const url = userData
    ? `${adminPath}speciality/getSpecialityName`
    : `${adminPath}speciality/getSpecialityName/NoAuth`;
    const response: any = await authInterceptor.post(url,
      { data }
    );
    setSpecialityNameList(response?.data?.list);
  };
  const getModuleName = async (data: any) => {
    const url = userData
      ? `${adminPath}module/getModuleName`
      : `${adminPath}module/NoAuth/getModuleName`;
    const response: any = await authInterceptor.post(url, {
      data,
    });
    setSpecialityNameList(response?.data?.list);
  };

  const getPackagedata = async () => {
    if (type === "package") {
      await getPackageByID(packageId);
    } else {
      await getSpecByID(packageId);
    }
  };
  useEffect(() => {
    getPackagedata();
  }, [state]);

  return (
    <>
      <section className="specialitydetails-wrapper">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div className="container specialitydetails-box">
          <div>
            <img
              src={BackBtn}
              alt="back-btn"
              onClick={() => navigate(-1)}
              className="backbtn"
            />
          </div>

          <div
            className="specialitydetails-box-detail-conatiner"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="specialitydetails-box-header">
            <BreadcrumbBox
                link=""
                redirect={"Speciality"}
                currentPage="Package"
                isBack={true}
              />
              <h2 className="purchasedTitle">{packageData?.title}</h2>
              <p className="desc">{nursedescription}</p>
              {isPackagePurchased ? null : (
                <p className="price">
                  Price : <span className="rupee-symbol">&#x20B9;</span>
                  {packageData?.discountPrice && packageData?.discountPrice != 0
                    ? packageData?.discountPrice
                    : packageData?.price}
                </p>
              )}
              <ul className="specialitydetails-box-suboptions">
                <li>
                  <img src={MenuBookWhiteIcon} alt="menu-book" />
                  {`Total Number of ${
                    type === "package" ? "Speciality" : "Module"
                  } : ${specialityNameList.length}`}
                </li>
                <li>
                  <img src={CalenderWhiteIcon} alt="menu-book" />
                  {`Number of Days : ${packageData?.duration}`}
                </li>
              </ul>
              <div style={{ maxWidth: "300px" }}>
                {isPackagePurchased ? (
                  <Button
                    variant="primary"
                    style={{ visibility: "hidden" }}
                    className="buynow-button"
                    size="lg"
                  >
                    Take Test
                  </Button>
                ) : (
                  <BtnWrapper
                    purchaseType={purchaseType}
                    userType={"Nurse"}
                    paydata={packageData}
                  />
                )}
              </div>
            </div>
            <div
              className={`speciality-list-card ${
                isPackagePurchased ? "without-purchased" : ""
              }`}
            >
              <Card.Title>
                {type === "package" ? "Speciality" : "Module"} List
              </Card.Title>
              <ListGroup>
                {(specialityNameList || []).map(
                  (speciality: string, index: any) => (
                    <ListGroup.Item key={index}>
                      <img src="assets/check_circle.svg" />
                      <span>{speciality}</span>
                    </ListGroup.Item>
                  )
                )}
              </ListGroup>
            </div>
          </div>
          <GradeBox />
        </div>
      </section>
    </>
  );
};

export default ViewSpecialityPackage;
