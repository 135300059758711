
import { imagePath } from '../constants/api';
import './FEQuizOptionStyle.scss';
const ResultAnswerImage = (props: any) => {
  return ( 
    <div className={`AnswerImage ${props.isEffect?'effects':''} ${props.isActive? (props.isCorectAnswer? 'correctAnswer':'wrongAnswer'):(props.dataOption?.checked? 'correctAnswer':'')}`}  onClick={props.validateAnswer}>
      <span className='ordernumber'>{props.option}</span>
      <span className='answer'>
        <img src={props.dataOption?.imagePath} alt='image'/></span>
    </div>
  )
}

export default ResultAnswerImage;