import { useEffect, useState } from "react";
import CodetoName from "../CodetoName/CodetoName";
import "./ExamList.scss";
import dayjs from "dayjs";
import _ from "lodash";
import { adminPath } from "../../constants/api";
import authInterceptor from "../../utils/authInterceptor";
const SubjectExamList = (props: any) => {
  const [examTypeValue, setExamTypeValue] = useState("All");
  const [examType, setExamType] = useState([]);
  const [examData, setExamData] = useState([]);
  const [globalexamData, setGlobalExamData] = useState([]);
  const getExamType = async () => {
    try {
      const response = await authInterceptor.get(`${adminPath}common/TestType`);
      setExamType(response?.data?.list[0]?.testTypeArray);
    } catch (error: any) {}
  };
  useEffect(() => {
    getExamType();
  }, []);
  useEffect(() => {
    if (props.examData) {
      setExamData(_.cloneDeep(props.examData));
      setGlobalExamData(_.cloneDeep(props.examData));
    }
  }, [props.examData]);
  const checkExamData = (data: any) => {
    if (data?.examResult) {
      return (
        <>
          <li style={{ flex: 0.4 }}>
            {data?.examResult.score}/{data.totalQuestionMark}
          </li>
          <li style={{ flex: 0.4 }}>{data?.examResult.percentage}%</li>
          <li
            style={{ flex: 0.4 }}
            className={`${data?.examResult?.isPass ? "Pass" : "Fail"}`}
          >
            {`${data?.examResult?.gradeData?.grade}`}
          </li>

          {data?.examStatus === "Completed" ? (
            <li className="complete-icon" style={{ flex: 0.4 }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
            </li>
          ) : (
            <li style={{ flex: 0.4 }}>{data?.examStatus}</li>
          )}
          <li style={{ textAlign: "center", flex: 0.5 }}>
            {dayjs(data?.examResult?.completedDate).format("DD/MM/YYYY")}
          </li>
        </>
      );
    } else {
      return (
        <>
          <li style={{ flex: 0.4 }}>Yet to Start</li>
          <li style={{ flex: 0.4 }}>Yet to Start</li>
          <li style={{ flex: 0.4 }}>Yet to Start</li>
          <li style={{ flex: 0.4 }}>Yet to Start</li>
          <li
            style={{ textAlign: "center", flex: 0.5 }}
            onClick={() => props.getQuestionList(data)}
            className="textLink"
          >
            {"Take Test >>"}
          </li>
        </>
      );
    }
  };
  const handleTypeChange = (e: any) => {
    let filtered: any;
    setExamTypeValue(e.target.value);
    if (e.target.value === "All") {
      setExamData(_.cloneDeep(globalexamData));
    } else {
      filtered = globalexamData.filter((data: any) => {
        return data.type === e.target.value;
      });
      setExamData(filtered);
    }
  };

  return (
    <div className="container" data-aos="zoom-in" data-aos-duration="1000">
      <div className="examTable">
        <h4>Test Details</h4>
        {props.isFilterRequired && (
          <div className="subjectListFilter">
            <select
              className="form-select signup-select"
              name="course"
              onChange={handleTypeChange}
              value={examTypeValue}
            >
              <option>{"All"}</option>
              {examType.map((data: any) => (
                <option>{data.value}</option>
              ))}
            </select>
          </div>
        )}

        <ul className="header-list">
          {/* <li>Type of test</li> */}
          {props.isSubjectRequired && (
            <li style={{ textAlign: "left", flex: 0.5 }}>Subject</li>
          )}
          {/* <li>Unit No</li> */}
          {props.isTypeRequired && <li style={{ flex: 0.3 }}>Type of test</li>}
          <li style={{ flex: 0.3 }}>Test No</li>
          <li style={{ textAlign: "left" }}>Unit No / Title</li>
          <li style={{ flex: 0.4 }}>Score</li>
          <li style={{ flex: 0.4 }}>Percentage</li>
          <li style={{ flex: 0.4 }}>Grade</li>
          <li style={{ flex: 0.4 }}>Status</li>
          <li style={{ textAlign: "center", flex: 0.5 }}>DOC</li>
        </ul>
        <div>
          {(examData || []).map((data: any) => (
            <ul className="test_list">
              {/* <li>{data.type}</li> */}
              {props.isSubjectRequired && (
                <li style={{ textAlign: "left", flex: 0.5 }}>
                  <CodetoName
                    url="subject/getSubjectName/"
                    code={data.subject}
                  />
                </li>
              )}
              {props.isTypeRequired && (
                <li style={{ flex: 0.3 }}>{data.type}</li>
              )}
              {/* <li>
                <ul>
                  {data?.selecteUnitList?.map((innerData: string) => (
                    <li> {innerData} </li>
                  ))}
                </ul>
              </li> */}
              <li style={{ flex: 0.3 }}>{data?.testNumber}</li>
              <li>
                <ul
                  style={{
                    listStyle: "none",
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {data?.selecteUnitList?.map((innerData: string) => (
                    <li>
                      <CodetoName
                        url={`extra/FE/getUnittNameNum/`}
                        code={innerData}
                      />
                    </li>
                  ))}
                </ul>
              </li>
              {checkExamData(data)}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubjectExamList;
