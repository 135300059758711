
import { imagePath } from '../constants/api';
import './FEQuizOptionStyle.scss';
const AnswerImage = (props: any) => {
  return (
    <div className={`AnswerImage ${props.isEffect?'effects':''}  ${props.currentOptionSelected?._id === props.dataOption?._id? 'active':''}`}  onClick={props.validateAnswer}>
      <span className='ordernumber'>{props.option}</span>
      <span className='answer'>
        <img src={props.dataOption?.imagePath} alt='image'/></span>
    </div>
  )
}

export default AnswerImage;