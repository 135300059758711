import { useState } from "react";
import "./FeedBack.scss";
import { Button } from "react-bootstrap";
import { adminPath } from "../../constants/api";
import authInterceptor from "../../utils/authInterceptor";
import { getUserData } from "../../utils/common";
import Excellent from '../../assets/emojis/Execellant.png';
import Terrible from '../../assets/emojis/Terrible.png';
import Bad from '../../assets/emojis/Bad.png';
import Okay from '../../assets/emojis/Okay.png';
import Good from '../../assets/emojis/Good.png';
const FeedBack = (props: any) => {
  const [feedBack, setFeedBack] = useState<any>();
  const userData = getUserData();

  const FeedBackList = [
    {
      title: "Terrible",
      emoji: Terrible,
    },
    {
      title: "Bad",
      emoji: Bad,
    },
    {
      title: "Okay",
      emoji: Okay,
    },
    {
      title: "Good",
      emoji: Good,
    },
    {
      title: "Excellent",
      emoji: Excellent,
    },
  ];
  const submitFeedBack = async () => {
    let obj: any = {
      rating: feedBack,
      IHSID: userData?.user?.IHSID,
      type: userData?.user?.role,
      name: userData?.user?.name,
      score: props.score,
      exam:props.title,
      examtype: props.dataList.examtype,
      subName: props.dataList.subName,
      grade: `${props.gradeData?.grade} (${props.gradeData?.text}) `,
    };
    // if (userData?.user?.role === "NursingStudent") {
    //   obj["semester"] = userData?.user?.semester;
    //   obj["subject"] = userData?.user?.semester;
    // } else {
    //   obj["speciality"] = userData?.user?.speciality;
    //   obj["module"] = userData?.user?.module;
    // }
    try {
      const response = await authInterceptor.post(`${adminPath}feedBack`, obj);
      props.handleClose();
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  return (
    <div className="FeedBackWrapper">
      <h2 className="title">Give Feedback</h2>
      <p>How was your experience with our application</p>
      <div className="rateingWrapperBox">
        {FeedBackList.map((data, index) => (
          <span
            className={`rateingWrapper ${
              data.title === feedBack ? "active" : ""
            }`}
            onClick={() => setFeedBack(data.title)}
          >
            <img src={data.emoji} alt={data.title} />
        
            <label>{data.title}</label>
          </span>
        ))}
      </div>
      <div className="btn-section">
        <Button
          onClick={() => submitFeedBack()}
          className="imbtn-primary"
          size="lg"
          disabled={!feedBack}
        >
          Submit
        </Button>
        <Button
          onClick={() => props.handleClose()}
          className="imbtn-secondary"
          size="lg"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default FeedBack;
