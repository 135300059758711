import { useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import axios from "axios";
import { CodeToNameProps } from "../../interfaces/common";
import authInterceptor from "../../utils/authInterceptor";
const UnittoName = (props: CodeToNameProps) => {
  const [name, setName] = useState([]);
  useEffect(()=>{
    if(props.code){
        getName(props.code)
    }
  },[props.code])
  const getName = async (data: any) => { 
    const response = await authInterceptor.post(
      `${adminPath}${props.url}`,
      {data}
    );
    
    setName(response.data.list);
  };
 
  return <>{name.join(', ')}</>;
};

export default UnittoName;
