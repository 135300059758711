import { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode"; 
export const AuthContext = createContext<{
  user: any;
  login: any;
  logout: any;
  role:any;
}>({ role:"",user: "", login: () => null, logout: () => null });
export const AuthContextProvider = ({ children }: any) => {
  const [user, setUser] = useState(() => {
    const authToken = sessionStorage.getItem('token');
    if (authToken) { 
      return jwtDecode(authToken);
    }
    return null;
  });

  const [role, setRole] = useState<string | null>(); 
  const login = async (token: string,role:string) => {
    setUser(jwtDecode(token));
    setRole(role)
    //window.location.reload();

  };
  const logout = async () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('refID');
    setRole(null);
    setUser(null);  
  };
  return (
    <AuthContext.Provider value={{ role, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
