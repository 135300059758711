import { router } from "./routes";
import "./App.scss";
import { RouterProvider } from "react-router-dom";
import { AuthContextProvider } from "./contexts/AuthContext";
import { SnackbarContextProvider } from "./contexts/SnackbarContext";
import { LoginModalContextProvider } from "./contexts/LoginModalContext";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { CartModalContextProvider } from "./contexts/CartModalContext"; 
const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <ToastContainer />
      <div data-aos="fade" data-aos-duration="1000">
        <SnackbarContextProvider>
          <AuthContextProvider>
            <CartModalContextProvider>
              <LoginModalContextProvider>
                <RouterProvider router={router} />
              </LoginModalContextProvider>
            </CartModalContextProvider>
          </AuthContextProvider>
        </SnackbarContextProvider>
      </div>
    </>
  );
};

export default App;
