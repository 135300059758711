import "./GradeBox.scss";
import { grade } from "../../constants/constants";

const GradeBox = () => {
  return (
    <div
      className="grade-wrapper-box"
      data-aos="flip-up"
      data-aos-duration="1000"
    >
      <img src="/assets/grade.jpeg" />
      <div className="grade-wrapper-box-details">
        <h3>Grade Details</h3>
        <ul className="grade-header">
          <li className="grade">Letter Grade</li>
          {/* <li className="points">Points</li> */}
          <li className="percent">Percentage</li>
        </ul>
        {grade.map((data: any) => (
          <ul>
            <li className="grade">
              {data.grade} ({data.text})
            </li>
            {/* <li className="points">{data.points}</li> */}
            {data.grade === "O" ? (
              <li className="percent">{data.from}%</li>
            ) : (
              <li className="percent">
                {data.from}-{data.to}%
              </li>
            )}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default GradeBox;
