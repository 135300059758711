import MCQResult from "../../Quiz/MCQResult";
import MatchAnswerResult from "../../Quiz/MatchAnswerResult";
import MultiOptionResult from "../../Quiz/MultiOptionResult";
import SequenceAnswerResult from "../../Quiz/SequenceAnswerResult";
import TrueOrFalseResult from "../../Quiz/TrueOrFalseResult"; 
import { quizQuestionDisclimer } from "../../constants/constants";
import "./FinalScore.scss";
const FinalScore = (props: any) => {
  return (
    <>
      {props.quizResults?.map((data: any, index: number) => (
        <div className={`FinalScore quizAnswerSection quizReviewListSection`}>
          <div className="quizQuestionHeader">
            <h4 className="quizQuestion">
              {index + 1}
              {"."} {data.objData?.question}
            </h4>
            {data.objData?.isQuestionImageUploaded && (
              <img
                className="quizQuestionImage"
                src={data.objData?.questionImage}
                alt="quizQuestionImage"
              />
            )}
            <p className="quizQuestionType">
              {quizQuestionDisclimer[data?.questiontype]}
            </p>
          </div>

          <div>
            {(data.objData?.questiontype === "multiplechoice" ||
              data.objData?.questiontype === "image") && (
              <>
                <MCQResult
                  answerList={data.objData?.optionArray}
                  isImageOption={data.objData?.imageSubType === "Image"}
                  currentOptionSelected={data.selectedOption}
                  correctOption={data?.correctAnswer}
                />
                {/* { data.objData?.questiontype === "image" && data.objData?.imageSubType === "Image"?
                 <p className="correct-answer-result">Correct Answer : <img src={data?.correctAnswer?.imagePath}
                 alt="answerImage" /></p>: 
                 <p className="correct-answer-result">Correct Answer : {data?.correctAnswer?.value}</p>}
                */}
              </>
            )}

            {data.objData?.questiontype === "multipleOptions" && (
              <>
                <MultiOptionResult
                  answerList={data.objData?.optionArray}
                  currentOptionSelected={data.selectedOption}
                  correctOption={data?.correctAnswer}
                />
                {/* <p className="correct-answer-result">
                  Correct Answer :{" "}
                  {data?.correctAnswer
                    .filter((data: any) => data.checked)
                    .map((data: any) => data.value)
                    .join(" , ")}
                </p> */}
              </>
            )}

            {data.objData?.questiontype === "sequence" && (
              <>
                <SequenceAnswerResult
                  answerList={data.objData?.optionSqu}
                  currentOptionSelected={data.selectedOption}
                  correctOption={data?.correctAnswer}
                />
                {/* <p className="correct-answer-result">
                  Correct Answer : {data?.correctAnswer?.join(" , ")}
                </p> */}
              </>
            )}
            {data.objData?.questiontype === "match" && (
              <>
                <MatchAnswerResult
                  answerList={data.objData?.optionMatch}
                  currentOptionSelected={data.selectedOption}
                  correctOption={data?.correctAnswer}
                />
                {/* <p className="correct-answer-result">
                  Correct Answer : {data?.correctAnswer?.join(" , ")}
                </p> */}
              </>
            )}
            {data.objData?.questiontype === "trueorfalse" && (
              <>
                <TrueOrFalseResult
                  currentOptionSelected={data.selectedOption}
                  correctOption={data?.correctAnswer}
                />
                {/* <p className="correct-answer-result">Correct Answer : {data?.correctAnswer}</p> */}
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
export default FinalScore;
