import { Dispatch, useReducer, createContext } from "react";

export interface ICartModalState {
  triggerPopup: boolean; 
  type: string; 
}

export const initialItems: ICartModalState = {
    triggerPopup:false, 
    type: "",
};
// Actions
export enum ActionType {
  setMessage,
}

export interface setMessage {
  type: ActionType.setMessage;
  payload: ICartModalState;
}

export type SnackBarActions = setMessage;

//reducer
export const setMessage = (payload: ICartModalState): setMessage => ({
  type: ActionType.setMessage,
  payload: payload,
});

export const CartModalContext = createContext<{
  cartstate: ICartModalState;
  dispatch: Dispatch<SnackBarActions>;
  setCart: any;
}>({
  cartstate: initialItems,
  dispatch: () => null,
  setCart: () => null,
});

export const manageBadgeReducer = (
  cartstate: ICartModalState,
  action: SnackBarActions
): ICartModalState => {
  switch (action.type) {
    case ActionType.setMessage:
      const badgestate = {
        ...cartstate,
        triggerPopup: action.payload.triggerPopup, 
        type: action.payload.type,
      };
      return badgestate;
    default:
      return cartstate;
  }
};

export const CartModalContextProvider = (props: any) => {
  const [cartstate, dispatch] = useReducer(manageBadgeReducer, initialItems);
  const setCart = (payload: ICartModalState) => {
    dispatch(setMessage(payload));
  };
  return (
    <CartModalContext.Provider value={{ cartstate, dispatch, setCart }}>
      {props.children}
    </CartModalContext.Provider>
  );
};
