import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./SpecialityPackage.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import axios from "axios";
import {
  dateDiff,
  jsonDecryptAES,
  shuffle,
  shuffleArray,
} from "../../utils/common";
import MenuBookIcon from "../../assets/menu_book.png";
import CalenderIcon from "../../assets/calendar_month_gray.svg";
import CalenderWhiteIcon from "../../assets/calendar_month.svg";
import MenuBookWhiteIcon from "../../assets/menu_book_white.svg";
import BtnWrapper from "../../common/BtnWrapper";
import { Button, Card, ListGroup } from "react-bootstrap";
import BackBtn from "../../assets/backbtn.svg";
import ExamListAccordion from "../../components/ExamList/ExamListAccordion";
import GradeBox from "../../components/GradeBox/GradeBox";
import dayjs from "dayjs";
import authInterceptor from "../../utils/authInterceptor";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import { nursedescription } from "../../constants/constants";
import ExamList from "../../components/ExamList/ExamList";

const SpecialityPackage = () => {
  const [packageData, setPackageData] = useState<any>();
  const [examData, setExamData] = useState([]);
  const [examResults, setExamResults] = useState([]);
  const [quizQuestionList, setQuizQuestionList] = useState();
  const [moduleCodeList, setModuleCodeList] = useState();
  const [selectedExam, setSelectedExam] = useState<any>();
  const [PQData, setPQData] = useState<any>();
  const [type, setType] = useState<any>("Module");
  const [specialityNameList, setSpecialityNameList] = useState<any>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isPackagePurchased, purchasedDataID, endDate, code } = state || {};
  const getpurchasedDataIDData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setPackageData(decryptAESData[0].packageData);
      // if (decryptAESData[0].packageData?.isModule) {
      //   getModuleName(decryptAESData[0].packageData.list);
      // } else {
      //   getSpecialityName(decryptAESData[0].packageData.list);
      // }
      const filterTest: any = decryptAESData[0].examData
        .filter(
          (item: any) => item.type !== "Speciality" && item.type !== "Mock"
        )
        .map((item: any) => item.moduleCode);
      setModuleCodeList(filterTest);
      getModuleName(filterTest);
      setExamData(decryptAESData[0].examData);
      setPQData(decryptAESData[0]);
      setExamResults(decryptAESData[0].examResult);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  useEffect(() => {
    if (quizQuestionList) {
      try {
        let obj: any = {
          selectedExam: selectedExam?._id,
          pqdId: PQData._id,
          noOfQuestions: selectedExam?.noOfQuestions,
          duration: selectedExam?.duration,
          passPercent: selectedExam?.passPercent,
          totalMark:
            selectedExam?.type === "Module"
              ? selectedExam?.totalQuestionMark
              : selectedExam?.totalMark,
          quizQuestionList: quizQuestionList,
          type: type,
          examResult:
            type === "Module"
              ? selectedExam?.specData?.examResult
              : selectedExam.examResult,
          isSpecialityPackage: true,
          examtype: selectedExam.type,
          subName: packageData?.title,
        };
        if (type === "Module") {
          obj["moduleCode"] = selectedExam?.selectedModule;
        }
        authInterceptor
          .post(`${adminPath}tempdata`, {
            questionData: obj,
          })
          .then((response: any) => {
            navigate("/testPage", {
              state: { testId: response?.data?.id, title: packageData?.title },
            });
          });
      } catch (error: any) {
        if (error.response) {
        }
      }
    }
  }, [quizQuestionList]);

  const getSpecialityName = async (data: any) => {
    const response: any = await authInterceptor.post(
      `${adminPath}speciality/getSpecialityName`,
      { data }
    );
    setSpecialityNameList(response?.data?.list);
  };

  const getModuleName = async (data: any) => {
    const response: any = await authInterceptor.post(
      `${adminPath}module/getModuleName`,
      {
        data,
      }
    );
    setSpecialityNameList(response?.data?.list);
  };
  const getAlldata = async () => {
    // await getpurchasedDataIDData()
    await checkForNewExam();
  };

  useEffect(() => {
    getAlldata();
  }, [state]);

  const getExamData = async (code: any, moduleCodeList: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}specialityTest/FE/specialitygetAll`,
        {
          specialityCode: code,
          moduleCode: moduleCodeList,
        }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getpurchasedExamData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const updatePQD = async (data: any) => {
    try {
      await authInterceptor.patch(
        `${adminPath}PQD/FEUpdate/${purchasedDataID}`,
        data
      );
      getpurchasedDataIDData();
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const checkForNewExam = async () => {
    const purchasedItemsList = await getpurchasedExamData();
    const moduleCodeList: any = purchasedItemsList[0].examData
      .filter((item: any) => item.type !== "Speciality" && item.type !== "Mock")
      .map((item: any) => item.moduleCode);
    const examList = await getExamData(code, moduleCodeList);
    if (
      examList?.length > 0 &&
      examList?.length !== purchasedItemsList[0].examData?.length
    ) {
      let result = examList?.filter((o1: any) => {
        return !purchasedItemsList[0].examData?.some((o2: any) => {
          return o1?._id === o2?._id;
        });
      });
      if (result?.length > 0) {
        purchasedItemsList[0].examData?.push(...result);
      }
      console.log(purchasedItemsList[0]);
      updatePQD(purchasedItemsList[0]);
    } else {
      getpurchasedDataIDData();
    }
  };
  const getModuleQuestionListData = async (data: any) => {
    let finalData: any = [];
    try {
      const requests = data.levelList.map((level: any) => {
        let obj = {
          moduleCode: data.selectedModule,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Module/Quiz`, obj);
      });
      return axios.all(requests).then((responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp.data.list);
        });
        return finalData;
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getQuestionData = async (selectedType: any) => {
    if (selectedType.type === "Speciality" || selectedType.type === "Mock") {
      getQuestionList(selectedType);
    } else {
      getModuleQuestionList(selectedType);
    }
  };
  const getQuestionList = async (selectedType: any) => {
    let finalData: any = [];
    setType("Speciality");
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.moduleTestList.map((data: any) => {
        return getModuleQuestionListData(data);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getModuleQuestionList = async (selectedType: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          moduleCode: selectedType.moduleCode,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Module/Quiz`, obj);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp.data.list);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  // const getModuleQuestionList = async (selectedType: any, data: any) => {
  //   let finalData: any = [];
  //   setType("Module");
  //   selectedType["specData"] = data;
  //   setSelectedExam(selectedType);
  //   try {
  //     const requests = selectedType.levelList.map((level: any) => {
  //       let obj = {
  //         moduleCode: selectedType.selectedModule,
  //         LevelCode: level.code,
  //         qustionCount: level.qustionCount,
  //       };
  //       return authInterceptor.post(`${adminPath}quiz/FE/Module/Quiz`, obj);
  //     });
  //     axios.all(requests).then(async (responses) => {
  //       responses.forEach((resp: any) => {
  //         finalData.push(...resp.data.list);
  //       });
  //       finalData.forEach(async (item: any) => {
  //         if (item?.optionArray?.length > 0) {
  //           item.optionArray = await shuffle(item.optionArray);
  //         }
  //         if (item?.optionMatch?.length > 0) {
  //           item.optionMatch = await shuffle(item.optionMatch);
  //         }
  //         if (item?.optionSqu?.length > 0) {
  //           item.optionSqu = await shuffle(item.optionSqu);
  //         }
  //       });
  //       const shuffledFinal: any = await shuffleArray(finalData);
  //       setQuizQuestionList(shuffledFinal);
  //     });
  //   } catch (error: any) {
  //     if (error.response) {
  //     }
  //   }
  // };
  return (
    <>
      <section className="specialitydetails-wrapper">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div className="container specialitydetails-box">
          <div>
            <img
              src={BackBtn}
              alt="back-btn"
              onClick={() => navigate(-1)}
              className="backbtn"
            />
          </div>

          <div
            className="specialitydetails-box-detail-conatiner"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="specialitydetails-box-header">
              <BreadcrumbBox
                link=""
                redirect={"Speciality"}
                currentPage="Package"
                isBack={true}
              />
              <h2 className="purchasedTitle">{packageData?.title}</h2>
              <p className="desc">{packageData?.description}</p>

              <p className="price">
                Date Of Purchase :{" "}
                {dayjs(PQData?.purchasedDate).format("DD/MM/YYYY")}
              </p>

              <ul className="specialitydetails-box-suboptions">
                <li>
                  <img src={MenuBookWhiteIcon} alt="menu-book" />
                  {`Total Number of Specialitys : ${specialityNameList.length}`}
                </li>
                <li>
                  <img src={CalenderWhiteIcon} alt="menu-book" />
                  {`Number of Days Left :${dateDiff(endDate)}`}
                </li>
              </ul>
              <div style={{ maxWidth: "300px" }}>
                {isPackagePurchased ? (
                  <Button
                    variant="primary"
                    style={{ visibility: "hidden" }}
                    className="buynow-button"
                    size="lg"
                  >
                    Take Test
                  </Button>
                ) : (
                  <BtnWrapper paydata={"props.course"} />
                )}
              </div>
            </div>
            <div
              className={`speciality-list-card ${
                isPackagePurchased ? "without-purchased" : ""
              }`}
            >
              {/* {packageData?.isModule ? (
                <Card.Title>Module List</Card.Title>
              ) : (
                <Card.Title>Speciality List</Card.Title>
              )} */}
              <Card.Title>Module List</Card.Title>

              <ListGroup>
                {(specialityNameList || []).map(
                  (speciality: string, index: any) => (
                    <ListGroup.Item key={index}>
                      <img src="assets/check_circle.svg" />
                      <span>{speciality}</span>
                    </ListGroup.Item>
                  )
                )}
              </ListGroup>
            </div>
          </div>
          <GradeBox />
        </div>
      </section>
      <ExamList examData={examData} getQuestionList={getQuestionData} />

      {/* <ExamListAccordion
        examData={examData}
        getQuestionList={getQuestionList}
        getModuleQuestionList={getModuleQuestionList}
      /> */}
    </>
  );
};

export default SpecialityPackage;
