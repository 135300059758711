import Image from "react-bootstrap/Image";
import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./Module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import PackageCardSlider from "../../components/CarouselSlider/PackageCardSlider";
import PurchasedPackage from "../../components/CarouselSlider/PurchasedPackage";
import { jwtDecode } from "jwt-decode";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import authInterceptor from "../../utils/authInterceptor";
import { nursedescription } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
const Module = () => {
  const navigate = useNavigate();
  const [moduleList, setModuleList] = useState([]);
  const [pacakgeList, setPackageList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [myCourse, setMyCourse] = useState([]);
  const { state } = useLocation();
  const { id, name } = state || {};
  const { user } = useContext(AuthContext);
  const userData = getUserData();

  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}/Nurse`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData.map((data: any) => {
        return data.purchasedItems[0];
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getModuleListData = async (id: any, purchasedData: any) => {
    try {
      const userData = getUserData();
      let response;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}module/FE/${id}`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}module/FE/NoAuth/${id}`
        );
      }
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData
        .map((data: any) => {
          if (!purchasedData.includes(data._id)) {
            return {
              _id: data._id,
              title: data.moduleTitle,
              code: data.moduleCode,
              shortTitle: data.shortTitle,
              description: data.moduleDescription,
              image: data.moduleImage,
              finalPrice: data.price,
              discountPrice: data.discountPrice,
              duration: data.durationDays,
              status: data.status,
              date: data.date,
            };
          }
        })
        .filter((list: any) => list);
      setModuleList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getPacakgeListData = async (id: any, purchasedData: any) => {
    try {
      const userData = getUserData();
      let response;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}specialityPackage/FE/`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}specialityPackage/FE/NoAuth`
        );
      }
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData
        .map((data: any) => {
          if (!purchasedData.includes(data._id)) {
            return {
              _id: data._id,
              title: data.packageName,
              listCount: data.packageSpeciality?.length,
              list: data.packageSpeciality,
              image: data.moduleImage,
              finalPrice: data.packagePrice,
              discountPrice: data.packageDiscountPrice,
              duration: data.packageDurationDays,
              status: data.status,
              date: data.date,
              isPackage: true,
            };
          }
        })
        .filter((list: any) => list);
      setPackageList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getSpecialityListData = async (purchasedData: any) => {
    try {
      const userData = getUserData();
      let response: any;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}speciality/FE/`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}speciality/NoAuth/FE`
        );
      }

      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      console.log("speciality", decryptAESData);
      const list = decryptAESData
        .map((data: any) => {
          if (!purchasedData.includes(data._id)) {
            return {
              _id: data._id,
              title: data.specialityTitle,
              listCount: data.moduleList?.length,
              list: data.moduleList,
              image: data.specialityImage,
              finalPrice: data.price,
              discountPrice: data.discountPrice,
              duration: data.durationDays,
              status: data.status,
              specialityCode: data.specialityCode,
              shortTitle: data.shortTitle,
              isModule: true,
              date: data.date,
            };
          }
        })
        .filter((list: any) => list);
      setSpecialityList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getAlldata = async (id: any) => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      purchasedData = await getPurchasedData(userData);
    }
    await getModuleListData(id, purchasedData);
    await getPacakgeListData(id, purchasedData);
    await getSpecialityListData(purchasedData);
  };
  useEffect(() => {
    getAlldata(id);
  }, [id,user]);
  const cardBtn = (data: any) => {
    if (data.isPackage) {
      navigate("/SpecialityPackage", {
        state: {
          isPackagePurchased: true,
          packageId: data._id,
          purchasedDataID: data.purchasedDataID,
          endDate: data.endDate,
        },
      });
    } else {
      navigate("/PurchasedModule", {
        state: {
          purchasedDataID: data.purchasedDataID,
          endDate: data.endDate,
        },
      });
    }
  };
  const packageViewMore = (data: any, type: string, purchaseType: string) => {
    navigate("/ViewSpecialityPackage", {
      state: {
        isPackagePurchased: false,
        type,
        purchaseType,
        packageId: data._id,
        endDate: data.endDate,
      },
    });
  };

  return (
    <>
      <section className="speciality-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <BreadcrumbBox
            link="/nurse-speciality"
            redirect={name}
            currentPage="Modules"
          /> <div className="package-header">
          <h2 className="title">Module & Package Details</h2>
          {userData && userData.user.role === "Nurse" ? (
            <Link to={"/my-package"} className="nonPurchased">
             Click here for My Package <img src="assets/breadcrumb.svg" />
            </Link>
          ) : null}
        </div>


          
          <p className="desc">{nursedescription}
          </p>

          {/* {myCourse && myCourse.length > 0 ? (
            <div className="myCourse">
              <h3>My Packages</h3>
              <PurchasedPackage data={myCourse} cardBtn={cardBtn} />
            </div>
          ) : null}
          {myCourse.length === 0 && moduleList && moduleList.length > 0 ? (
            <div className="newCourse nocolor">
              <h3>New Modules</h3>
              <CarouselSlider
                data={moduleList}
                purchaseType={"Module"}
                userType={"Nurse"}
              />
            </div>
          ) : null} */}

          <div className="newCourse nocolor">
            <CarouselSlider
              data={moduleList}
              purchaseType={"Module"}
              userType={"Nurse"}
            />
          </div>
        </div>
      </section>
      {/* <div className="newCourse-details">
        {myCourse &&
        myCourse.length > 0 &&
        moduleList &&
        moduleList.length > 0 ? (
          <div className="newCourse">
            <h3>New Modules</h3>
            <CarouselSlider
              data={moduleList}
              purchaseType={"Module"}
              userType={"Nurse"}
            />
          </div>
        ) : null}
      </div> */}
      {/* {pacakgeList && pacakgeList.length > 0 ? (
        <section
          className="package-details"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <div className="container package-details-box">
            <h2 className="title mt-5">{"Package Details"}</h2>
            <div className="row justify-content-center">
              <PackageCardSlider
                data={pacakgeList}
                isSpeciality={true}
                viewMore={(data: any) =>
                  packageViewMore(data, "package", "SpecPackage")
                }
                userType={"Nurse"}
                purchaseType={"SpecPackage"}
              />
            </div>
          </div>
        </section>
      ) : null} */}

      {/* {specialityList && specialityList.length > 0 ? (
        <section
          className="package-details specPackage"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <div className="container package-details-box">
            <h2 className="title mt-5">{"Speciality Details"}</h2>
            <div className="row justify-content-center">
              <PackageCardSlider
                data={specialityList}
                isSpeciality={true}
                viewMore={(data: any) =>
                  packageViewMore(data, "speciality", "SpecPackage")
                }
                userType={"Nurse"}
                purchaseType={"Speciality"}
              />
            </div>
          </div>
        </section>
      ) : null} */}
    </>
  );
};

export default Module;
