import Image from "react-bootstrap/Image";
import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./Module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import { jwtDecode } from "jwt-decode";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import authInterceptor from "../../utils/authInterceptor";
import { nursedescription } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
import CodetoName from "../../components/CodetoName/CodetoName";
const AllModule = () => {
  const navigate = useNavigate();
  const [moduleList, setModuleList] = useState<any>([]);
  const [myCourse, setMyCourse] = useState([]);
  const { state } = useLocation();
  const { id, name } = state || {};
  const { user } = useContext(AuthContext);
  const userData = getUserData();
  const [isLogedIN, setIsLogedIN] = useState<any>(false);
  useEffect(() => {
    const userData = getUserData();
    if (userData && userData?.user?.role === "Nurse") {
      setIsLogedIN(true);
    } else {
      setIsLogedIN(false);
    }
  }, [user]);

  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}/Nurse`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData.map((data: any) => {
        return data.purchasedItems[0];
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getModuleListData = async (id: any, purchasedData: any) => {
    try {
      const response = await authInterceptor.get(
        `${adminPath}module/FE/module/all`
      );
      const result = response?.data?.list?.reduce((result: any, item: any) => {
        (result[item.speciality] = result[item.speciality] || []).push(item);
        return result;
      }, {});
      let moduleList = [];
      for (const key in result) {
        result[key] = result[key]
          .map((data: any) => {
            if (!purchasedData.includes(data._id)) {
              return {
                _id: data._id,
                title: data.moduleTitle,
                code: data.moduleCode,
                shortTitle: data.shortTitle,
                description: data.moduleDescription,
                image: data.moduleImage,
                finalPrice: data.price,
                discountPrice: data.discountPrice,
                duration: data.durationDays,
                status: data.status,
                date: data.date,
              };
            }
          })
          .filter((list: any) => list);
        moduleList.push({ name: key, list: result[key] });
      }
      setModuleList(moduleList);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getAlldata = async (id: any) => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      purchasedData = await getPurchasedData(userData);
    }
    await getModuleListData(id, purchasedData);
  };
  useEffect(() => {
    getAlldata(id);
  }, [id, user]);

  const redirectUpdate = (link: string) => {
    navigate(link);
  };
  return (
    <>
      <div className="header-submenu">
        <div className="container">
          <a onClick={() => redirectUpdate("/nurse")}>{"About Us"}</a>
          <a onClick={() => redirectUpdate("/nurse-speciality")}>
            {"Speciality"}
          </a>
          <a onClick={() => redirectUpdate("/allmodule")}>{"Modules"}</a>
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/my-package")}>{"My Package"}</a>
          )}
        </div>
      </div>
      <section className="speciality-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="package-header">
            <h2 className="title">Module & Package Details</h2>
            {userData && userData.user.role === "Nurse" ? (
            <Link to={"/my-package"} className="nonPurchased">
             Click here for My Package  <img src="assets/breadcrumb.svg" />
            </Link>
          ) : null}
          </div>
          <p className="desc specdesc">{nursedescription}</p>
          {moduleList.map((data: any,index:number) => (
            <>
              {data.list.length > 0 && (
                <div className="Spec-wrapper newCourse nocolor">
                  <h2 className={`Spec-name ${index === 0? 'whitecolor':''}`}> 
                    <CodetoName
                      url="speciality/getSpecialityName/"
                      code={data.name}
                    />
                  </h2>
                  <CarouselSlider
                    data={data.list}
                    purchaseType={"Module"}
                    userType={"Nurse"}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      </section>
    </>
  );
};

export default AllModule;
