import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { adminPath } from "../../constants/api";
import authInterceptor from "../../utils/authInterceptor";
const SuccessData = () => {
  return <div className="OnlineTest">Loading</div>;
};

export default SuccessData;
