import "./NurseAboutUs.scss";
import AboutHeader from "../../components/AboutHeader/AboutHeader";
import TwoNurseImg from "../../assets/two-nurses.png";
import abbg1 from "../../assets/aboutbg-1.svg";
import abbg2 from "../../assets/aboutbg.svg";
import Objectives from "../../assets/clientimages/Nurses.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import { getUserData } from "../../utils/common";
import { LoginModalContext } from "../../contexts/LoginModalContext";

const NurseAboutUs = () => {
  const { setToster } = useContext(LoginModalContext);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isLogedIN, setIsLogedIN] = useState<any>(false);

  useEffect(() => {
    const userData = getUserData();
    if (userData && userData?.user?.role === "Nurse") {
      setIsLogedIN(true);
    } else {
      setIsLogedIN(false);
    }
  }, [user]);
  const redirectUpdate = (link: string) => {
    navigate(link);
  };
  const demoTestChange = () => {
    if (isLogedIN) {
      navigate("/demoTest");
    } else {
      setToster({ triggerPopup: true });
    }
  };
  return (
    <div className="NurseAboutUs">
      <div className="header-submenu">
        <div className="container">
          <a onClick={() => redirectUpdate("/nurse")}>{"About Us"}</a>
          <a onClick={() => redirectUpdate("/nurse-speciality")}>
            {"Speciality"}
          </a>
          <a onClick={() => redirectUpdate("/allmodule")}>{"Modules"}</a>
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/my-package")}>{"My Package"}</a>
          )}
          {/* {!isLogedIN && (
            <a onClick={() => redirectUpdate("/demoTest")}>{"Demo Test"}</a>
          )} */}
        </div>
      </div>
      <AboutHeader
        text="Nurses"
        para="Unlock your Potential with our Online testing Platform"
      />
      <div className="container">
        <div className="row NurseAboutUs-section-one">
          <div
            className="col left-section"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h3>Professional Knowledge Assessment</h3>
            <p className="mb-5">
              Human resources in healthcare are the most important component of
              the healthcare system. The healthcare system faces many
              challenges. The biggest challenge is the shortage of health
              workers, especially nurses, in both rural and urban areas. The
              desired health outcomes cannot be achieved if there are not enough
              efficient health workers. This shortage leads to a decline in the
              quantity and quality of healthcare services, higher healthcare
              costs and a decline in care in remote areas and costs that are
              only accessible to those who can pay or afford them. The issue of
              a shortage of healthcare professionals has recently gained
              prominence and the government has focused on skilled healthcare
              workers to ensure quality of care
            </p>
            <p>
              In the current Indian scenario, career opportunities in nursing
              are very high to meet the increasing demand for skilled healthcare
              manpower at various levels of the healthcare system. Qualified
              nurses have a variety of opportunities in the country to advance
              in their career. Nurses have the opportunity to work in government
              hospitals, private hospitals, armed forces, public health, nursing
              homes, industries, psychiatric care, hospices, etc. In most
              government institutions, nurses are recruited by the respective
              recruiting authorities through competitive examinations. STATE
              GOVERNMENT NURSING RECRUITMENT, AIIMS, PGIMER, JIPMER, DSSSB,
              RAILWAYs and ESIC recruit nurses for various posts at regular
              intervals to meet the demand through competitive examinations. For
              nurses preparing for selection exams, learning in online mode is
              most effective as it saves time, is cost effective and can be
              completed anywhere, anytime in a comfortable environment.
            </p>
          </div>
          <div
            className="col"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <img src={abbg1} />
          </div>
        </div>

        <div className="row NurseAboutUs-section-two">
          <div
            className="col"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <img src={TwoNurseImg} />
          </div>
          <div
            className="col right-section"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <p className="mb-5">
              Online tests are standardized tests designed to assess the
              knowledge, skills and aptitude of individuals, often with the goal
              of selecting the most qualified candidates for a particular
              position, academic program or opportunity. These exams are usually
              very demanding, and success often requires careful preparation.
              The exams serve various purposes, such as selection for a
              position, admission to educational institutions, awarding
              scholarships and certification in certain professional fields. The
              main objective is to identify the most suitable candidates based
              on their performance. Examinations play a crucial role in shaping
              careers and educational opportunities for millions of people.
              Preparing for these exams requires dedication, discipline and a
              thorough understanding of the exam format and content. Success can
              be life-changing and lead to rewarding careers and personal
              growth.
            </p>
            <p>
              Impetus online test provides an optimal assessment tool for nurses
              preparing for nurse selection and recruitment exams administered
              by central and state government recruitment boards. It helps
              nurses determine their level of knowledge, identify areas for
              improvement, increase their knowledge, and facilitate success on
              the exams Repetitive online exams will mould the nurses to appear
              for the competitive exams with confidence and be a key to success
              in the exams.
            </p>
            <p>
              The Impetus online test is an optimal assessment tool for nurses
              preparing for the nurse selection and recruitment exams conducted
              by central and state recruitment agencies. It helps nurses
              determine their level of knowledge, identify areas for
              improvement, enhance their knowledge and facilitate success in the
              exams. Repeated online tests help nurses to approach the exams
              with confidence and are the key to success.
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row NurseAboutUs-section-three">
            <div
              className="col-8 right-section"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img src={Objectives} />
            </div>
            <div
              className="col-4 "
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img src={abbg2} className="NurseAboutUs-right-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NurseAboutUs;
