import "./BreadcrumbBox.scss";
import { Link, useNavigate } from "react-router-dom";

const BreadcrumbBox = (props: any) => {
  const navigate = useNavigate();

  const redirect = () => {
    if (props.isBack) {
      navigate(-1);
    } else {
      navigate(props.link);
    }
  };
  return (
    <ul className="BreadcrumbBox">
      <li>
        <a onClick={redirect}>{props.redirect}</a>
      </li>
      <li>
        <img src="assets/breadcrumb.svg" />
      </li>
      <li>{props.currentPage}</li>
    </ul>
  );
};

export default BreadcrumbBox;
