import "./BenefitsBanner.scss";

const BenefitsBanner = () => {
  return (
    <div className="container benefits-arrange">
      <div className="BenefitsBanner-bg">
        <div className="container">
          <h3>Benefits of Online Test System</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Assessment of the nurse's or student nurse's knowledge and
                understanding of key nursing concepts
              </div>
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Monitoring of ongoing professional development and academic
                performance
              </div>
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Practice tests and assessments to prepare nursing students for
                the real exam
              </div>
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Ease of use and accessibility
              </div>
            </div>
            <div className="col">
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                High flexibility
              </div>
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Test results are accurate and immediately available
              </div>
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Immediate feedback on your performance
              </div>
              <div className="col paralist">
                <img src="/assets/check_circle.svg" />
                Cost effective
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BenefitsBanner;
