import { Dispatch, useReducer, createContext } from "react";

export interface ILoginModalState {
  triggerPopup: boolean; 
}

export const initialItems: ILoginModalState = {
    triggerPopup:false, 
};
// Actions
export enum ActionType {
  setMessage,
}

export interface setMessage {
  type: ActionType.setMessage;
  payload: ILoginModalState;
}

export type SnackBarActions = setMessage;

//reducer
export const setMessage = (payload: ILoginModalState): setMessage => ({
  type: ActionType.setMessage,
  payload: payload,
});

export const LoginModalContext = createContext<{
  state: ILoginModalState;
  dispatch: Dispatch<SnackBarActions>;
  setToster: any;
}>({
  state: initialItems,
  dispatch: () => null,
  setToster: () => null,
});

export const manageBadgeReducer = (
  state: ILoginModalState,
  action: SnackBarActions
): ILoginModalState => {
  switch (action.type) {
    case ActionType.setMessage:
      const badgestate = {
        ...state,
        triggerPopup: action.payload.triggerPopup, 
      };
      return badgestate;
    default:
      return state;
  }
};

export const LoginModalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(manageBadgeReducer, initialItems);
  const setToster = (payload: ILoginModalState) => {
    dispatch(setMessage(payload));
  };
  return (
    <LoginModalContext.Provider value={{ state, dispatch, setToster }}>
      {props.children}
    </LoginModalContext.Provider>
  );
};
