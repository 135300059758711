import { useEffect, useState } from "react";
import "./FEQuizOptionStyle.scss";
const TrueOrFalseResult = (props: any) => {
  const [isRefreshed, setIsRefreshed] = useState(false);
  useEffect(() => {
    setIsRefreshed(true);
  }, [props.indexValue]); 
  return (
    <>
      {isRefreshed && (
        <div className="TrueOrFalseOption">
          <div
            className={`AnswerOption ${
              props.currentOptionSelected === "True" ? (props.correctOption === "True" ?'correctAnswer':'wrongAnswer'):(props.correctOption === "True" ?"correctAnswer" : "")
            }`}
            style={{ marginRight: "30px" }}
        
          >
            <span className="ordernumber">A</span>
            <span className="answer">True</span>
          </div>
          <div
            className={`AnswerOption ${
              props.currentOptionSelected === "False" ? (props.correctOption === "False" ?'correctAnswer':'wrongAnswer'):(props.correctOption === "False" ?"correctAnswer" : "")
            }`}
          
          >
            <span className="ordernumber">B</span>
            <span className="answer">False</span>
          </div>
        </div>
      )}
    </>
  );
};
export default TrueOrFalseResult;
