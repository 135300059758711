import { useContext, useEffect, useState } from "react";
import BuyNowBtn from "./BuyNowBtn/BuyNowBtn";
import RegisterNowBtn from "./RegisterNowBtn/RegisterNowBtn";
import AuthContext from "../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";

const BtnWrapper = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [userType, setUserType] = useState();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const token: any = sessionStorage.getItem("token");
    if (token) {
      let userData: any = jwtDecode(token);
        setUserType(userData?.user?.role);
    }
    setIsLoggedIn(user ? user : sessionStorage.getItem("token"));
  }, [user]);
  return (
    <> 
      {isLoggedIn && userType=== props.userType? <BuyNowBtn purchaseType={props.purchaseType} userType={props.userType} paydata={props.paydata} /> : <RegisterNowBtn  isSubjectBtn={props.purchaseType === "Subject"}/>}
    </>
  );
};

export default BtnWrapper;
