import React, { useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import axios from "axios";
import {
  requiredValidation,
  isValidNumber,
  requiredPhoneValidation,
  isValidEmail,
  requiredEmailValidation,
} from "../../utils/formutils";
import { singUpType, courseList } from "../../constants/constants";
import "./FESignUp.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tab, Tabs } from "react-bootstrap"; 
import { toast } from "react-toastify";
import { jsonDecryptAES } from "../../utils/common";
import EyeIcon from "../../assets/visibility.svg";
import { stateList } from "../../constants/constants";
const FESignUp = (props: any) => {
  let selectedOption = singUpType[0].value;
  const [showPassword, setshowPassword] = useState(false);
  const [globalError, setglobalError] = useState({
    isError: false,
    errorMsg: "",
  });

  const [collegeList, setCollegeList] = useState([]);
  const [minDate, setMinDate] = useState("");
  const [semesterList, setSemesterList] = useState<any>();

  const [signUpData, setSignUpData] = useState<any>({
    type: { value: "NursingStudent", errormsg: "", showError: false },
    name: { value: "", errormsg: "", showError: false },
    dateofbirth: { value: "", errormsg: "", showError: false },
    email: { value: "", errormsg: "", showError: false },
    mobile: { value: "", errormsg: "", showError: false },
    password: { value: "", errormsg: "", showError: false },
    state: { value: "", errormsg: "", showError: false },
    course: { value: courseList[0], errormsg: "", showError: false },
    college: { value: [], errormsg: "", showError: false },
    semester: { value: "", errormsg: "", showError: false },
    qualification: { value: courseList[0], errormsg: "", showError: false },
    rn: { value: "", errormsg: "", showError: false },
  });
  useEffect(() => {
    handleTabChange(selectedOption);
    getCollegeData();
    calculateDate();
    getSemData();
  }, []);

  const getCollegeData = async () => {
    try {
      const response: any = await axios.get(`${adminPath}collegeAdmin`);
      setCollegeList(response?.data?.list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getSemData = async () => {
    try {
      const response = await axios.get(`${adminPath}semester/FE/NoAuthSem`);
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const selectBoxList = (decryptAESData || []).map((item: any) => ({
        title: `${item.semName}`,
        value: item.semCode,
      }));
      setSignUpData({
        ...signUpData,
        ["semester"]: {
          value: selectBoxList[0]?.value,
          errormsg: "",
          showError: false,
        },
      });
      setSemesterList(selectBoxList);
    } catch (error: any) {}
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (handleValidation()) {
      const d = new Date();
      let year = d.getFullYear();
      const IHSID =
        year.toString() + Math.floor(100000 + Math.random() * 900000);
      let payload: any = {
        IHSID: IHSID,
        type: signUpData.type.value,
        name: signUpData.name.value,
        dateofbirth: signUpData.dateofbirth.value,
        email: signUpData.email?.value?.toLowerCase(),
        mobile: signUpData.mobile.value,
        state: signUpData.state.value,
        password: signUpData.password.value,
        dor: new Date(),
      };
      if (signUpData.type.value === "NursingStudent") {
        payload["course"] = signUpData.course.value;
        payload["college"] =
          signUpData.college.value.length > 0
            ? signUpData.college.value[0].collegeName
            : "";
        payload["semester"] = signUpData.semester.value;
      } else {
        payload["qualification"] = signUpData.qualification.value;
        payload["rn"] = signUpData.rn.value;
      }
      try {
        let apiResponse;
        if (signUpData.type.value === "NursingStudent") {
          apiResponse = await axios.post(`${adminPath}FElanding/ns`, payload);
        } else {
          apiResponse = await axios.post(
            `${adminPath}FElanding/nurse`,
            payload
          );
        }
        try{
          axios.post(`${adminPath}common/SMS/userDetails`, {
            email: signUpData.email?.value?.toLowerCase(),
            mobile: Number(signUpData.mobile.value),
            password: signUpData.password.value,
            IHSID: IHSID,
          });
        }catch (error: any) {
         console.log(error)
        }
     
        toast.success("Registration Successful", {
          position: "top-center",
        });
        props.redirectToSignUp();
      } catch (error: any) {
        const { data } = error.response;
        setglobalError({ isError: true, errorMsg: data.msg });
      }
    }
  };

  const handleChange = (e: any) => {
    selectedOption = e;
    console.log("event", e);
    setSignUpData({
      ...signUpData,
      [e.target.name]: {
        value: e.target.value,
        errormsg: "",
        showError: false,
      },
    });
  };

  const handleTabChange = (e: any) => {
    selectedOption = e;
    setSignUpData({
      ...signUpData,
      ["type"]: {
        value: e,
        errormsg: "",
        showError: false,
      },
    });
  };

  const calculateDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 16);
    const minDateFormatted = today.toISOString().split("T")[0];
    setMinDate(minDateFormatted);
  };
  const handleDateChange = (value: any) => {
    setSignUpData({
      ...signUpData,
      ["dateofbirth"]: {
        value: value,
        errormsg: "",
        showError: false,
      },
    });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let obj: any = {};
    if (!signUpData.name.value) {
      formIsValid = false;
      obj.name = requiredValidation(signUpData.name.value);
    }
    if (!signUpData.dateofbirth.value) {
      formIsValid = false;
      obj.dateofbirth = requiredValidation(signUpData.dateofbirth.value);
    }
    if (!signUpData.mobile.value) {
      formIsValid = false;
      obj.mobile = requiredValidation(signUpData.mobile.value);
    } else if (isValidNumber(signUpData.mobile.value)) {
      formIsValid = false;
      obj.mobile = requiredPhoneValidation(signUpData.mobile.value);
    }
    if (!signUpData.email.value) {
      formIsValid = false;
      obj.email = requiredValidation(signUpData.email.value);
    } else if (isValidEmail(signUpData.email.value)) {
      formIsValid = false;
      obj.email = requiredEmailValidation(signUpData.email.value);
    }
    if (!signUpData.state.value) {
      formIsValid = false;
      obj.state = requiredValidation(signUpData.state.value);
    }
    if (!signUpData.password.value) {
      formIsValid = false;
      obj.password = requiredValidation(signUpData.password.value);
    } else if (signUpData?.password?.value?.length < 6) {
      formIsValid = false;
      obj.password = {
        value: signUpData?.password?.value,
        errormsg: "Password should minimum 6 characters",
        showError: true,
      };
    }
    if (
      !signUpData.college.value &&
      signUpData.type.value === "NursingStudent"
    ) {
      formIsValid = false;
      obj.college = requiredValidation(signUpData.college.value);
    }
    if (
      !signUpData.course.value &&
      signUpData.type.value === "NursingStudent"
    ) {
      formIsValid = false;
      obj.course = requiredValidation(signUpData.course.value);
    }
    if (
      !signUpData.semester.value &&
      signUpData.type.value === "NursingStudent"
    ) {
      formIsValid = false;
      obj.semester = requiredValidation(signUpData.semester.value);
    }
    if (!signUpData.qualification.value && signUpData.type.value === "Nurse") {
      formIsValid = false;
      obj.qualification = requiredValidation(signUpData.qualification.value);
    }
    if (!signUpData.rn.value && signUpData.type.value === "Nurse") {
      formIsValid = false;
      obj.rn = requiredValidation(signUpData.rn.value);
    }
    const newData = { ...signUpData, ...obj };
    setSignUpData(newData);
    return formIsValid;
  };
  const handleCollageChange = (value: any) => {
    setSignUpData({
      ...signUpData,
      ["college"]: {
        value: value,
        errormsg: "",
        showError: false,
      },
    });
  };
  return (
    <div className="signup-wrapper">
      <div className="row">
        <Tabs className="im-tabs" onSelect={handleTabChange}>
          {(singUpType || []).map((data: any) => (
            <Tab
              eventKey={data.value}
              title={data.title}
              active={data.value == selectedOption}
            >
              <div className="formWrapper">
                <div className="col-6 signup-input-box">
                  <label className="form-label signup-label">Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={signUpData.name.value}
                    onChange={handleChange}
                    className="form-control signup-input"
                  />
                  {signUpData.name.showError && (
                    <span className="signup-input-error">
                      {signUpData.name.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 signup-input-box">
                  <label className="form-label signup-label">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    placeholder="Enter your Date of Birth"
                    name="dateofbirth"
                    value={signUpData.dateofbirth.value}
                    onChange={handleChange}
                    className="form-control signup-input"
                    max={minDate}
                  />
                  {signUpData.dateofbirth.showError && (
                    <span className="signup-input-error">
                      {signUpData.dateofbirth.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 signup-input-box">
                  <label className="form-label signup-label">Email</label>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    value={signUpData.email.value}
                    onChange={handleChange}
                    className="form-control signup-input"
                  />
                  {signUpData.email.showError && (
                    <span className="signup-input-error">
                      {signUpData.email.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 signup-input-box">
                  <label className="form-label signup-label">Mobile</label>
                  <input
                    type="text"
                    placeholder="Enter your mobile"
                    name="mobile"
                    value={signUpData.mobile.value}
                    onChange={handleChange}
                    className="form-control signup-input"
                  />
                  {signUpData.mobile.showError && (
                    <span className="signup-input-error">
                      {signUpData.mobile.errormsg}
                    </span>
                  )}
                </div>
                <div
                  className="col-6 signup-input-box"
                  style={{ position: "relative" }}
                >
                  <label className="form-label signup-label">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    value={signUpData.password.value}
                    onChange={handleChange}
                    className="form-control signup-input"
                  />
                  <button
                    className="password-eye-btn signup-eye-btn"
                    onClick={() => setshowPassword(!showPassword)}
                  >
                    <img src={EyeIcon} alt="back-btn" />
                  </button>
                  {signUpData.password.showError && (
                    <span className="signup-input-error">
                      {signUpData.password.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 signup-input-box">
                  <label className="form-label signup-label">State</label>
                  <select
                    className="form-select signup-select"
                    name="state"
                    onChange={handleChange}
                  >
                    {(stateList || []).map((data: any) => (
                      <option
                        selected={data.name === signUpData.state.value}
                        value={data.name}
                      >
                        {data.name}
                      </option>
                    ))}
                  </select>
                  {signUpData.state.showError && (
                    <span className="signup-input-error">
                      {signUpData.state.errormsg}
                    </span>
                  )}
                </div>

                {signUpData.type.value === "NursingStudent" && (
                  <>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">Course</label>
                      <select
                        className="form-select signup-select"
                        name="course"
                        onChange={handleChange}
                      >
                        {(courseList || []).map((data: any) => (
                          <option
                            selected={data === signUpData.course.value}
                            value={data}
                          >
                            {data}
                          </option>
                        ))}
                      </select>
                      {signUpData.course.showError && (
                        <span className="signup-input-error">
                          {signUpData.course.errormsg}
                        </span>
                      )}
                    </div>

                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">
                        Semester
                      </label>
                      <select
                        className="form-select signup-select"
                        name="semester"
                        onChange={handleChange}
                      >
                        {(semesterList || []).map((data: any) => (
                          <option
                            selected={data.value === signUpData.semester.value}
                            value={data.value}
                          >
                            {data.title}
                          </option>
                        ))}
                      </select>
                      {signUpData.semester.showError && (
                        <span className="signup-input-error">
                          {signUpData.semester.errormsg}
                        </span>
                      )}
                    </div>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">College</label>
                      <Typeahead
                        labelKey="collegeName"
                        className="CollegeTypeHead"
                        id="basic-typeahead-single"
                        onChange={handleCollageChange}
                        options={collegeList}
                        placeholder="Choose a College..."
                        selected={signUpData.college.value}
                      />
                      {signUpData.college.showError && (
                        <span className="signup-input-error">
                          {signUpData.college.errormsg}
                        </span>
                      )}
                    </div>
                  </>
                )}
                {signUpData.type.value === "Nurse" && (
                  <>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">
                        Qualification
                      </label>
                      <select
                        className="form-select signup-select"
                        name="qualification"
                        onChange={handleChange}
                      >
                        {(courseList || []).map((data: any) => (
                          <option
                            selected={data === signUpData.qualification.value}
                            value={data}
                          >
                            {data}
                          </option>
                        ))}
                      </select>
                      {signUpData.qualification.showError && (
                        <span className="signup-input-error">
                          {signUpData.qualification.errormsg}
                        </span>
                      )}
                    </div>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">
                        RN Number
                      </label>
                      <input
                        type="qualifitextcation"
                        placeholder="Enter your RN Number"
                        name="rn"
                        value={signUpData.rn.value}
                        onChange={handleChange}
                        className="form-control signup-input"
                      />
                      {signUpData.rn.showError && (
                        <span className="signup-input-error">
                          {signUpData.rn.errormsg}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <button className="signup-btn" onClick={handleSubmit}>
        Sign Up
      </button>
      {globalError.isError && (
        <span className="signup-input-gloabl-error">
          {globalError.errorMsg}
        </span>
      )}
      <span className="signup-link">
        Already have an account?{" "}
        <span onClick={props.redirectToSignUp}>Login</span>
      </span>
    </div>
  );
};
export default FESignUp;
