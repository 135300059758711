import "./NSAboutUs.scss";
import AboutHeader from "../../components/AboutHeader/AboutHeader";
import TwoNurseImg from "../../assets/two-nurses.png";
import Objectives from "../../assets/Online_Test_Students_content.svg";
import abbg2 from "../../assets/aboutbg.svg";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import { getUserData } from "../../utils/common";
import { LoginModalContext } from "../../contexts/LoginModalContext";

const NSAboutUs = () => {
  const { setToster } = useContext(LoginModalContext);
  const navigate = useNavigate();
  const redirectUpdate = (link: string) => {
    navigate(link);
  };
  const { user } = useContext(AuthContext);
  const [isLogedIN, setIsLogedIN] = useState<any>(false);

  useEffect(() => {
    const userData = getUserData();
    if (userData && userData?.user?.role === "NursingStudent") {
      setIsLogedIN(true);
    } else {
      setIsLogedIN(false);
    }
  }, [user]);

  const demoTestChange = () => {
    if (isLogedIN) {
      navigate("/demoTest");
    } else {
      setToster({ triggerPopup: true });
    }
  };
  return (
    <div className="NSAboutUs">
      <div className="header-submenu">
        <div className="container">
          <a onClick={() => redirectUpdate("/nursing-students")}>
            {"About Us"}
          </a>
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/semester")}>{"Semester"}</a>
          )}
          {!isLogedIN && (
            <a onClick={() => redirectUpdate("/ns-semester")}>{"Semester"}</a>
          )}
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/my-package")}>{"My Package"}</a>
          )}
          {/* {!isLogedIN && (
            <a onClick={() => redirectUpdate("/demoTest")}>{"Demo Test"}</a>
          )} */}
        </div>
      </div>
      <AboutHeader
        text="Nursing Students"
        para="Testing Redefined : Access Knowledge at your Fingertips"
      />
      <div className="container">
        <div className="row NSAboutUs-section-one">
          <div
            className="col left-section"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h3>Comprehensive Knowledge Assessment</h3>
            <p className="mb-5">
              Nursing today requires a high level of knowledge and skill to meet
              the demands of healthcare. Basic nursing education programmes
              strive to provide learning experiences that will enable the
              nursing student to successfully complete the course and be a
              competent nurse upon graduation. The number of educational
              institutions in the field of nursing education has increased
              rapidly to meet the demands of the healthcare industry. The online
              test helps nursing students to assess their knowledge and level of
              preparation for the university exams. It provides a consistent
              assessment of what has been learned, reduces exam time and can be
              administered in various forms, such as multiple-choice questions,
              fill in the blank questions, true-or-false, ranking questions or a
              combination of these patterns.
            </p>
            <p>
              <strong>Impetus eLearning</strong>, an online test for students,
              has been developed based on the nursing curriculum prescribed by
              the Indian Nursing Council or respective state health universities
              to enhance their theoretical and skill-based knowledge so that
              they can apply the best clinical practices and perform well in
              their university examinations
            </p>
          </div>
          <div
            className="col"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <img src={TwoNurseImg} />
          </div>
        </div>
      </div>
      <div className="NSAboutUs-bg">
        <div className="container">
          <div className="row NSAboutUs-section-two">
            <div
              className="col"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img src={abbg2} className="NSAboutUs-leftImage" />
            </div>
            <div
              className="col right-section"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img src={Objectives} />

              {/* <h3>Objectives</h3>

      <ul>
        <li>
          1. <strong>Assessment of Knowledge:</strong> With the help of
          online tests, you can check your understanding of nursing
          concepts, medical terminology and other important nursing and
          non-nursing topics.
        </li>
        <li>
          2. <strong>Clinical Decision-Making:</strong> Nursing requires
          critical thinking and sound clinical judgment. Online tests
          allow you to assess a student&#39;s ability to make appropriate
          clinical decisions in a variety of clinical case studies.
        </li>
        <li>
          3. <strong>Competency Evaluation:</strong> Nursing students need
          to demonstrate clinical skills and competencies. Online tests
          allow you to assess your knowledge on practical skills in areas
          such as patient assessment, medication administration, wound
          care and other clinical procedures.
        </li>
        <li>
          4. <strong>Self-Assessment:</strong> Online tests can serve as a
          self-assessment tool for nursing students. They can determine
          their strengths and weaknesses, identify areas for improvement
          and focus their study efforts accordingly.
        </li>
        <li>
          5. <strong>Progress Tracking:</strong> Online tests can help
          teachers and students track their progress over time. They can
          see how well students are mastering the curriculum and make
          adjustment as needed.
        </li>
        <li>
          6. <strong>Formative Assessment:</strong> Online tests can be
          used for formative assessment to provide feedback to students
          during their learning process. This feedback can support them in
          continuous learning and improvement.
        </li>
        <li>
          7. <strong>Standardization:</strong> Online tests contribute to
          the standardization of assessment procedures and ensure that all
          nursing students are assessed fairly and consistently.
        </li>
      </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NSAboutUs;
