import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./PurchasedSubject.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import axios from "axios";
import { getUserData, jsonDecryptAES, shuffle, shuffleArray } from "../../utils/common";
import MenuBookIcon from "../../assets/menu_book.png";
import CalenderIcon from "../../assets/calendar_month_gray.svg";
import CalenderWhiteIcon from "../../assets/calendar_month.svg";
import MenuBookWhiteIcon from "../../assets/menu_book_white.svg";
import BtnWrapper from "../../common/BtnWrapper";
import { Button, Card, ListGroup } from "react-bootstrap";
import BackBtn from "../../assets/backbtn.svg";
import ExamList from "../../components/ExamList/ExamList";
import SubjectExamListAccordion from "../../components/ExamList/SubjectExamListAccordion";
import GradeBox from "../../components/GradeBox/GradeBox";
import dayjs from "dayjs";
import authInterceptor from "../../utils/authInterceptor";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import { nsdescription } from "../../constants/constants";
import SubjectExamList from "../../components/ExamList/SubjectExamList";
const PurchasedPackage = () => {
  const [packageData, setPackageData] = useState<any>();
  const [examData, setExamData] = useState([]);
  const [quizQuestionList, setQuizQuestionList] = useState();
  const [selectedExam, setSelectedExam] = useState<any>();
  const [subjectNameList, setSubjectNameList] = useState<any>([]);
  const [PQData, setPQData] = useState<any>();
  const userData = getUserData();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { packageId, isPackagePurchased, purchasedDataID } = state || {};
  const getpurchasedDataIDData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setPackageData(decryptAESData[0].packageData);
      getSubjectName(decryptAESData[0].packageData.list);
      setExamData(decryptAESData[0].examData);
      setPQData(decryptAESData[0]);
      console.log("getpurchasedDataIDData", decryptAESData[0]);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  useEffect(() => {
    if (quizQuestionList) {
      try {
        authInterceptor
          .post(`${adminPath}tempdata`, {
            questionData: {
              selectedExam: selectedExam?._id,
              pqdId: PQData._id,
              noOfQuestions: selectedExam?.noOfQuestions,
              duration: selectedExam?.duration,
              passPercent: selectedExam?.passPercent,
              totalMark: selectedExam?.totalQuestionMark,
              quizQuestionList: quizQuestionList,
              examtype: selectedExam.type,
              subName: packageData?.title,
            },
          })
          .then((response: any) => {
            navigate("/testPage", {
              state: { testId: response?.data?.id, title: packageData?.title },
            });
          });
      } catch (error: any) {
        if (error.response) {
        }
      }
    }
  }, [quizQuestionList]);

  const getSubjectName = async (data: any) => {
    const response: any = await authInterceptor.post(
      `${adminPath}subject/getSubjectName`,
      { data }
    );
    setSubjectNameList(response?.data?.list);
  };
  useEffect(() => {
    getpurchasedDataIDData();
  }, [state]);

  const getQuestionList = async (selectedType: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          unitCode: selectedType.selecteUnitList,
          subjectCode: selectedType.subject,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Quiz`, obj);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          const decryptAESData: any = jsonDecryptAES(resp.data.list);
          finalData.push(...decryptAESData);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  return (
    <>
      <section className="subjectdetails-wrapper">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div className="container subjectdetails-box">
          <div>
            <img
              src={BackBtn}
              alt="back-btn"
              onClick={() => navigate(-1)}
              className="backbtn"
            />
          </div>

          <div
            className="subjectdetails-box-detail-conatiner"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="subjectdetails-box-header">
              <BreadcrumbBox
                link="/semester"
                redirect={userData.user.semName}
                currentPage="Package"
              />
              <h2 className="purchasedTitle">{packageData?.title}</h2>
              <p className="desc">{packageData?.description} </p>
              <p className="price">
                Date Of Purchase :{" "}
                {dayjs(PQData?.purchasedDate).format("DD/MM/YYYY")}
              </p>
              <ul className="subjectdetails-box-suboptions">
                <li>
                  <img src={MenuBookWhiteIcon} alt="menu-book" />
                  {`Total Number of Subjects : ${subjectNameList.length}`}
                </li>
                <li>
                  <img src={CalenderWhiteIcon} alt="menu-book" />
                  {`Number of Days : ${packageData?.duration}`}
                </li>
              </ul>
              <div style={{ maxWidth: "300px" }}>
                {isPackagePurchased ? (
                  <Button
                    variant="primary"
                    style={{ visibility: "hidden" }}
                    className="buynow-button"
                    size="lg"
                  >
                    Take Test
                  </Button>
                ) : (
                  <BtnWrapper
                    paydata={packageData}
                    purchaseType={"Package"}
                    userType={"NursingStudent"}
                  />
                )}
              </div>
            </div>
            <div className="subject-list-card">
              <Card.Title>Subjects List</Card.Title>
              <ListGroup>
                {(subjectNameList || []).map((subject: string, index: any) => (
                  <ListGroup.Item key={index}>
                    <img src="assets/check_circle.svg" />
                    <span>{subject}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>
          <GradeBox />
        </div>
      </section>
      {isPackagePurchased && (
        <SubjectExamList
          examData={examData}
          isSubjectRequired={true}
          getQuestionList={getQuestionList}
        />
      )}
    </>
  );
};

export default PurchasedPackage;
 

