import Image from "react-bootstrap/Image";
import DoctorImage from "../../assets/banner-image.svg";
import "./homebanner.scss";
import Container from "react-bootstrap/Container";

const HomeBanner = () => {
  return (
    <div className="im-banner">
      <Container className="im-banner-box">
        <h1 className="title" data-aos="fade-down" data-aos-duration="1500">
          {`Welcome to Nursing Online Test`}
          {/* <span className="highlight">{`Online Test`}</span>
          {` System!`} */}
        </h1>
        <div className="im-banner-box-image" data-aos="fade-left" data-aos-duration="1500">
          <Image className="img-over-banner" src={DoctorImage} alt="Doctor" />
        </div>
      </Container>
    </div>
  );
};

export default HomeBanner;
