import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./PurchasedModule.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import axios from "axios";
import { getUserData, jsonDecryptAES, shuffle, shuffleArray } from "../../utils/common";
import CalenderIcon from "../../assets/calendar_month_gray.svg";
import CalenderWhiteIcon from "../../assets/calendar_month.svg";
import BackBtn from "../../assets/backbtn.svg";
import { dateDiff } from "../../utils/common";
import { Button } from "react-bootstrap";
import ExamList from "../../components/ExamList/ExamList";
import GradeBox from "../../components/GradeBox/GradeBox";
import dayjs from "dayjs";
import authInterceptor from "../../utils/authInterceptor";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
const PurchasedModule = () => {
  const [moduleData, setModuleData] = useState<any>();
  const [examData, setExamData] = useState([]);
  const [quizQuestionList, setQuizQuestionList] = useState();
  const [selectedExam, setSelectedExam] = useState<any>();
  const [PQData, setPQData] = useState<any>();
  const userData = getUserData();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { purchasedDataID, endDate,code } = state || {};

  const getpurchasedDataIDData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setModuleData(decryptAESData[0].packageData);
      setExamData(decryptAESData[0].examData);
      setPQData(decryptAESData[0]);
      console.log("getpurchasedDataIDData", decryptAESData[0]);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}moduleTest/FE/getAll/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getpurchasedExamData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const checkForNewExam = async () => {
    const examList = await getExamData(code);
    const purchasedItemsList = await getpurchasedExamData();
    if (examList.length >0 && examList.length !== purchasedItemsList[0].examData.length) {
      let result = examList.filter((o1: any) => {
        return !purchasedItemsList[0].examData?.some((o2: any) => {
          return o1._id === o2._id;
        });
      });
      if(result?.length>0){
        purchasedItemsList[0].examData?.push(...result);
      }
      console.log(purchasedItemsList)
      updatePQD(purchasedItemsList[0]);
    } else {
      getpurchasedDataIDData();
    }
  };
  const updatePQD = async (data: any) => {
    try {
      await authInterceptor.patch(
        `${adminPath}PQD/FEUpdate/${purchasedDataID}`,
        data
      );
      getpurchasedDataIDData();
    } catch (error: any) {
      if (error.response) {
      }
    }
  };


  useEffect(() => {
    if (quizQuestionList) {
      try {
        authInterceptor
          .post(`${adminPath}tempdata`, {
            questionData: {
              selectedExam: selectedExam?._id,
              pqdId: PQData._id,
              noOfQuestions: selectedExam?.noOfQuestions,
              duration: selectedExam?.duration,
              passPercent: selectedExam?.passPercent,
              totalMark: selectedExam?.totalQuestionMark,
              quizQuestionList: quizQuestionList,
              examtype: selectedExam.type,
              subName: moduleData?.title,
            },
          })
          .then((response: any) => {
            navigate("/testPage", { state: { testId: response?.data?.id,title:moduleData?.title } });
          });
      } catch (error: any) {
        if (error.response) {
        }
      }
    }
  }, [quizQuestionList]);
  useEffect(() => {
    //getpurchasedDataIDData();
    checkForNewExam();
  }, [state]);

  const getQuestionList = async (selectedType: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          moduleCode: selectedType.moduleCode,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Module/Quiz`, obj);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp.data.list);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  return (
    <>
      <section className="moduledetails-wrapper">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container moduledetails-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div>
            <img
              src={BackBtn}
              alt="back-btn"
              onClick={() => navigate(-1)}
              className="backbtn"
            />
          </div>

          <div className="moduledetails-box-detail-conatiner">
            <div className="moduledetails-box-header">
              <BreadcrumbBox
                link="/semester"
                redirect={'Speciality'}
                currentPage="Package"
                isBack={true}
              />

              <h2 className="purchasedTitle">{moduleData?.title}</h2>
              <p className="desc">{moduleData?.description}</p>
              <ul className="moduledetails-box-suboptions">
                <li>
                  <img src={CalenderWhiteIcon} alt="menu-book" />
                  {`Number of Days Left :${dateDiff(endDate)} Days`}
                </li>
              </ul>
              <p className="price">
                Date Of Purchase :{" "}
                {dayjs(PQData?.purchasedDate).format("DD/MM/YYYY")}
              </p>
            
              <div style={{ maxWidth: "300px" }}>
                <Button
                  variant="primary"
                  style={{ visibility: "hidden" }}
                  className="buynow-button"
                  size="sm"
                >
                  Take Test
                </Button>
              </div>
            </div>
          </div>
          <GradeBox />
          {/* <div className="moduledetails-packagecard" data-aos="flip-up"  data-aos-duration="1000">
            <img src="/assets/package.png" />
            <div className="moduledetails-packagecard-details">
              <h3>{moduleData?.title}</h3>
              <p>{moduleData?.description}</p>
              <ul className="moduledetails-box-suboptions">
                <li>
                  <img src={CalenderIcon} alt="menu-book" />
                  {`Number of Days Left :${dateDiff(endDate)}`}
                </li>
              </ul>
              <Button variant="primary" style={{visibility:"hidden"}} className="buynow-button" size="lg">
                Take Test
              </Button>
            </div>
          </div> */}
        </div>
      </section>
      <ExamList examData={examData} getQuestionList={getQuestionList} title={moduleData?.title} isPurchasedModule={true} />
    </>
  );
};

export default PurchasedModule;
