import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./semester.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import PurchasedPackage from "../../components/CarouselSlider/PurchasedPackage";
import { jwtDecode } from "jwt-decode";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import authInterceptor from "../../utils/authInterceptor";
import { nsdescription } from "../../constants/constants";
import { dateDiff } from "../../utils/common";
const NSMyPackage = () => {
  const navigate = useNavigate();
  const [myCourse, setMyCourse] = useState([]);
  const { state } = useLocation();
  const { id, name } = state || {};
  const userData = getUserData();
  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}/NursingStudent`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const filteredData = decryptAESData.filter((data:any)=>{
        const diffValue:any = dateDiff(data.endDate)
        return diffValue > 0
      }) 
      const list = filteredData.map((data: any) => {
        return { ...data.purchasedItems[0], purchasedDate: data.purchasedDate };
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getAlldata = async (id: any) => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      purchasedData = await getPurchasedData(userData);
    }
  };
  useEffect(() => {
    getAlldata(id);
  }, [id]);
  const cardBtn = (data: any) => {
    if (data.isPackage) {
      navigate("/PackageDetails", {
        state: {
          isPackagePurchased: true,
          purchasedDataID: data.purchasedDataID,
          packageId: data._id,
        },
      });
    } else {
      navigate("/SubjectDetails", {
        state: {
          purchasedDataID: data.purchasedDataID,
          endDate: data.endDate,
          code: data.code,
        },
      });
    }
  };

  return (
    <>
      <section className="semester-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <BreadcrumbBox
            link="/semester"
            redirect={userData.user.semName}
            currentPage="Subject"
          />
          <div className="package-header">
            <h2 className="title">My Subjects</h2>
            <Link to={"/semester"} className="nonPurchased">
            Click here for Non Purchased Subjects <img src="assets/breadcrumb.svg" />
            </Link>
          </div>
          <p className="desc">{nsdescription}</p>

          {myCourse && myCourse.length > 0 ? (
            <div className="myCourse">
              <PurchasedPackage
                data={myCourse}
                cardBtn={cardBtn}
                userType={"NursingStudent"}
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default NSMyPackage;
