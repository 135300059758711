import { useContext, useEffect, useState } from "react";
import "./CartDrawer.scss";
import { CartModalContext } from "../../contexts/CartModalContext";
import { adminPath } from "../../constants/api";
import authInterceptor from "../../utils/authInterceptor";
import {
  getUserData,
  jsonDecryptAES,
  jsonEncryptAES,
} from "../../utils/common";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { paymentStatus } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import axios from "axios";

const CartDrawer = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setCart, cartstate } = useContext(CartModalContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const [finelPrice, setFinelPrice] = useState(0);
  const [pacakgeList, setPacakgeList] = useState([]);
  // const [subjectList, setSubjectList] = useState([]);
  const [discountPercent, setDiscountPercent] = useState<any>();
  const [disclimerPercent, setDisclimerPercent] = useState(0);
  const [isDisclimer, setIsDisclimer] = useState(false);
  const [isTrigger, setIsTrigger] = useState(false);
  const [cartList, setCartList] = useState([]);
  const navigate = useNavigate();
  const { setToster } = useContext(SnackbarContext);

  const userData = getUserData();
  const getDataList = async () => {
    let list;
    if (cartstate.type === "Speciality") {
      list = await getSpecPackageDetails();
    } else if (cartstate.type === "Module") {
      list = await getModulePackageDetails();
    } else {
      list = await getSubjectPackageDetails();
    }
    //await getSubjectDetails();
    await getCartItems(list);
  };
  useEffect(() => {
    setIsOpen(cartstate.triggerPopup);
    if (cartstate.triggerPopup) {
      getDataList();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [cartstate.triggerPopup]);
  const closeDrawer = () => {
    setCart({ triggerPopup: false });
  };
  const priceCalculation = (cartList: any) => {
    let basePrice = 0;
    cartList.map((item: any) => {
      basePrice = basePrice + item?.cartList?.finalPrice;
    });
    setTotalPrice(basePrice);
    setFinelPrice(basePrice - (basePrice * discountPercent) / 100);
  };
  const getCartItems = async (packageDataList: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}cartItems/${userData.user.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      disCountUpdate(packageDataList, decryptAESData);
      setCartList(decryptAESData);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  useEffect(() => {
    if (isTrigger) {
      priceCalculation(cartList);
      setIsTrigger(false);
    }
  }, [isTrigger]);
  useEffect(() => {
    if (discountPercent === 0 || discountPercent > 0) {
      priceCalculation(cartList);
    }
  }, [discountPercent]);
  const disCountUpdate = (data: any, cartDataList: any) => {
    if (data.length === 0) {
      setDiscountPercent(0);
    }
    if (cartDataList.length === 0) {
      setDiscountPercent(0);

      setTotalPrice(0);
      setFinelPrice(0);
    } else {
      data.map((item: any) => {
        if (cartDataList.length === Number(item?.subjectCount)) {
          setDiscountPercent(Number(item?.packageDiscountPercent));
        }
        if (Number(item?.subjectCount) === cartDataList.length + 1) {
          setDisclimerPercent(Number(item?.packageDiscountPercent));
          setIsDisclimer(true);
        }
      });
    }
    setIsTrigger(true);
  };
  const getSubjectPackageDetails = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}nspackage/FE/${userData.user.semester}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setPacakgeList(decryptAESData);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getSpecPackageDetails = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}specialityPackage/FE/allspec`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setPacakgeList(decryptAESData);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getModulePackageDetails = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}modulePackage/FE/allPackage`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setPacakgeList(decryptAESData);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  // const getSubjectDetails = async () => {
  //   try {
  //     const response: any = await authInterceptor.get(
  //       `${adminPath}subject/FE/sem/${userData.user.semester}`
  //     );
  //     const decryptAESData: any = jsonDecryptAES(response?.data?.list);
  //     setSubjectList(decryptAESData);
  //     return decryptAESData;
  //   } catch (error: any) {
  //     if (error.response) {
  //     }
  //   }
  // };

  const removeCartItems = async (data: any, index: any) => {
    try {
      const response: any = await authInterceptor.delete(
        `${adminPath}cartItems/${data?._id}`
      );
      getCartItems(pacakgeList);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getSpecExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}specialityTest/FE/getAll`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getModuleExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}moduleTest/FE/getAll/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getAllModuleExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}moduleTest/FE/getAll`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}onlineTest/FE/subject/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getPackageExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}onlineTest/FE/package`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const removeAllCartItems = async () => {
    try {
      await authInterceptor.delete(
        `${adminPath}cartItems/deleteall/${userData.user.IHSID}`
      );
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const handleCartPurchase = async () => {
    const d = new Date();
    let month: any = d.getMonth();
    let day = d.getDate().toString().padStart(2, "0");
    let year: any = d.getFullYear();
    year = year.toString().substr(-2);
    month = (month + 1).toString().padStart(2, "0");
    const id =
      year + day + month + Math.floor(100000 + Math.random() * 900000);
    const purchaseTempData: any = await authInterceptor.post(
      `${adminPath}purchaseTemp/cart/`,
      {
        cartList: cartList
      }
    );
    const apiResponse = await authInterceptor.post(
      `${adminPath}payment/paymentRequestBulk`,
      {
        amount: finelPrice.toFixed(2),
        orderID: id,
        orderIDs: '',
        packagename:purchaseTempData?.data?.id || '',
        IHSID: userData.user.IHSID,
      }
    );
    window.location.href = apiResponse?.data?.url;
    // const requests = cartList.map(async (item: any) => {
    //   return handleSuccess(item?.cartList, item?._id);
    // });
    // axios.all(requests).then(async (result) => {
    //   const apiResponse = await authInterceptor.post(
    //     `${adminPath}payment/paymentRequestBulk`,
    //     {
    //       amount: finelPrice.toFixed(2),
    //       orderID: result[0]?.orderID,
    //       orderIDs: result.map((data) => data?.orderID).join(","),
    //       packagename: result.map((data) => data?.packagename).join(","),
    //       IHSID: userData.user.IHSID,
    //     }
    //   );
    //   window.location.href = apiResponse?.data?.url;
    //   //await removeAllCartItems();
    //   //closeDrawer();
    //   //navigate("/my-package");
    //   console.log(result);
    // });
  };
  const handleSuccess = async (cartData: any, cartID: any) => {
    try {
      const d = new Date();
      let month: any = d.getMonth();
      let day = d.getDate().toString().padStart(2, "0");
      let year: any = d.getFullYear();
      year = year.toString().substr(-2);
      month = (month + 1).toString().padStart(2, "0");
      const id =
        year + day + month + Math.floor(100000 + Math.random() * 900000);
      const token: any = sessionStorage.getItem("token");
      const userData: any = jwtDecode(token);
      let result = new Date();
      result.setDate(d.getDate() + Number(cartData.duration));
      cartData["endDate"] = result;
      if (
        cartstate.type === "Package" ||
        cartstate.type === "SpecPackage" ||
        cartstate.type === "Speciality"
      ) {
        cartData["isPackage"] = true;
      }
      let examList;
      if (cartstate.type === "Package") {
        examList = await getPackageExamData(cartData?.list);
      } else if (cartstate.type === "Subject") {
        examList = await getExamData(cartData.code);
      } else if (cartstate.type === "Module") {
        examList = await getModuleExamData(cartData.code);
      } else if (cartstate.type === "SpecPackage") {
        examList = await getSpecExamData(cartData.list);
      } else if (cartstate.type === "Speciality") {
        const moduleData = await getAllModuleExamData(cartData.list);
        const specData = await getSpecExamData([cartData.code]);
        examList = [...specData, ...moduleData];
      }
      let PQDObj: any = {
        orderID: id,
        userType: userData.user.role,
        IHSID: userData.user.IHSID,
        userName: userData.user.name,
        purchasedDate: dayjs(),
        packageData: { ...cartData },
        examData: examList,
      };
      const encryptPQD = await jsonEncryptAES(PQDObj);
      const purchasedDataID = await authInterceptor.post(
        `${adminPath}PQD/FE/`,
        {
          data: encryptPQD,
        }
      );
      cartData["purchasedDataID"] = purchasedDataID.data.id;
      let obj = {
        orderID: id,
        userType: userData.user.role,
        IHSID: userData.user.IHSID,
        userName: userData.user.name,
        status: paymentStatus.Success,
        purchasedItems: [cartData],
        purchasedDate: dayjs(),
        endDate: result,
        date: new Date(),
      };
      const encryptAESData = await jsonEncryptAES(obj);
      await authInterceptor.post(`${adminPath}order`, {
        data: encryptAESData,
      });
      // await authInterceptor.delete(`${adminPath}cartItems/${cartID}`);
      return {
        orderID: id,
        packagename: cartData?.title,
      };
    } catch (error: any) {
      if (error.response) {
        setToster({
          message: error?.response?.data?.msg,
          type: "error",
        });
      }
    }
  };

  return (
    <div>
      <div
        className={`CartDrawer-wrapper ${isOpen ? "wrapper-open" : ""} `}
      ></div>
      <div className={`CartDrawer ${isOpen ? "open" : ""}`}>
        <div className="cartHeader">
          <span>Cart Items </span>
          <span className="close" onClick={closeDrawer}>
            X
          </span>
        </div>
        <div className="cartHeaderContentWrapper">
          {cartList.length === 0 ? (
            <div className="emptyCart">No Items Found</div>
          ) : (
            (cartList || []).map((item: any, index: number) => (
              <div className="cartItems">
                <div className="cartItemImg">
                  <img src={item?.cartList?.image} alt="product" />
                </div>
                <div className="cartItemContent">
                  <div className="cartTitle">
                    <h4> {item?.cartList?.title}</h4>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      onClick={() => removeCartItems(item, index)}
                    >
                      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                  </div>
                  <p>{item?.cartList?.description}</p>
                  <div className="price-list">
                    <span>
                      Price : <span className="rupee-symbol">&#x20B9;</span>
                      {item?.cartList?.finalPrice}
                    </span>
                    <span className="duration">
                      Validity : {item?.cartList?.duration} <span>days</span>
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="cartFooter-wrapper">
          {isDisclimer && cartList.length > 0 && (
            <p className="discount-strip">
              Buy <strong>{cartList.length + 1}</strong> subject and get{" "}
              <strong>
                {disclimerPercent}
                {"%"}
              </strong>{" "}
              discount
            </p>
          )}

          <div className="cartFooter">
            <div className="price-details">
              <ul>
                <li>
                  <span>Total Price</span>
                  <span>{totalPrice.toFixed(2)}</span>
                </li>
                <li>
                  <span>Discount</span>
                  <span>{discountPercent}%</span>
                </li>
                <li>
                  <span>Grand Total</span>
                  <span>{finelPrice.toFixed(2)}</span>
                </li>
              </ul>
            </div>
            {/* <button className="checkoutBtn">Cancel</button> */}
            <div className="cart-btn">
              <button
                className="checkoutBtn"
                onClick={handleCartPurchase}
                disabled={cartList.length === 0}
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;
