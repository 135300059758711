import Image from "react-bootstrap/Image";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./PurchasedSubject.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import axios from "axios";
import {
  getUserData,
  jsonDecryptAES,
  shuffle,
  shuffleArray,
} from "../../utils/common";
import CalenderWhiteIcon from "../../assets/calendar_month.svg";
import BackBtn from "../../assets/backbtn.svg";
import { dateDiff } from "../../utils/common";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import { nsdescription } from "../../constants/constants";
import GradeBox from "../../components/GradeBox/GradeBox";
import authInterceptor from "../../utils/authInterceptor";
import BreadcrumbBox from "../../components/BreadcrumbBox/BreadcrumbBox";
import SubjectExamList from "../../components/ExamList/SubjectExamList";
const PurchasedSubject = () => {
  const [subjectData, setSubjectData] = useState<any>();
  const [PQData, setPQData] = useState<any>();
  const [examData, setExamData] = useState([]);
  const [quizQuestionList, setQuizQuestionList] = useState();
  const [selectedExam, setSelectedExam] = useState<any>();
  const userData = getUserData();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { purchasedDataID, endDate, code } = state || {};
  const getpurchasedDataIDData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setSubjectData(decryptAESData[0].packageData);
      setExamData(decryptAESData[0].examData);
      setPQData(decryptAESData[0]);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const updatePQD = async (data: any) => {
    try {
      await authInterceptor.patch(
        `${adminPath}PQD/FEUpdate/${purchasedDataID}`,
        data
      );
      getpurchasedDataIDData();
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getpurchasedExamData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/${purchasedDataID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}onlineTest/FE/subject/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const checkForNewExam = async () => {
    const examList = await getExamData(code);
    const purchasedItemsList = await getpurchasedExamData();
    if (
      examList.length > 0 &&
      examList.length !== purchasedItemsList[0].examData.length
    ) {
      let result = examList.filter((o1: any) => {
        return !purchasedItemsList[0].examData?.some((o2: any) => {
          return o1._id === o2._id;
        });
      });
      if (result?.length > 0) {
        purchasedItemsList[0].examData?.push(...result);
      }
      updatePQD(purchasedItemsList[0]);
    } else {
      getpurchasedDataIDData();
    }
  };

  useEffect(() => {
    if (quizQuestionList) {
      try {
        authInterceptor
          .post(`${adminPath}tempdata`, {
            questionData: {
              selectedExam: selectedExam?._id,
              pqdId: PQData._id,
              noOfQuestions: selectedExam?.noOfQuestions,
              duration: selectedExam?.duration,
              passPercent: selectedExam?.passPercent,
              totalMark: selectedExam?.totalQuestionMark,
              quizQuestionList: quizQuestionList,
              examtype: selectedExam.type,
              subName: subjectData?.title,
            },
          })
          .then((response: any) => {
            navigate("/testPage", {
              state: { testId: response?.data?.id, title: subjectData?.title },
            });
          });
      } catch (error: any) {
        if (error.response) {
        }
      }
    }
  }, [quizQuestionList]);

  const getAlldata = async () => {
    //await getpurchasedDataIDData();
    await checkForNewExam();
  };

  useEffect(() => {
    getAlldata();
  }, [state]);

  const getQuestionList = async (selectedType: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          unitCode: selectedType.selecteUnitList,
          subjectCode: selectedType.subject,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Quiz`, obj);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          const decryptAESData: any = jsonDecryptAES(resp.data.list);
          finalData.push(...decryptAESData);
        });

        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  return (
    <>
      <section className="subjectdetails-wrapper">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container subjectdetails-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div>
            <img
              src={BackBtn}
              alt="back-btn"
              onClick={() => navigate(-1)}
              className="backbtn"
            />
          </div>

          <div className="subjectdetails-box-detail-conatiner">
            <div className="subjectdetails-box-header">
              <BreadcrumbBox
                link="/semester"
                redirect={userData.user.semName}
                currentPage="Package"
              />
              <h2 className="purchasedTitle">{subjectData?.title}</h2>
              {/* <p className="desc">{nsdescription}</p> */}
              <p className="desc">{subjectData?.description}</p>
              <p className="price">
                Number of Days Left : {dateDiff(endDate)} Days{" "}
              </p>
              <ul className="subjectdetails-box-suboptions">
                <li>
                  <img src={CalenderWhiteIcon} alt="menu-book" />
                  {`Date Of Purchase :${dayjs(PQData?.purchasedDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </li>
              </ul>
              <div style={{ maxWidth: "300px" }}>
                <Button
                  variant="primary"
                  style={{ visibility: "hidden" }}
                  className="buynow-button"
                  size="sm"
                >
                  Take Test
                </Button>
              </div>
            </div>
          </div>
          <GradeBox />
        </div>
      </section>
      <SubjectExamList
        isFilterRequired={true}
        examData={examData}
        getQuestionList={getQuestionList}
      />
    </>
  );
};

export default PurchasedSubject;
