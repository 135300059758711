import React, { useState } from "react";
import { adminPath } from "../../constants/api";
import axios from "axios";
import "./ForgotPassword.scss";
import OtpInput from "react-otp-input";
import { isValidNumber, requiredPhoneValidation } from "../../utils/formutils";
import EyeIcon from "../../assets/visibility.svg";
import { toast } from "react-toastify";
import { jsonEncryptAES } from "../../utils/common";

const ForgotPassword = (props: any) => {
  const [globalError, setglobalError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [otpStatus, setotpStatus] = useState("initialScreen");
  const [otp, setOtp] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loginData, setLoginData] = useState({
    mobileNumber: { value: "", errormsg: "", showError: false },
    otpNumber: { value: "", errormsg: "", showError: false },
    password: { value: "", errormsg: "", showError: false },
    confirmPassword: { value: "", errormsg: "", showError: false },
  });
  const verifyOTP = async () => {
    if (handleValidation()) {
      let payload = {
        mobile: Number(loginData.mobileNumber.value),
        OTP: otp,
      };
      try {
        await axios.post(`${adminPath}common/SMS/verify`, payload);
        setotpStatus("passwordSubmitScreen");
        setglobalError({ isError: false, errorMsg: "" });
      } catch (error: any) {
        console.log("error", error);
        const { data } = error.response;
        setglobalError({ isError: true, errorMsg: data.msg });
      }
    }
  };
  const passwordChange = async () => {
    if (handleValidation()) {
      let payload = {
        mobile: Number(loginData.mobileNumber.value),
        confirmPassword: loginData.confirmPassword.value,
      };
      const encryptAESData = await jsonEncryptAES(payload);
      try {
        await axios.post(`${adminPath}common/SMS/changePassword`, {
          list: encryptAESData,
        });
        setglobalError({ isError: false, errorMsg: "" });
        toast.success("Password Updated Successful", {
          position: "top-center",
        });
      } catch (error: any) {
        console.log("error", error);
        const { data } = error.response;
        setglobalError({ isError: true, errorMsg: data.msg });
      }
    }
  };
  const handleSubmit = async (event: any, isFrom: any) => {
    event.preventDefault();
    if (handleValidation()) {
      let payload = {
        mobile: Number(loginData.mobileNumber.value),
      };
      try {
        await axios.post(`${adminPath}common/SMS/forgotpassword`, payload);
        if (isFrom === "mobileSubmitScreen") {
          setotpStatus("mobileSubmitScreen");
        }
        if (isFrom === "passwordSubmitScreen") {
          setotpStatus("passwordSubmitScreen");
        }
        // props.handleClose();
      } catch (error: any) {
        console.log("error", error);
        const { data } = error.response;
        setglobalError({ isError: true, errorMsg: data.msg });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [e.target.name]: {
        value: e.target.value,
        errormsg: "",
        showError: false,
      },
    });
    setglobalError({ isError: false, errorMsg: "" });
  };
  const otpChange = (value: any) => {
    console.log("value :", value);
    setOtp(value);
    setLoginData({
      ...loginData,
      ["otpNumber"]: {
        value: value,
        errormsg: "",
        showError: false,
      },
    });
  };
  const handleValidation = () => {
    let formIsValid = true;
    let obj: any = {};
    if (otpStatus === "initialScreen") {
      if (!loginData.mobileNumber.value) {
        formIsValid = false;
        obj.mobileNumber = {
          value: loginData.mobileNumber.value,
          errormsg: "This is required field",
          showError: true,
        };
      } else if (isValidNumber(loginData.mobileNumber.value)) {
        formIsValid = false;
        obj.mobileNumber = requiredPhoneValidation(
          loginData.mobileNumber.value
        );
      }
    }

    if (otpStatus === "mobileSubmitScreen") {
      if (!loginData.otpNumber.value) {
        formIsValid = false;
        obj.otpNumber = {
          value: loginData.otpNumber.value,
          errormsg: "This is required field",
          showError: true,
        };
      }
    }

    if (otpStatus === "passwordSubmitScreen") {
      if (!loginData.password.value) {
        formIsValid = false;
        obj.password = {
          value: loginData.password.value,
          errormsg: "This is required field",
          showError: true,
        };
      }
      if (!loginData.confirmPassword.value) {
        formIsValid = false;
        obj.confirmPassword = {
          value: loginData.confirmPassword.value,
          errormsg: "This is required field",
          showError: true,
        };
      }
      if (loginData.confirmPassword.value !== loginData.password.value) {
        formIsValid = false;
        obj.confirmPassword = {
          value: loginData.confirmPassword.value,
          errormsg: "Password Mismatch",
          showError: true,
        };
      }
    }

    if (otpStatus === "passwordSubmitScreen") {
      if (!loginData.password.value) {
        formIsValid = false;
        obj.password = {
          value: loginData.password.value,
          errormsg: "This is required field",
          showError: true,
        };
      }
      if (!loginData.confirmPassword.value) {
        formIsValid = false;
        obj.confirmPassword = {
          value: loginData.confirmPassword.value,
          errormsg: "This is required field",
          showError: true,
        };
      }
      if (loginData.confirmPassword.value !== loginData.password.value) {
        formIsValid = false;
        obj.confirmPassword = {
          value: loginData.confirmPassword.value,
          errormsg: "Password Mismatch",
          showError: true,
        };
      }
    }

    const newData = { ...loginData, ...obj };
    setLoginData(newData);
    return formIsValid;
  };

  return (
    <>
      <div className="login-wrapper">
        <h2 className="login-title" style={{ marginBottom: "40px" }}>
          Forgot Password!
        </h2>
        {otpStatus === "initialScreen" && (
          <div>
            <label className="form-label login-label">
              Enter Register Mobile Number
            </label>
            <div className="input-group mb-4 login-input-group">
              <span className="input-group-text">
                <img src="assets/mail.png" />
              </span>
              <input
                type="text"
                placeholder="Enter Register Mobile Number"
                name="mobileNumber"
                onChange={handleChange}
                className="form-control"
              />
            </div>
            {loginData.mobileNumber.showError && (
              <span className="input-error">
                {loginData.mobileNumber.errormsg}
              </span>
            )}
            <button
              className="login-btn"
              onClick={(e) => {
                handleSubmit(e, "mobileSubmitScreen");
              }}
            >
              Send OTP
            </button>
          </div>
        )}
        {otpStatus === "mobileSubmitScreen" && (
          <div>
            <label className="form-label login-label">Enter OTP</label>
            <OtpInput
              value={otp}
              inputStyle="form-control"
              shouldAutoFocus
              containerStyle="input-group otp-input-style"
              onChange={otpChange}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            {loginData.otpNumber.showError && (
              <div className="input-error otp-error">
                {loginData.otpNumber.errormsg}
              </div>
            )}
            <button className="login-btn" onClick={verifyOTP}>
              Verify
            </button>
          </div>
        )}
        {otpStatus === "passwordSubmitScreen" && (
          <div>
            <label className="form-label login-label">Enter Password</label>
            <div className="input-group mb-4 login-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="password"
                onChange={handleChange}
                className="form-control"
              />
              <button
                className="password-eye-btn"
                onClick={() => setshowPassword(!showPassword)}
              >
                <img src={EyeIcon} alt="back-btn" />
              </button>
            </div>
            {loginData.password.showError && (
              <span className="input-error">{loginData.password.errormsg}</span>
            )}
            <label className="form-label login-label">Confirm Password</label>
            <div className="input-group mb-4 login-input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Confirm Password"
                name="confirmPassword"
                onChange={handleChange}
                className="form-control"
              />
              <button
                className="password-eye-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <img src={EyeIcon} alt="back-btn" />
              </button>
            </div>
            {loginData.confirmPassword.showError && (
              <span className="input-error">
                {loginData.confirmPassword.errormsg}
              </span>
            )}
            <button className="login-btn" onClick={passwordChange}>
              Update
            </button>
          </div>
        )}
        {globalError.isError && (
          <span className="input-error">{globalError.errorMsg}</span>
        )}
        <span className="signup-link">
          Already have an account?{" "}
          <span onClick={props.redirectToSignUp}>Login</span>
        </span>
      </div>
    </>
  );
};
export default ForgotPassword;
