export const tokensconst = "tokens";
export const roleconst = "role";
export const adminNavList = [
  { title: "DashBoard", value: "dashboard" },
  { title: "Nursing Students", value: "NursingStudent" },
  { title: "Nurses", value: "Nurses" },
  { title: "College admin", value: "Collegeadmin" },
  // { title: "Order", value: "Order" },
  // { title: "Customer Support", value: "CustomerSupport" },
  // { title: "Users", value: "Users" },
  { title: "Upload Image", value: "Upload" },
];
export const addQuestionBtns = [
  { title: "Add New Question", questiontype: "multiplechoice" },
  { title: "Add Sequence Question", questiontype: "sequence" },
  { title: "Match The Following Question", questiontype: "match" },
  { title: "Add True Or False Question", questiontype: "trueorfalse" },
  { title: "Add Image Question", questiontype: "image" },
];
export const paymentStatus = { Success: "Success", Failure: "Failure" };
export const levelList = [
  { title: "Level 1", value: "L1" },
  { title: "Level 2", value: "L2" },
  { title: "Level 3", value: "L3" },
];
export const testType = [
  { title: "Unit test", value: "Unit" },
  { title: "Term test", value: "Term" },
  { title: "Mock test", value: "Mock" },
];
export const singUpType = [
  { title: "Nursing Students", value: "NursingStudent" },
  { title: "Nurses", value: "Nurse" },
];

export const semList = [
  { title: "One", value: "One" },
  { title: "Two", value: "Two" },
  { title: "Three", value: "Three" },
  { title: "Four", value: "Four" },
  { title: "Five", value: "Five" },
  { title: "Six", value: "Six" },
  { title: "Seven", value: "Seven" },
  { title: "Eight", value: "Eight" },
];

export const courseList = ["B.Sc Nursing", "M.Sc Nursing", "GNM"];
export const collegeList = [
  { title: "Anna University", value: "AU" },
  { title: "SVIST", value: "SVIST" },
];

export const ADD = "add";
export const EDIT = "edit";
export const EDITTEXT = "Edit";
export const ADDTEXT = "Add";

export enum ROLE {
  Admin = "admin",
  SME = "sme",
}
export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const aboutPara = [
  "The rapid development of information and communication technologies has changed the way we live, work and learn.The rapid development of information and communication technologies has meant that innovative methods of knowledge transfer in the education system and assessment are becoming increasingly important. Online tests are being used to assess nurses  and nursing students  knowledge and understanding of key nursing concepts.In the new technological economy, they have become a worldwide phenomenon, crossing oceans and reaching remote areas.",
  "An online test is an electronic test that tests knowledge and understanding of thesubject in question. It has unique advantages as it overcomes the barriers of introversion and physical distance, creates new ways of communication and shapes identity. It enables consistent assessment of learning, reduces assessment time and can be administered in a variety of forms, e.g. as multiple-choice questions, fill-in-the-blank questions, questions with a ranking, true or false, or a combination of thesepatterns",
  "Impetus Online Test Solutions offers the best online tests for nurses and nursing students. These tests are essential assessment tools to evaluate their knowledge, skills - based knowledge and competence in various aspects of nursing practice. These tests are an integral part of nursing curricula and professional development, helping to ensure that nurses and nursing students are well prepared to provide quality patient care and perform better on university exams. Online testing offers the advantage of accessibility, allowing nurses and nursing students to take the tests from the comfort of their own homes or premises. This flexibility is especially valuable for professionals preparing for state exams.",
  "Impetus; online testing platform provides essential assessment tools to evaluate the knowledge, skills and competencies of professional nurses and nursing students toassess their ability to perform in competitive and university examinations conducted by various agencies. Impetus offers online test series exclusively for nurses preparing for state recruitment exams and for nursing students preparing for university exams",
];

export const welcomePara = [
  "Impetus Online Test Solutions offers the best online tests for nurses and nursing students. These tests are essential assessment tools to evaluate their knowledge, skills and competence in various aspects of nursing practise. These tests are an integral part of nursing curricula and professional development. They help ensure that nurses and nursing students are well prepared to provide quality patient care and perform better on university examinations. Online testing offers the advantage of accessibility, allowing nurses and nursing students to take the tests from the comfort of their own homes or premises. This flexibility applies to state or central recruitment board examinations.",
  "The rapid development of information and communication technologies has changed the way we live, work and learn.The rapid development of information and communication technologies has meant that innovative methods of knowledge transfer in the education system and assessment are becoming increasingly important. Online tests are being used to assess nurses  and nursing students  knowledge and understanding of key nursing concepts.In the new technological economy, they have become a worldwide phenomenon, crossing oceans and reaching remote areas.",
];
export const menuList = [
  {
    title: "Home",
    link: "home",
  },
  {
    title: "About Us",
    link: "aboutus",
  },
  {
    title: "Nursing Students",
    link: "",
    subMenu: [
      {
        title: "About Us",
        link: "nursing-students",
      },
      {
        title: "Objectives",
        link: "ns-objectives",
      },
      {
        title: "Online Test",
        link: "ns-onlinetest",
      },
      {
        title: "Semester",
        link: "ns-semester",
      },
    ],
  },
  {
    title: "Nurse",
    link: "",
    subMenu: [
      {
        title: "About Us",
        link: "nurse-aboutus",
      },
      {
        title: "Objectives",
        link: "nurse-objectives",
      },
      {
        title: "Online Test",
        link: "nurse-onlinetest",
      },
      {
        title: "Speciality",
        link: "nurse-speciality",
      },
    ],
  },
];
export const nursetestType = [
  { title: "Speciality test", value: "Speciality" },
  { title: "Mock test", value: "Mock" },
  { title: "Module test", value: "Module" },
];
export const grade = [
  {
    points: 10,
    grade: "O",
    text: "Outstanding",
    from: 100,
    to: 100,
  },
  {
    points: 9,
    grade: "A+",
    text: "Excellent",
    from: 90,
    to: 99.99,
  },
  {
    points: 8,
    grade: "A",
    text: "Very Good",
    from: 80,
    to: 89.99,
  },
  {
    points: 7,
    grade: "B+",
    text: "Good",
    from: 70,
    to: 79.99,
  },
  {
    points: 6,
    grade: "B",
    text: "Above Average",
    from: 60,
    to: 69.99,
  },
  {
    points: 5,
    grade: "C",
    text: "Average",
    from: 50,
    to: 59.99,
  },
  {
    points: 4,
    grade: "P",
    text: "Pass",
    from: 40,
    to: 49.99,
  },
  {
    points: 0,
    grade: "F",
    text: "Fail",
    from: 0,
    to: 39.99,
  },
];
export const quizQuestionDisclimer: any = {
  multiplechoice: "Select Only One",
  multipleOptions: "Select Multiple Correct Answers",
  sequence: "Order Correct Sequence",
  match: "Match The Following",
  trueorfalse: "Select True or False",
  image: "Select Only One",
};
export const subTypes: any = {
  SpecPackage: "Speciality",
  Speciality: "Module",
  Package: "Subject",
};

export const nsdescription =
  "A semester is a specific academic period usually used in educational institutions such as universities and colleges to organize courses and academic activities. At the end of each semester, students often receive grades based on their performance in courses. ";
export const nursedescription =
  "Nursing specialties encompass specialized areas of nursing practice that require advanced knowledge, skills and expertise in specific areas of healthcare. Nursing specializations represent the various career paths that are possible within the nursing profession";
export const stateList = [{"code": "AN","name": "Andaman and Nicobar Islands"},
{"code": "AP","name": "Andhra Pradesh"},
{"code": "AR","name": "Arunachal Pradesh"},
{"code": "AS","name": "Assam"},
{"code": "BR","name": "Bihar"},
{"code": "CG","name": "Chandigarh"},
{"code": "CH","name": "Chhattisgarh"},
{"code": "DH","name": "Dadra and Nagar Haveli"},
{"code": "DD","name": "Daman and Diu"},
{"code": "DL","name": "Delhi"},
{"code": "GA","name": "Goa"},
{"code": "GJ","name": "Gujarat"},
{"code": "HR","name": "Haryana"},
{"code": "HP","name": "Himachal Pradesh"},
{"code": "JK","name": "Jammu and Kashmir"},
{"code": "JH","name": "Jharkhand"},
{"code": "KA","name": "Karnataka"},
{"code": "KL","name": "Kerala"},
{"code": "LD","name": "Lakshadweep"},
{"code": "MP","name": "Madhya Pradesh"},
{"code": "MH","name": "Maharashtra"},
{"code": "MN","name": "Manipur"},
{"code": "ML","name": "Meghalaya"},
{"code": "MZ","name": "Mizoram"},
{"code": "NL","name": "Nagaland"},
{"code": "OR","name": "Odisha"},
{"code": "PY","name": "Puducherry"},
{"code": "PB","name": "Punjab"},
{"code": "RJ","name": "Rajasthan"},
{"code": "SK","name": "Sikkim"},
{"code": "TN","name": "Tamil Nadu"},
{"code": "TS","name": "Telangana"},
{"code": "TR","name": "Tripura"},
{"code": "UK","name": "Uttarakhand"},
{"code": "UP","name": "Uttar Pradesh"},
{"code": "WB","name": "West Bengal"}]