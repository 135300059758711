import Image from "react-bootstrap/Image";
import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./Module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import PurchasedPackage from "../../components/CarouselSlider/PurchasedPackage";
import { jwtDecode } from "jwt-decode";
import authInterceptor from "../../utils/authInterceptor";
import AuthContext from "../../contexts/AuthContext";
import { nursedescription } from "../../constants/constants";
const AllSpeciality = () => {
  const navigate = useNavigate();
  const [myCourse, setMyCourse] = useState([]);
  const { state } = useLocation();
  const { id } = state || {};
  const [specialityList, setSpecialityList] = useState<any>([]);
  const { user } = useContext(AuthContext);
  const [isLogedIN, setIsLogedIN] = useState<any>(false);

  useEffect(() => {
    const userData = getUserData();
    if (userData && userData?.user?.role === "Nurse") {
      setIsLogedIN(true);
    } else {
      setIsLogedIN(false);
    }
  }, [user]);
  const getSpecialityListData = async (purchasedData: any) => {
    const userData = getUserData();
    try {
      let response;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}speciality/FE`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}speciality/NoAuth/FE`
        );
      }

      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData
        .map((data: any) => {
          if (!purchasedData.includes(data._id)) {
            return {
              _id: data._id,
              title: data.specialityTitle,
              code: data.specialityCode,
              description: data.specialityDescription,
              image: data.specialityImage,
              list: data.moduleList,
              finalPrice: data.price,
              discountPrice: data.discountPrice,
              duration: data.durationDays,
              status: data.status,
              date: data.date,
            };
          }
        })
        .filter((list: any) => list);
      setSpecialityList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}/Nurse`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData.map((data: any) => {
        return data.purchasedItems[0];
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getAlldata = async (id: any) => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      purchasedData = await getPurchasedData(userData);
    }
    await getSpecialityListData(purchasedData);
  };

  useEffect(() => {
    getAlldata(id);
  }, [id]);
  const cardBtn = (data: any) => {
    if (data.isPackage) {
      navigate("/SpecialityPackage", {
        state: { isPackagePurchased: true, packageId: data._id },
      });
    } else {
      navigate("/PurchasedModule", {
        state: {
          moduleId: data._id,
          semCode: id,
          code: data.code,
          endDate: data.endDate,
        },
      });
    }
  };

  const semViewCourse = (data: any) => {
    navigate("/module", { state: { id: data.code, name: data.title } });
  };
  const redirectUpdate = (link: string) => {
    navigate(link);
  };
  return (
    <>
      <div className="header-submenu">
        <div className="container">
          <a onClick={() => redirectUpdate("/nurse")}>{"About Us"}</a>
          <a onClick={() => redirectUpdate("/nurse-speciality")}>
            {"Speciality"}
          </a>
          <a onClick={() => redirectUpdate("/allmodule")}>{"Modules"}</a>
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/my-package")}>{"My Package"}</a>
          )}
        </div>
      </div>
      <section className="speciality-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h2 className="title">Speciality & Package Details</h2>
          <p className="desc">{nursedescription}</p>
          <div className="mySemList">
            <CarouselSlider
              data={specialityList}
              buttonText={"View Modules"}
              buuttonFunction={semViewCourse}
              isViewBtn={true}
              isPrice={true}
              isCourseList={true}
              purchaseType={"Speciality"}
              userType={"Nurse"}
            />
          </div>
          {/* {myCourse && myCourse.length > 0 ? (
            <div className="myCourse">
              <h3>My Courses</h3>
              <PurchasedPackage data={myCourse} cardBtn={cardBtn} />
            </div>
          ) : null}
          {myCourse.length === 0 &&
          specialityList &&
          specialityList.length > 0 ? (
            <div className="mySemList">
              <h3>All Speciality</h3>
              <CarouselSlider
                data={specialityList}
                buttonText={""}
                buuttonFunction={semViewCourse}
                isViewBtn={true}
                isPrice={false}
                isCourseList={true}
              />
            </div>
          ) : null} */}
        </div>
      </section>
      {/* 
      <div className="newCourse-details">
        {myCourse &&
        myCourse.length > 0 &&
        specialityList &&
        specialityList.length > 0 ? (
          <div className="newCourse">
            <h3>All Speciality</h3>
            <CarouselSlider
              data={specialityList}
              buttonText={"View Courses"}
              buuttonFunction={semViewCourse}
              isViewBtn={true}
              isPrice={false}
              isCourseList={true}
            />
          </div>
        ) : null}
      </div> */}
    </>
  );
};

export default AllSpeciality;
