import ContactUsIcon from "../../assets/contact-us.svg";
import AddressIcon from "../../assets/address.svg";
import PolicyIcon from "../../assets/policy.svg";
import FacebookIcon from "../../assets/facebook.svg";
import TwitterIcon from "../../assets/twitter.svg";
import WhatsappIcon from "../../assets/whatsapp.svg";
import InstagramIcon from "../../assets/instagram.svg";
import QR from "../../assets/Vcard.png";

import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <section className="im-footer">
        <div className="container im-footer-container">
          <div className="row footerSec">
            <div className="col-md-6 col-lg-4 col-sm-12 px-sm-3 px-5 px-lg-4 pt-sm-4">
              <h3>
                <img src={ContactUsIcon} alt="" />
                Contact
              </h3>
              <p>Mobile: +91-9445256977 / 9445296977 / 9019051277 </p>
              <p>Mail: info@nursingonlinetest.com </p>
              <p>Web: https://www.nursingonlinetest.com </p>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 px-5 px-sm-3 px-lg-4 px-md-3 pt-sm-4">
              <h3>
                <img src={AddressIcon} alt="" />
                Address
              </h3>
              <p>Impetus Healthcare Skills Private Limited</p>
              <p>#1/23 Somasundaram Avenue,</p>
              <p>Porur, Chennai – 600116,</p>
              <p> Tamil Nadu, India.</p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 px-5 px-sm-3 px-lg-4 pt-sm-4">
              <h3>
                <img src={PolicyIcon} alt="" />
                Policy
              </h3>
              <a href="/assets/PrivacyPolicy.pdf" download>
                Refund Policy
              </a>
              <a href="/assets/PrivacyPolicy.pdf" download>
                Privacy Policy
              </a>
              <a href="/assets/PrivacyPolicy.pdf" download>
                Term & Conditions
              </a>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 px-5 px-sm-3 px-4 pt-sm-4">
              <img src={QR} style={{maxWidth:'150px'}}/>
            </div>
          </div>
        </div>
      </section>
      <section className="im-footer-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-xs-12 col-sm-12">
              <p className="copyright-text">
                Copyright @ Impetus Healthcare Skills
              </p>
            </div>
            <div className="col-lg-5 col-md-2 col-xs-12 col-sm-12">&nbsp;</div>
            <div className="col-lg-3 col-md-4 col-xs-12 col-sm-12 social-icons-box">
              <div className="social-icons">
                <img src={FacebookIcon} alt="facebook" />
                <img src={TwitterIcon} alt="twitter" />
                <img src={WhatsappIcon} alt="whatsapp" />
                <img src={InstagramIcon} alt="instagram" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
