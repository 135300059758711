import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../common/ScrollToTop"; 
const HomeLayout = () => {
  const [isQuiz, setIsQuiz] = useState<any>(false);
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    setIsQuiz(pathname.includes("Quiz") || pathname.includes("score"));
  }, [location]);
  return (
    <div> 
      <Header />
      <ScrollToTop />
      <Outlet />
      {!isQuiz && <Footer />}
    </div>
  );
};

export default HomeLayout;
