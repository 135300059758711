import { useEffect, useState } from "react";
import FinalScore from "./FinalScore"; 
import "./Score.scss";
import { useNavigate, useLocation } from "react-router-dom"; 
import { adminPath } from "../../constants/api";
import { grade } from "../../constants/constants";
import { jsonDecryptAES } from "../../utils/common";
import authInterceptor from "../../utils/authInterceptor";
import ScoreCardV2 from "./ScoreCardV2";
import { Modal } from "react-bootstrap"; 
import FeedBack from "../../components/FeedBack/FeedBack";
import ScoreCardV3 from "./ScoreCardV3";
const Score = () => {
  const [quizResults, setQuizResults] = useState<any>();
  const [gradeData, setGradeData] = useState<any>();
  const [examlistData, setExamlistData] = useState<any>();
  const [showFeedBack, setShowFeedBack] = useState<boolean>(false);
  const { state } = useLocation(); 
  const navigate = useNavigate();
 
  // useEffect(() => {
  //   const handleBeforeUnload = (event: {
  //     preventDefault: () => void;
  //     returnValue: string;
  //   }) => {
  //     localStorage.setItem("isPageRefreshed", "true");
  //   };

  //   const checkRefresh = () => {
  //     const isRefreshed = localStorage.getItem("isPageRefreshed");
  //     if (isRefreshed) {
  //       deleteTempData();
  //     } else {
  //       localStorage.removeItem("isPageRefreshed");
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   checkRefresh();
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  const { scoreId } = state || {};
  const getTestData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}tempdata/${scoreId}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const dataList = decryptAESData[0]?.questionData;
      setExamlistData(dataList?.entrieData)
      const selectedGrade = grade.filter((data) => {
        return (
          Number(dataList.percentage) > data.from &&
          Number(dataList.percentage) < data.to
        );
      });
      const gradeValue =
        selectedGrade.length > 0
          ? selectedGrade[0]
          : {
              points: 0,
              grade: "F",
              text: "Fail",
              from: 0,
              to: 39.99,
            };
      let scoreObj: any = {
        isPass: gradeValue.grade === "F" ? false : true,
        correctList: dataList?.correctList,
        score: dataList?.totalMark,
        overAllMark:dataList?.overAllMark,
        percentage: dataList?.percentage,
        count: dataList?.quizQuestionList?.length,
        gradeData: gradeValue,
        completedDate: new Date(),
        examstatus: dataList?.examstatus,
        examDuration:dataList?.examDuration
      };
      dataList["isPass"] = gradeValue.grade === "F" ? false : true;
      // if (dataList.isSpecialityPackage) {
      //   if (dataList?.type === "Module") {
      //     scoreObj["moduleCode"] = dataList?.moduleCode;
      //   }
      //   scoreObj["type"] = dataList?.type;
      //   const completedExam = [...dataList?.examResult];
      //   completedExam.push(scoreObj);
      //   await authInterceptor.patch(
      //     `${adminPath}PQD/FE/Speciality/${dataList?.pqdId}/${dataList?.selectedExamId}`,
      //     completedExam
      //   );
      // } else {
      //   await authInterceptor.patch(
      //     `${adminPath}PQD/FE/${dataList?.pqdId}/${dataList?.selectedExamId}`,
      //     scoreObj
      //   );
      // }
      const isDemo = sessionStorage.getItem("isDemo");
      if(!isDemo){
        await authInterceptor.patch(
          `${adminPath}PQD/FE/${dataList?.pqdId}/${dataList?.selectedExamId}`,
          scoreObj
        );
      }
      sessionStorage.removeItem("isDemo");
      setGradeData(gradeValue);
      setQuizResults(dataList);
      setShowFeedBack(true)
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const deleteTempData = async () => {
    try {
      await authInterceptor.delete(`${adminPath}tempdata/${scoreId}`);
      navigate("/home");
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  useEffect(() => {
    getTestData();
  }, []);
  return (
    <div className="scorePage-wrapper">
      <div className="dummy">&nbsp;</div>
      <div className="container-fluid score-container">
        <div className="scorecard-wrapper">
          {/* <ScoreCard
            isPass={quizResults?.isPass}
            correctList={quizResults?.correctList}
            score={quizResults?.totalMark}
            percentage={quizResults?.percentage}
            count={quizResults?.quizQuestionList?.length}
            gradeData={gradeData}
          /> */}
          {/* <ScoreCardV2
            isPass={quizResults?.isPass}
            correctList={quizResults?.correctList}
            score={quizResults?.totalMark}
            percentage={quizResults?.percentage}
            overAllMark={quizResults?.overAllMark}
            count={quizResults?.quizQuestionList?.length}
            gradeData={gradeData}
          /> */}
           <ScoreCardV3
            isPass={quizResults?.isPass}
            correctList={quizResults?.correctList}
            score={quizResults?.totalMark}
            percentage={quizResults?.percentage}
            overAllMark={quizResults?.overAllMark}
            count={quizResults?.quizQuestionList?.length}
            examDuration={quizResults?.examDuration}
            gradeData={gradeData}
          />
        </div>
        <div className="finalScore-wrapper">
          <FinalScore quizResults={quizResults?.quizQuestionList} />
        </div>

        <Modal show={showFeedBack} centered size="lg" className="FeddBackModal">
          <Modal.Body>
            <FeedBack handleClose={()=>setShowFeedBack(false)}
              gradeData={gradeData}
              score={quizResults?.totalMark} 
              title={state?.title}
              dataList={examlistData}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Score;
