export const requiredValidation = (value:any) => { 
    return {
        value: value,
        errormsg: "This is required field",
        showError: true,
    };
}
export const requiredEmailValidation = (value:any) => {
    return {
        value: value,
        errormsg: "Please Enter Valid Email",
        showError: true,
    };
}

export const requiredPhoneValidation = (value:any) => {
    return {
        value:value,
        errormsg: "Please Enter Valid Phone number",
        showError: true,
    };
}

export const requiredURLValidation = (value:any) => {
    return {
        value: value,
        errormsg: "Please Enter Valid URL",
        showError: true,
    };
}



// Validate Email
export const isValidEmail = (value: string) => {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
}

// Validate Phone

export const isValidNumber = (value: string) => {
    return !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value));
}

export const isValidUrl = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch (err) {
      return false;
    }
  }
