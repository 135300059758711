import Image from 'react-bootstrap/Image';

type AppProps = {
    name: string;
}

const MyAccount = ({name}: AppProps) => {
    return (
        <span className='user-name'>{name}</span>
        // <Image src="" roundedCircle />
    )
}

export default MyAccount;