
import './FEQuizOptionStyle.scss';
const ResultAnswerOption = (props: any) => { 
  return (
    <div className={`AnswerOption ${props.isActive? (props.isCorectAnswer? 'correctAnswer':'wrongAnswer'):(props.dataOption?.checked? 'correctAnswer':'')}`} onClick={props.validateAnswer}>
       {props.option &&<span className='ordernumber'>{props.option}</span>}
      <span className='answer'>{props.dataOption[props.attrbute]}</span>
    </div>
  )
}

export default ResultAnswerOption;