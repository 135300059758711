import { useEffect } from 'react';
import MCQ from '../../Quiz/MCQ';
import MatchAnswer from '../../Quiz/MatchAnswer';
import MultiOption from '../../Quiz/MultiOption';
import SequenceAnswer from '../../Quiz/SequenceAnswer';
import TrueOrFalseOption from '../../Quiz/TrueOrFalseOption';
import { imagePath } from '../../constants/api';
import './Quiz.scss'
import { quizQuestionDisclimer } from '../../constants/constants';
const QuizReview = (props: any) => { 
  return (<>
    {props.quizResults?.map((data:any,index:number) => (
      <div className='quizAnswerSection quizReviewListSection'>
        <div className='quizQuestionHeader'>
          <h4 className='quizQuestion'>{index + 1}{'.'} {data.objData?.question}</h4>
          {data.objData?.isQuestionImageUploaded && <img className='quizQuestionImage' src={data.objData?.questionImage} />}
          <p className='quizQuestionType'>{quizQuestionDisclimer[data?.questiontype]}</p>
        </div>
 
        {(data.objData?.questiontype === 'multiplechoice' || data.objData?.questiontype === 'image') &&
          <MCQ answerList={data.objData?.optionArray}
            isImageOption={data.objData?.imageSubType === "Image"}
            validateAnswer={(value: any) => props.updateQuizAnswer(value, index, data?.questiontype)}  currentOptionSelected={data.selectedOption} />}
        {data.objData?.questiontype === 'multipleOptions' &&
          <MultiOption answerList={data.objData?.optionArray}
            validateAnswer={(value: any) => props.updateQuizAnswer(value, index, data?.questiontype)}  currentOptionSelected={data.selectedOption} />}
        {data.objData?.questiontype === 'sequence' && <SequenceAnswer answerList={data.objData?.optionSqu}
          validateAnswer={(value: any) => props.updateQuizAnswer(value, index, data?.questiontype)}  currentOptionSelected={data.selectedOption} />}
        {data.objData?.questiontype === 'match' && <MatchAnswer answerList={data.objData?.optionMatch}
          validateAnswer={(value: any) => props.updateQuizAnswer(value, index, data?.questiontype)}  currentOptionSelected={data.selectedOption} />}
        {data.objData?.questiontype === 'trueorfalse' && <TrueOrFalseOption
          validateAnswer={(value: any) => props.updateQuizAnswer(value, index, data?.questiontype)}  currentOptionSelected={data.selectedOption} />}
        </div>
 
    ))}</>

  );
};
export default QuizReview;
