import Image from "react-bootstrap/Image";
import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import BlueLinearBg from "../../assets/blue-linear-bg.png";
import "./semester.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { adminPath } from "../../constants/api";
import { getUserData, jsonDecryptAES } from "../../utils/common";
import { jwtDecode } from "jwt-decode";
import AuthContext from "../../contexts/AuthContext";
import authInterceptor from "../../utils/authInterceptor";
import { nsdescription } from "../../constants/constants";
const AllSemester = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [myCourse, setMyCourse] = useState([]);
  const { state } = useLocation();
  const { id } = state || {};
  const [semesterList, setSemesterList] = useState<any>([]);
  const [isLogedIN, setIsLogedIN] = useState<any>(false);
  const getSemesterListData = async (userData?: any) => {
    try {
      let response;
      if (userData) {
        response = await authInterceptor.get(`${adminPath}semester/FE`);
      } else {
        response = await authInterceptor.get(
          `${adminPath}semester/FE/NoAuthSem`
        );
      }

      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      let filterData;
      if (userData) {
        filterData = decryptAESData.filter(
          (data: any) => data.semCode === userData?.user?.semester
        );
      } else {
        filterData = decryptAESData;
      }
      const list = filterData.map((data: any) => {
        return {
          _id: data._id,
          title: data.semName,
          code: data.semCode,
          description: data.semDescription,
          image: data.semImage,
          subjectList: data.subjectList,
          status: data.status,
          date: data.date,
        };
      });
      setSemesterList(list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getPurchasedData = async (userData: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}order/userorder/${userData.user.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const list = decryptAESData.map((data: any) => {
        return data.purchasedItems[0];
      });
      const myCourseData = list.map((data: any) => data._id || data._id);
      setMyCourse(list);
      return myCourseData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  useEffect(() => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);
      // getPurchasedData(userData);
      if (userData.user.role === "NursingStudent") {
        getSemesterListData(userData);
      } else {
        getSemesterListData();
      }
    } else {
      getSemesterListData();
    }
  }, [id]);

  useEffect(() => {
    const token: any = sessionStorage.getItem("token");
    let purchasedData = [];
    if (token) {
      let userData: any = jwtDecode(token);

      // getPurchasedData(userData);
      if (userData.user.role === "NursingStudent") {
        setIsLogedIN(true);
        getSemesterListData(userData);
      } else {
        getSemesterListData();
      }
    } else {
      getSemesterListData();
    }
  }, [user]);

  const cardBtn = (data: any) => {
    if (data.isPackage) {
      navigate("/PackageDetails", {
        state: { isPackagePurchased: true, packageId: data._id },
      });
    } else {
      navigate("/SubjectDetails", {
        state: {
          subjectId: data._id,
          semCode: id,
          subjectCode: data.code,
          endDate: data.endDate,
        },
      });
    }
  };

  const semViewCourse = (data: any) => {
    navigate("/semester", { state: { id: data.code, name: data.title } });
  };
  const redirectUpdate = (link: string) => {
    navigate(link);
  };
  return (
    <>
      <div className="header-submenu">
        <div className="container">
          <a onClick={() => redirectUpdate("/nursing-students")}>
            {"About Us"}
          </a>
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/semester")}>{"Semester"}</a>
          )}
          {!isLogedIN && (
            <a onClick={() => redirectUpdate("/ns-semester")}>{"Semester"}</a>
          )}
          {isLogedIN && (
            <a onClick={() => redirectUpdate("/my-package")}>{"My Package"}</a>
          )}
        </div>
      </div>
      <section className="semester-carousel">
        <Image
          src={BlueLinearBg}
          className="header-image"
          alt="background"
          fluid
        />
        <div
          className="container carouselSlider-box"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h2 className="title">Semester & Package Details</h2>
          <p className="desc">{nsdescription} </p>
          <div className="mySemList"> 
            <CarouselSlider
              data={semesterList}
              buttonText={"View Subjects"}
              buuttonFunction={semViewCourse}
              isViewBtn={true}
              isSemester={true}
              isPrice={false}
              isCourseList={true}
            />
          </div>
          {/* {myCourse && myCourse.length > 0 ? (
            <div className="myCourse">
              <h3>My Courses</h3>
              <PurchasedPackage data={myCourse} cardBtn={cardBtn} />
            </div>
          ) : null}
          {myCourse.length === 0 && semesterList && semesterList.length > 0 ? (
            <div className="mySemList">
              <h3>All Semester</h3>
              <CarouselSlider
                data={semesterList}
                buttonText={"View Courses"}
                buuttonFunction={semViewCourse}
                isViewBtn={true}
                isPrice={false}
                isCourseList={true}
              />
            </div>
          ) : null} */}
        </div>
      </section>

      {/* <div className="newCourse-details">
        {myCourse &&
        myCourse.length > 0 &&
        semesterList &&
        semesterList.length > 0 ? (
          <div className="newCourse">
            <h3>All Semester</h3>
            <CarouselSlider
              data={semesterList}
              buttonText={"View Courses"}
              buuttonFunction={semViewCourse}
              isViewBtn={true}
              isPrice={false}
              isCourseList={true}
            />
          </div>
        ) : null}
      </div> */}
    </>
  );
};

export default AllSemester;
