import './FEQuizOptionStyle.scss'
import AnswerOption from "./AnswerOption";
import { alphabet } from "../constants/constants";
import AnswerImage from './AnswerImage';
const MCQ = (props: any) => {
  return (
    <div className={'mcqQuestions'}> 
      {props.answerList?.map((data: any, index: number) => (
        <div className="mcqAnswerList">
          {props.isImageOption ?
            <AnswerImage isEffect={true} dataOption={data} 
            highlightCorrect={props?.correctOption?._id === data?._id}
            currentOptionSelected={props.currentOptionSelected} correctOption={props?.correctOption}
              validateAnswer={() => props.validateAnswer(data)} option={alphabet[index]} /> :
            <AnswerOption attrbute="value"
              isActive={props.currentOptionSelected?._id === data?._id}
              highlightCorrect={props?.correctOption?._id === data?._id}
              isEffect={true} dataOption={data} currentOptionSelected={props.correctOption}
              validateAnswer={() => props.validateAnswer(data)} option={alphabet[index]} />}
        </div>
      ))}
    </div>
  );
};
export default MCQ;
