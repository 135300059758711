import React, { useState, useContext } from "react";
import { adminPath } from "../../constants/api";
import axios from "axios";
import "./FELogin.scss";
import { jwtDecode } from "jwt-decode";
import { encryptAES, jsonDecryptAES } from "../../utils/common";
import AuthContext from "../../contexts/AuthContext";
import { isValidEmail, requiredEmailValidation } from "../../utils/formutils";
import authInterceptor from "../../utils/authInterceptor";
import EyeIcon from "../../assets/visibility.svg";

const FELogin = (props: any) => {
  const [globalError, setglobalError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [showPassword, setshowPassword] = useState(false);

  const { login } = useContext(AuthContext);

  const [loginData, setLoginData] = useState({
    username: { value: "", errormsg: "", showError: false },
    password: { value: "", errormsg: "", showError: false },
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (handleValidation()) {
      let payload = {
        email: loginData.username?.value?.toLowerCase(),
        password: loginData.password.value,
      };
      try {
        const apiResponse = await authInterceptor.post(
          `${adminPath}FElanding`,
          payload
        );
        const token: any = jwtDecode(apiResponse.data.token);
        sessionStorage.setItem("token", apiResponse.data.token);
        sessionStorage.setItem("role", encryptAES(token.user.role));
        sessionStorage.setItem(
          "refID",
          encryptAES(token.user.IHSID.toString())
        );
        await login(apiResponse.data.token, token.user.role);
        if (token.user.role === "NursingStudent") {
          props.handleModal(token.user.college, token.user.semester);
        } else {
          props.handleClose();
        }
      } catch (error: any) {
        console.log("error", error);
        const { data } = error.response;
        setglobalError({ isError: true, errorMsg: data.msg });
      }
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [e.target.name]: {
        value: e.target.value,
        errormsg: "",
        showError: false,
      },
    });
    setglobalError({ isError: false, errorMsg: "" });
  };
  const handleValidation = () => {
    let formIsValid = true;
    let obj: any = {};
    if (!loginData.username.value) {
      formIsValid = false;
      obj.username = {
        value: loginData.username.value,
        errormsg: "This is required field",
        showError: true,
      };
    } else if (isValidEmail(loginData.username.value)) {
      formIsValid = false;
      obj.username = requiredEmailValidation(loginData.username.value);
    }
    if (!loginData.password.value) {
      formIsValid = false;
      obj.password = {
        value: loginData.password.value,
        errormsg: "This is required field",
        showError: true,
      };
    }
    const newData = { ...loginData, ...obj };
    setLoginData(newData);
    return formIsValid;
  };

  return (
    <div className="login-wrapper">
      <h2 className="login-title">Welcome back!</h2>
      <label className="form-label login-label">Email</label>
      <div className="input-group mb-4 login-input-group">
        <span className="input-group-text">
          <img src="assets/mail.png" />
        </span>
        <input
          type="email"
          placeholder="Enter your mail"
          name="username"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      {loginData.username.showError && (
        <span className="input-error">{loginData.username.errormsg}</span>
      )}
      <label className="form-label login-label">Password</label>
      <div className="input-group mb-4 login-input-group">
        <span className="input-group-text" id="basic-addon1">
          <img src="assets/lock_open.png" />
        </span>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Enter password"
          name="password"
          onChange={handleChange}
          className="form-control"
        />
        <button
          className="password-eye-btn"
          onClick={() => setshowPassword(!showPassword)}
        >
          <img src={EyeIcon} alt="back-btn" />
        </button>
      </div>
      {loginData.password.showError && (
        <span className="input-error">{loginData.password.errormsg}</span>
      )}
      <div className="Forgot-box">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label login-check-label">
            Remember me
          </label>
        </div>
        <p onClick={() => props.redirectToSignUp("ForgotPassword")}>
          Forgot Password?
        </p>
      </div>

      <button className="login-btn" onClick={handleSubmit}>
        Login
      </button>
      {globalError.isError && (
        <span className="input-error">{globalError.errorMsg}</span>
      )}

      <span className="signup-link">
        Don't have an account?{" "}
        <span onClick={() => props.redirectToSignUp("SignUp")}>Sign Up</span>
      </span>
    </div>
  );
};
export default FELogin;
