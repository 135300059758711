import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adminPath } from "../../constants/api";
import authInterceptor from "../../utils/authInterceptor";
import { async } from "q";
import axios from "axios";
import { jsonDecryptAES, jsonEncryptAES } from "../../utils/common";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { paymentStatus } from "../../constants/constants";
import { CartModalContext } from "../../contexts/CartModalContext";
const PaymentData = () => {
  const navigate = useNavigate();
  const { setCart, cartstate } = useContext(CartModalContext);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get("valid");
    const parsedData = JSON.parse(product || "");
    const {
      order_status,
      merchant_param2,
      merchant_param3,
      merchant_param4,
      merchant_param5,
    } = parsedData; //merchant_param1- purchasedDataID, merchant_param2- orderID,merchant_param3- packagename,merchant_param4- IHSID,
    if (merchant_param5 === "Bulk") {
      if (order_status === "Success") {
        bulkUpdate(merchant_param3, Number(merchant_param4));
      } else {
        bulkUpdateFailure(merchant_param2);
      }
    } else {
      if (order_status === "Success") {
        handleSuccess(merchant_param3, Number(merchant_param4));
      } else {
        handleFailure(Number(merchant_param2));
      }
    }
  }, []);

  const getSpecExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}specialityTest/FE/getAll`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getModuleExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}moduleTest/FE/getAll/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getAllModuleExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}moduleTest/FE/getAll`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}onlineTest/FE/subject/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getPackageExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}onlineTest/FE/package`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const handleBulkSuccess = async (cartData: any, cartID: any) => {
    try {
      const d = new Date();
      let month: any = d.getMonth();
      let day = d.getDate().toString().padStart(2, "0");
      let year: any = d.getFullYear();
      year = year.toString().substr(-2);
      month = (month + 1).toString().padStart(2, "0");
      const id =
        year + day + month + Math.floor(100000 + Math.random() * 900000);
      const token: any = sessionStorage.getItem("token");
      const userData: any = jwtDecode(token);
      let result = new Date();
      result.setDate(d.getDate() + Number(cartData.duration));
      cartData["endDate"] = result;
      if (
        cartstate.type === "Package" ||
        cartstate.type === "SpecPackage" ||
        cartstate.type === "Speciality"
      ) {
        cartData["isPackage"] = true;
      }
      let examList;
      if (cartstate.type === "Package") {
        examList = await getPackageExamData(cartData?.list);
      } else if (cartstate.type === "Subject") {
        examList = await getExamData(cartData.code);
      } else if (cartstate.type === "Module") {
        examList = await getModuleExamData(cartData.code);
      } else if (cartstate.type === "SpecPackage") {
        examList = await getSpecExamData(cartData.list);
      } else if (cartstate.type === "Speciality") {
        const moduleData = await getAllModuleExamData(cartData.list);
        const specData = await getSpecExamData([cartData.code]);
        examList = [...specData, ...moduleData];
      }
      let PQDObj: any = {
        orderID: id,
        userType: userData.user.role,
        IHSID: userData.user.IHSID,
        userName: userData.user.name,
        modeOfActivation:'Purchased',
        packagestatus:'Active',
        purchasedDate: dayjs(),
        packageData: { ...cartData },
        examData: examList,
      };
      const encryptPQD = await jsonEncryptAES(PQDObj);
      const purchasedDataID = await authInterceptor.post(
        `${adminPath}PQD/FE/`,
        {
          data: encryptPQD,
        }
      );
      cartData["purchasedDataID"] = purchasedDataID.data.id;
      let obj = {
        orderID: id,
        userType: userData.user.role,
        IHSID: userData.user.IHSID,
        userName: userData.user.name,
        modeOfActivation:'Purchased',
        status: paymentStatus.Success,
        purchasedItems: [cartData],
        purchasedDate: dayjs(),
        packagestatus:'Active',
        endDate: result,
        date: new Date(),
      };
      const encryptAESData = await jsonEncryptAES(obj);
      await authInterceptor.post(`${adminPath}order`, {
        data: encryptAESData,
      });
      // await authInterceptor.delete(`${adminPath}cartItems/${cartID}`);
      return {
        orderID: id,
        packagename: cartData?.title,
      };
    } catch (error: any) {}
  };

  const bulkUpdate = async (packagename: any, IHSID: any) => {
    const purchaseTempData: any = await authInterceptor.get(
      `${adminPath}purchaseTemp/cart/${packagename}`
    );
    const purchaseData = purchaseTempData.data.data[0];
    const requests = purchaseTempData.data.data[0]?.cartList.map(
      async (item: any) => {
        return handleBulkSuccess(item?.cartList, item?._id);
      }
    );
    axios.all(requests).then(async (result) => {
      await removeAllCartItems(IHSID);
      const requests = purchaseData?.cartList?.map(async (data: any) => {
        return authInterceptor.post(`${adminPath}common/SMS/purchasesms`, {
          packagename: data?.cartList?.title,
          IHSID: IHSID,
        });
      });
      axios.all(requests).then(async (result) => {
        navigate("/my-package");
      });
    });
    // const packagenameList = packagename.split(",");
    //await removeAllCartItems(IHSID);
    // const requests = packagenameList.map(async (data: any) => {
    //   return authInterceptor.post(`${adminPath}common/SMS/purchasesms`, {
    //     packagename: data,
    //     IHSID: IHSID,
    //   });
    // });
    // axios.all(requests).then(async (result) => {
    //   navigate("/my-package");
    // });
  };
  const bulkUpdateFailure = async (ordeIds: any) => {
    // const orderList = ordeIds.split(",");
    // await authInterceptor.post(`${adminPath}order/deleteAll`, {
    //   ordeIds: orderList,
    // });
    navigate("/");
  };
  const removeAllCartItems = async (IHSID: any) => {
    try {
      await authInterceptor.delete(`${adminPath}cartItems/deleteall/${IHSID}`);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const handleSuccess = async (packagename: any, IHSID: any) => {
    const purchaseTempData: any = await authInterceptor.get(
      `${adminPath}purchaseTemp/${packagename}`
    );
    const purchaseData = purchaseTempData.data.data[0];
    const purchasedDataID = await authInterceptor.post(`${adminPath}PQD/FE/`, {
      data: purchaseData?.PQDObj,
    });
    purchaseData.paydata["purchasedDataID"] = purchasedDataID?.data?.id;
    purchaseData.orderObj["purchasedItems"] = [purchaseData.paydata];
    const encryptAESData = await jsonEncryptAES(purchaseData.orderObj);
    const response: any = await authInterceptor.post(`${adminPath}order`, {
      data: encryptAESData,
    });
    await authInterceptor.post(`${adminPath}common/SMS/purchasesms`, {
      packagename: purchaseData.paydata.title,
      IHSID: IHSID,
    });
    navigate("/my-package");
  };

  const handleFailure = async (orderID: any) => {
    // await authInterceptor.delete(`${adminPath}order/${orderID}`);
    navigate("/");
  };

  return <div className="OnlineTest">Loading</div>;
};

export default PaymentData;
