import { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PackageCourseCards from '../CourseCards/PackageCourseCards';
import "./CarouselSlider.scss" 
type Course = {
  title: string,
  description: string,
  imagePath: string,
  discountPrice: string,
  finalPrice: string
}

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      className={className}
      onClick={onClick}
    > <img src='assets/nextIcon.png' /></span>
  );
}

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      className={className}
      onClick={onClick}
    > <img src='assets/prevIcon.png' /></span>
  );
}

const PurchasedPackage = (props: any) => {
  const [sliderData, setSliderData] = useState<Course[]>([])
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow className="im-next-btn" />,
    prevArrow: <PrevArrow className="im-prev-btn" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useEffect(() => {
    if (props.data.length) {
      setSliderData(props.data)
    }
  }, [props.data])

 
  return (
    <div className='list-slider-carousel'>
      {sliderData && <Slider {...settings}>
        {sliderData.map((course: any) => (
          <PackageCourseCards course={course}
            isPrice={true}
            buttonText="Buy Now"
            buuttonFunction={props.cardBtn} />
        ))}
      </Slider>}</div>
  );
}

export default PurchasedPackage;